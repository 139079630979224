import { FungibleTokenContract, NonFungibleTokenContract } from 'services/contract';
import { INFTTokenMetadata } from 'services/interfaces';

export interface IAuctionValues {
  nftContract: NonFungibleTokenContract | null;
  nftContractId: string;
  nftToken: INFTTokenMetadata | null;
  nftTokenId: string;
  depositToken: FungibleTokenContract | null;
  depositTokenId: string;
  startDate: string;
  endDate: string;
  initialPrice: string;
  projectLink: string;
  twitterLink: string;
  mediumLink: string;
  telegramLink: string;
  buyoutPrice?: string;
  auctionStep?: string;
  auctionMinStep?: string;
}

export enum EAuctionFromValues {
  projectLink = 'projectLink',
  twitterLink = 'twitterLink',
  mediumLink = 'mediumLink',
  telegramLink = 'telegramLink',
  startDate = 'startDate',
  endDate = 'endDate',
  initialPrice = 'initialPrice',
  buyoutPrice = 'buyoutPrice',
  auctionStep = 'auctionStep',
  auctionMinStep = 'auctionMinStep',
  depositToken = 'depositToken',
  depositTokenId = 'depositTokenId',
  nftToken = 'nftToken',
  nftContractId = 'nftContractId',
  nftTokenId = 'nftTokenId',
  nftContract = 'nftContract',
}
