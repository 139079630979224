import Big from 'big.js';
import dayjs from 'dayjs';

import { stakingSeedId } from 'services/config';
import { EStakingStatus, IStakingInfo, ITokenMetadata } from 'services/interfaces';
import { DAYS_A_YEAR, ONE_HUNDRED, SECONDS_IN_A_DAY, ZERO_STR } from 'shared/constants';
import { IFormStakingValues, IFormattedStakingValues, IFormattedStaking } from 'shared/interfaces';
import { millisecondsToSeconds, parseTokenAmount, toMap, secondsToMilliseconds } from 'shared/utils';

Big.RM = Big.roundDown;
Big.DP = 30;

export const calcRewardPerSession = (
  distributionInDay: string,
  distributionAmount: string,
  sessionInterval: string
) => {
  try {
    const distributionDuration = Big(distributionInDay).mul(SECONDS_IN_A_DAY);
    const rewardPerSecond = Big(distributionAmount).div(distributionDuration);
    const rewardPerSession = rewardPerSecond.mul(sessionInterval).toFixed();
    return { rewardPerSession };
  } catch (e) {
    return { rewardPerSession: ZERO_STR };
  }
};

export const getStakingStatus = (status: EStakingStatus, dateStart: number, endAt: number): EStakingStatus => {
  const currentDate = dayjs().valueOf();
  const startDate = dayjs(secondsToMilliseconds(dateStart)).valueOf();
  const endDate = endAt ? dayjs(secondsToMilliseconds(endAt)).valueOf() : 0;

  if (status === EStakingStatus.Ended || currentDate > endDate) return EStakingStatus.Ended;
  if (startDate > currentDate) return EStakingStatus.Created;
  if (startDate < currentDate && status === EStakingStatus.Created) return EStakingStatus.Created;
  if (startDate < currentDate && status === EStakingStatus.Running) return EStakingStatus.Running;
  return EStakingStatus.Ended;
};

export const formatStaking = (staking: IStakingInfo, totalStaked: string): IFormattedStaking => {
  const id = staking.farm_id.slice(staking.farm_id.indexOf('#') + 1);
  const rewardPerSeconds = Big(staking.reward_per_session).div(staking.session_interval).toFixed();
  const stakingDuration = Big(staking.total_reward).div(rewardPerSeconds).toNumber();
  const endAt = staking.start_at + stakingDuration;
  const status = getStakingStatus(staking.farm_status, staking.start_at, endAt);
  let apy = ZERO_STR;
  if (Big(totalStaked).gt(ZERO_STR) && (status === EStakingStatus.Running || status === EStakingStatus.Created)) {
    const rewardForYear = Big(rewardPerSeconds).times(SECONDS_IN_A_DAY).times(DAYS_A_YEAR).toFixed();
    apy = Big(rewardForYear).div(totalStaked).times(ONE_HUNDRED).toFixed(2);
  }
  return {
    id: Number(id),
    stakeId: staking.farm_id,
    type: staking.farm_kind,
    status,
    seedId: staking.seed_id,
    rewardTokenId: staking.reward_token,
    startAt: staking.start_at,
    endAt,
    rewardPerSession: staking.reward_per_session,
    sessionInterval: staking.session_interval,
    totalReward: staking.total_reward,
    currentRound: staking.cur_round,
    lastRound: staking.last_round,
    claimedReward: staking.claimed_reward,
    unclaimedReward: staking.unclaimed_reward,
    beneficiaryReward: staking.beneficiary_reward,
    rewardPerSeconds,
    apy,
  };
};

export const formatStakingValues = (
  values: IFormStakingValues,
  metadata: ITokenMetadata,
  rewardToken: string
): IFormattedStakingValues => {
  const { minDeposit, sessionInterval, startAt, distributionInDay, distributionAmount } = values;
  const { decimals } = metadata;

  const { rewardPerSession } = calcRewardPerSession(distributionInDay, distributionAmount, sessionInterval);
  const parsedRewardPerSession = parseTokenAmount(rewardPerSession, decimals);

  const startAtValueOf = dayjs(startAt).add(dayjs().utcOffset(), 'minutes').valueOf();

  const formattedValues: IFormattedStakingValues = {
    rewardToken,
    seedId: stakingSeedId,
    sessionInterval: Number(sessionInterval),
    rewardPerSession: parsedRewardPerSession,
    startAt: millisecondsToSeconds(startAtValueOf),
  };
  if (minDeposit) formattedValues.minDeposit = parseTokenAmount(minDeposit, decimals);
  return formattedValues;
};

export const initialStakingValues: IFormStakingValues = {
  minDeposit: '',
  startAt: '',
  sessionInterval: '60',
  distributionInDay: '',
  distributionAmount: '',
};

export const retrieveStakingData = (stakingArray: IFormattedStaking[], totalStaked: string) => {
  if (!stakingArray.length) return null;
  const firstStaking = stakingArray[0];
  const data = stakingArray.reduce(
    (acc, staking) => {
      if (staking.startAt < acc.startAt) acc.startAt = staking.startAt;
      if (staking.endAt > acc.endAt) acc.endAt = staking.endAt;
      acc.unclaimedReward = Big(acc.unclaimedReward).plus(staking.unclaimedReward).toFixed();
      acc.claimedReward = Big(acc.claimedReward).plus(staking.claimedReward).toFixed();

      if (staking.status !== EStakingStatus.Running) return acc;
      acc.rewardPerSeconds = Big(acc.rewardPerSeconds).plus(staking.rewardPerSeconds).toFixed();
      acc.totalReward = Big(acc.totalReward).plus(staking.totalReward).toFixed();
      acc.apy = Big(acc.apy).plus(staking.apy).toFixed();
      return acc;
    },
    {
      startAt: firstStaking.startAt,
      endAt: firstStaking.endAt,
      rewardPerSeconds: ZERO_STR,
      totalReward: ZERO_STR,
      unclaimedReward: ZERO_STR,
      claimedReward: ZERO_STR,
      apy: ZERO_STR,
    }
  );
  const { startAt, endAt, rewardPerSeconds, totalReward, unclaimedReward, claimedReward, apy } = data;

  const farms: { [key: string]: IFormattedStaking } = toMap(stakingArray, 'id');
  return {
    rewardTokenId: firstStaking.rewardTokenId,
    startAt,
    endAt,
    rewardPerSeconds,
    apy,
    totalStaked,
    totalReward,
    unclaimedReward,
    claimedReward,
    farms: {
      obj: farms,
      arr: stakingArray,
    },
  };
};

export const sortingStaking = (staking: IFormattedStaking[]) => {
  const createdStaking = staking.filter((el) => el.status === EStakingStatus.Created);
  const runningStaking = staking.filter((el) => el.status === EStakingStatus.Running);
  const endedStaking = staking.filter((el) => el.status === EStakingStatus.Ended);
  const clearedStaking = staking.filter((el) => el.status === EStakingStatus.Cleared);
  return [...createdStaking, ...runningStaking, ...endedStaking, ...clearedStaking];
};
