import { WalletSelector } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { providers } from 'near-api-js';

import { auctionContractId, saleContractId, stakingContractId } from 'services/config';
import { AuctionContract, SaleContract, StakingContract } from 'services/contract';
import RPCProviderService from 'services/RPCProviderService';
import { RootState } from 'store';
import { setAuctionContract, setProvider, setSaleContract, setStakingContract } from 'store/slices/services';

export const loadServiceData = createAsyncThunk<void, { walletSelector: WalletSelector }, { state: RootState }>(
  'services/loadServiceData',
  async ({ walletSelector }, { dispatch }) => {
    try {
      const { network } = walletSelector.options;
      const providerService = new providers.JsonRpcProvider({ url: network.nodeUrl });
      const provider = new RPCProviderService(providerService);
      const saleContract = new SaleContract(saleContractId, provider);
      const auctionContract = new AuctionContract(auctionContractId, provider);
      const stakingContract = new StakingContract(stakingContractId, provider);
      dispatch(setSaleContract(saleContract));
      dispatch(setAuctionContract(auctionContract));
      dispatch(setStakingContract(stakingContract));
      dispatch(setProvider(provider));
    } catch (e) {
      console.error(`Error: while setup service data \n ${e}`);
    }
  }
);
