import { Translate } from 'shared/components/Translate';
import { displayAmount } from 'shared/utils';

import { IParticipants } from '../../participantData';
import styles from './styles';

export default function BidHistory({
  participants,
  decimals,
  symbol,
}: {
  participants: IParticipants;
  decimals: number;
  symbol: string;
}) {
  const loading = !participants.isUpdated;

  if (loading) {
    return (
      <styles.Loading>
        <Translate value="BidHistory.Title" />
      </styles.Loading>
    );
  }

  if (!participants.length) {
    return (
      <styles.Title>
        <Translate value="BidHistory.EmptyParticipants" />
      </styles.Title>
    );
  }

  const updatedParticipants = participants.arr
    .slice(0)
    .reverse()
    .map((participant) => {
      const accountId = participant[0];
      const accountInfo = participant[1];
      const amount = displayAmount(accountInfo.amount, decimals);
      return { ...participant, accountId, amount };
    });

  return (
    <styles.Container>
      {updatedParticipants.map(({ accountId, amount }) => (
        <styles.Row key={accountId}>
          <span>{accountId}</span>
          <span>
            <Translate value="TokenAmount" interpolation={{ amount, symbol }} />
          </span>
        </styles.Row>
      ))}
    </styles.Container>
  );
}
