import { createAsyncThunk } from '@reduxjs/toolkit';

import { onlyUniqueValues, toMap } from 'shared/utils';
import { RootState } from 'store';
import { setAuctions, setAuctionsLoading } from 'store/slices/auctions';

import { retrieveAuctionArray } from './helpers';
import { loadNfts } from './loadNfts';
import { loadTokens } from './loadTokens';

export const loadAuctions = createAsyncThunk<void, void, { state: RootState }>(
  'auctions/loadAuctions',
  async (_, { dispatch, getState }) => {
    try {
      const {
        services: { auctionContract },
      } = getState();
      if (!auctionContract) return;
      const auctionArray = await retrieveAuctionArray(auctionContract);
      const auctionsMap = toMap(auctionArray, 'id');
      const tokenAddresses = onlyUniqueValues(auctionArray.flatMap((auction) => auction.depositTokenId));
      dispatch(setAuctions(auctionsMap));
      dispatch(loadTokens({ tokenAddresses }));
      dispatch(loadNfts());
    } catch (e) {
      console.error(`Error: while load auctions \n ${e}`);
    } finally {
      dispatch(setAuctionsLoading(false));
    }
  }
);
