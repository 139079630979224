import { IConfig } from '../config';

export default {
  networkId: 'testnet',
  nodeUrl: 'https://rpc.testnet.near.org',
  walletUrl: 'https://wallet.testnet.near.org',
  helperUrl: 'https://testnet-api.kitwallet.app',
  explorerUrl: 'https://explorer.testnet.near.org',
  saleContractId: 'bocadmin.testnet',
  auctionContractId: 'auction.bocadmin.testnet',
  wNearAddress: 'wrap.testnet',
  walletId: 'testnet',
  myNearWalletUrl: 'https://testnet.mynearwallet.com/',
  usn: 'usdn.testnet',
  parasContractId: 'paras-token-v2.testnet',
  parasApiUrl: 'https://api-v3-marketplace-testnet.paras.id',
  stakingContractId: 'sarap.testaking.testnet',
  stakingSeedId: 'token.testaking.testnet',
} as IConfig;
