export const FTViewMethods = {
  ftMetadata: 'ft_metadata',
  storageBalanceOf: 'storage_balance_of',
  storageBalanceBounds: 'storage_balance_bounds',
  ftBalanceOf: 'ft_balance_of',
};

export const FTChangeMethods = {
  ftTransferCall: 'ft_transfer_call',
  storageDeposit: 'storage_deposit',
};

export const SaleViewMethods = {
  getNumSales: 'get_num_sales', // (&self)
  getSales: 'get_sales', // (&self, from_index: u64, limit: u64)
  getSaleAccounts: 'get_sale_accounts', // (&self, sale_id: u64, from_index: u64, limit: u64,)
  getNumFutureSales: 'get_num_future_sales',
  getFutureSale: 'get_future_sale',
  getFutureSales: 'get_future_sales',
};

export const SaleChangeMethods = {
  createSale: 'create_sale', // (&mut self, sale: SaleInput)
  removeSale: 'remove_sale', // (&mut self, sale_id: u64)
  updateSaleDates: 'update_sale_dates', // (&mut self, sale_id: u64, start_date: U64, end_date: U64)
  updateSaleDistributeTokenId: 'update_sale_distribute_token_id',
  // ( &mut self, sale_id: u64, distribute_token_id: AccountId)

  updateSalePrice: 'update_sale_price', // (&mut self, sale_id: u64, price: U128, max_amount: U128)

  updateSaleClaimAvailable: 'update_sale_claim_available', // (&mut self, sale_id: u64, claim_available: bool)
  updateSaleRefundAvailable: 'update_sale_refund_available', // (&mut self, sale_id: u64, refund_available: bool)
  changeVesting: 'change_vesting', // (&mut self, sale_id: u64, mut input_vesting: Vec<VestingPeriod>)
  changeSaleType: 'change_saletype',

  changeWhitelistHash: 'change_whitelist_hash', // (&mut self, sale_id: u64, new_hash: CryptoHash)
  createFutureSale: 'create_future_sale',
  deleteFutureSale: 'delete_future_sale',
  updateFutureSale: 'update_future_sale',
  createSaleFromFutureSale: 'create_sale_from_future_sale',
};

export const NFTViewMethods = {
  nftMetadata: 'nft_metadata',
  nftToken: 'nft_token',
  nftTokensForOwner: 'nft_tokens_for_owner',
  nftSupplyForOwner: 'nft_supply_for_owner',
};

export const TransferNFT = 'ft_transfer_call';

export const AuctionViewMethods = {
  getNumAuctions: 'get_num_auctions',
  getAuction: 'get_auction',
  getAuctions: 'get_auctions',
  getNumAccounts: 'get_num_accounts',
  getAuctionsAccounts: 'get_auctions_accounts',
  getWinner: 'get_winner',
  getAuctionAccount: 'get_auction_account',
  getAuctionAccounts: 'get_auction_accounts',
  hasAccount: 'has_account',
  getJoinFee: 'get_join_fee',
};

export const AuctionChangeMethods = {
  createAuction: 'create_auction',
  updateAuctionDates: 'update_auction_dates',
  updateAuctionRefundAvailable: 'update_auction_refund_available',
  updateAuctionClaimAvailable: 'update_auction_claim_available',
  updateMetadata: 'update_metadata',
  removeAuction: 'remove_auction',
};

export enum StakingChangeMethods {
  createSimpleFarm = 'create_simple_farm',
}

export const StakingContractViewMethod = {
  listFarmsBySeed: 'list_farms_by_seed',
  getSeedInfo: 'get_seed_info',
  listUserSeeds: 'list_user_seeds',
  getUnclaimedReward: 'get_unclaimed_reward',
  getUserRps: 'get_user_rps',
  storageBalanceOf: 'storage_balance_of',
  getReward: 'get_reward',
};
