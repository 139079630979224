import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { APP_ROUTES } from 'routes/constant';
import Loading from 'shared/components/Loading';
import NFTMedia from 'shared/components/NFTMedia';
import Status from 'shared/components/Status';
import Timer from 'shared/components/Timer';
import { Translate } from 'shared/components/Translate';
import { useAppSelector } from 'shared/hooks/redux';
import useAuctionStatus from 'shared/hooks/useAuctionStatus';
import useAuctionUpdater from 'shared/hooks/useAuctionUpdater';
import useImageDataUpload from 'shared/hooks/useImageDataUpload';
import useWindowDimensions from 'shared/hooks/useWindowDimensions';
import { IAuction, EStatus } from 'shared/interfaces';
import { getNftData, getAuctionData } from 'shared/utils';
import { selectData } from 'store/selectors/selectData';
import { selectToken } from 'store/selectors/selectToken';

import AdminPanel from './components/AdminPanel';
import BidHistory from './components/BidHistory';
import Footer from './components/Footer';
import styles from './styles';

export default function AuctionPage(): JSX.Element {
  const { auctions, loading, nfts, tokens } = useAppSelector(selectData);
  const { id } = useParams();
  const navigate = useNavigate();
  const [auction, setAuction] = useState<IAuction | null>(null);
  const token = useAppSelector((state) => selectToken(state, auction?.depositTokenId));

  useEffect(() => {
    const auctionId = Number(id);
    const currentAuction = auctions.obj[auctionId];
    if (id && currentAuction && (!auction || auction !== currentAuction)) {
      setAuction(currentAuction);
    }
  }, [id, loading.auction, auction, token, tokens, auctions]);

  const status = useAuctionStatus(auction?.startDate || 0, auction?.endDate || 0, auction?.status);
  const { participants } = useAuctionUpdater(auction, status, setAuction);
  const dimension = useWindowDimensions();

  const auctionData = useMemo(() => getAuctionData(auction, nfts, token, status), [auction, nfts, token, status]);
  const { media, isLoading, typeImage } = useImageDataUpload(
    auctionData?.nft.metadata.media,
    auctionData?.nft.metadata.mime_type
  );

  if (loading.auction || !auction || !auctionData) return <Loading />;

  const nftDataArray = getNftData(
    dimension,
    auctionData.contractNft,
    auctionData.nft,
    auctionData.tokenMetadata,
    auction.auctionMinStep
  );

  return (
    <styles.Row>
      <AdminPanel auction={auction} />
      <styles.Container>
        <styles.CloseContainer
          onClick={() => {
            setAuction(null);
            navigate(APP_ROUTES.AUCTION.HOME);
          }}
        >
          <styles.CloseIcon />
        </styles.CloseContainer>
        <styles.MainContainer>
          <styles.ImageContainer>
            <NFTMedia media={media} isLoading={isLoading} />
          </styles.ImageContainer>
          <styles.AuctionDataContainer>
            <styles.TitleBlock>
              <styles.Title>{auctionData.nft.metadata.title}</styles.Title>
              <Status type={status} />
            </styles.TitleBlock>
            <styles.LabelBlock>
              <styles.LogoToken src={auctionData.tokenMetadata.icon} alt="deposit token logo" />
              <styles.Label>
                <Translate
                  value="AuctionLabel"
                  interpolation={{ symbol: auctionData.tokenMetadata.symbol, typeImage }}
                />
              </styles.Label>
            </styles.LabelBlock>
            <styles.BidBlock>
              {auctionData.bidArray.map(({ title, value }) => (
                <div key={title}>
                  <styles.TemplateTitle>
                    <Translate value={title} />
                  </styles.TemplateTitle>
                  <styles.TemplateLabel>{value}</styles.TemplateLabel>
                </div>
              ))}
            </styles.BidBlock>
            {status !== EStatus.Closed && <Timer time={auctionData.timeLeft} status={status} />}
          </styles.AuctionDataContainer>
        </styles.MainContainer>
        <styles.NftData>
          {nftDataArray.map(({ title, value, onClick }) => (
            <div key={title}>
              <styles.TemplateTitle>
                <Translate value={title} />
              </styles.TemplateTitle>
              <styles.TemplateLabel onClick={onClick}>{value}</styles.TemplateLabel>
            </div>
          ))}
        </styles.NftData>
        {auctionData.nft.metadata.description && (
          <styles.Description>{auctionData.nft.metadata.description}</styles.Description>
        )}
        <Footer socials={auctionData.socials} projectLink={auctionData.projectLink} />
        <BidHistory
          participants={participants}
          decimals={auctionData.tokenMetadata.decimals}
          symbol={auctionData.tokenMetadata.symbol}
        />
      </styles.Container>
    </styles.Row>
  );
}
