import { Button } from '@mui/material';

import { useStakingProvider } from 'providers/LocalProviders/StakingProvider';
import { Translate } from 'shared/components/Translate';
import { EFromValues } from 'shared/interfaces';

import { StakingDatePickerInput, StakingNumericalInput } from '../StakingInput';
import styles from './styles';

export default function CreateStaking() {
  const { createStakingForm } = useStakingProvider();
  const { getFieldMeta, getFieldProps, setFieldValue, submitForm } = createStakingForm;
  return (
    <styles.Container>
      <styles.Title>
        <styles.BocaChicaLogo />
        <Translate value="Staking.CreateStaking" />
      </styles.Title>
      <StakingNumericalInput
        name={EFromValues.sessionInterval}
        field={getFieldProps(EFromValues.sessionInterval)}
        meta={getFieldMeta(EFromValues.sessionInterval)}
      />
      <StakingNumericalInput
        name={EFromValues.distributionAmount}
        field={getFieldProps(EFromValues.distributionAmount)}
        meta={getFieldMeta(EFromValues.distributionAmount)}
      />
      <StakingNumericalInput
        name={EFromValues.distributionInDay}
        field={getFieldProps(EFromValues.distributionInDay)}
        meta={getFieldMeta(EFromValues.distributionInDay)}
      />
      <StakingDatePickerInput
        name={EFromValues.startAt}
        field={getFieldProps(EFromValues.startAt)}
        meta={getFieldMeta(EFromValues.startAt)}
        setFieldValue={setFieldValue}
      />
      <StakingNumericalInput
        name={EFromValues.minDeposit}
        field={getFieldProps(EFromValues.minDeposit)}
        meta={getFieldMeta(EFromValues.minDeposit)}
      />
      <styles.Description>
        <span>
          <Translate value="Description.Note" />
        </span>
        <br />
        <Translate value="Description.TermsForm1" />
        <br />
        <Translate value="Description.TermsForm2" />
      </styles.Description>
      <Button variant="contained" color="warning" onClick={submitForm}>
        <Translate value="Action.CreateStaking" />
      </Button>
    </styles.Container>
  );
}
