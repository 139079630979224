import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import auctionBackground from 'assets/images/auctionBackground.svg';
import marsBackground from 'assets/images/marsBackground.svg';
import * as Auction from 'pages/Auction';
import * as Mars from 'pages/Mars';
import Staking from 'pages/Staking';
import Whitelist from 'pages/Whitelist';
import { useWalletData } from 'providers/WalletProvider';
import Footer from 'shared/components/Footer';
import Header from 'shared/components/Header';
import { useAppDispatch } from 'shared/hooks/redux';
import useTransactionHash from 'shared/hooks/useTransactionHash';
import { loadAuctions } from 'store/actions/loadAuctions';
import { loadSales } from 'store/actions/loadSales';
import { loadServiceData } from 'store/actions/loadServiceData';
import { loadStaking } from 'store/actions/loadStaking';

import { APP_ROUTES, AUCTION, STAKING } from './constant';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Background = styled.div<{ isAuction: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${({ isAuction }) => (isAuction ? auctionBackground : marsBackground)});
  background-position: center 5.25rem;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-position: center 8rem;
    background-size: 44rem;
  `}
`;

const Pages = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100vw;
  z-index: 1;
`;

export default function AppRoutes(): JSX.Element {
  useTransactionHash();
  const location = useLocation();
  const isAuction = location.pathname.includes(AUCTION);
  const dispatch = useAppDispatch();
  const { walletSelector } = useWalletData();

  useEffect(() => {
    if (!walletSelector) return;
    dispatch(loadServiceData({ walletSelector }));
    switch (location.pathname) {
      case AUCTION: {
        dispatch(loadAuctions());
        dispatch(loadSales());
        dispatch(loadStaking());
        break;
      }
      case STAKING: {
        dispatch(loadStaking());
        dispatch(loadSales());
        dispatch(loadAuctions());
        break;
      }
      default: {
        dispatch(loadSales());
        dispatch(loadAuctions());
        dispatch(loadStaking());
      }
    }
  }, [walletSelector, dispatch]);

  return (
    <Container>
      <Background isAuction={isAuction} />
      <ToastContainer />
      <Header />
      <Pages>
        <Routes>
          <Route path={APP_ROUTES.STAKING} element={<Staking />} />
          <Route path={APP_ROUTES.WHITELIST} element={<Whitelist />} />
          <Route path={APP_ROUTES.MARS.HOME} element={<Mars.HomePage />} />
          <Route path={APP_ROUTES.MARS.SALE_BY_ID} element={<Mars.SalePage />} />
          <Route path={APP_ROUTES.MARS.CREATE_SALE} element={<Mars.CreateSalePage />} />
          <Route path={APP_ROUTES.MARS.FUTURE_SALE_BY_ID} element={<Mars.FutureSalePage />} />
          <Route path={APP_ROUTES.AUCTION.HOME} element={<Auction.HomePage />} />
          <Route path={APP_ROUTES.AUCTION.AUCTION_BY_ID} element={<Auction.AuctionPage />} />
          <Route path={APP_ROUTES.AUCTION.CREATE_SALE} element={<Auction.CreateAuctionPage />} />
          <Route path={APP_ROUTES.DEFAULT} element={<Navigate replace to={APP_ROUTES.MARS.HOME} />} />
        </Routes>
      </Pages>
      <Footer />
    </Container>
  );
}
