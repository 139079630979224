import styled from 'styled-components';

import { ReactComponent as BocaChicaLogo } from 'assets/images/bocachica-logo.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.white};
  min-height: 160px;
  padding: 1.125rem 1.5rem 1.5rem;
  border-radius: 1.5rem;
  gap: 1rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.black};
  & > svg {
    margin-right: 0.5rem;
    path {
      fill: ${({ theme }) => theme.black};
    }
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.gray};
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
  & > span {
    color: ${({ theme }) => theme.black};
  }
`;

export default {
  Container,
  Title,
  Description,
  BocaChicaLogo,
};
