import Button from '@mui/material/Button';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { APP_ROUTES } from 'routes/constant';
import AuctionCard from 'shared/components/AuctionCard';
import Loading from 'shared/components/Loading';
import { Translate } from 'shared/components/Translate';
import { EMPTY_STRING } from 'shared/constants';
import { IAuction } from 'shared/interfaces';
import { getFilteredAuctionArray } from 'shared/utils';
import { selectData } from 'store/selectors/selectData';

import styles from './styles';

export default function HomePage(): JSX.Element {
  const { loading, auctions, nfts } = useSelector(selectData);
  const navigate = useNavigate();
  const auctionsArray = getFilteredAuctionArray(auctions.arr);
  const [searchValue, setSearchValue] = useState<string>(EMPTY_STRING);

  const [retrievedAuctions, setRetrievedAuctions] = useState<IAuction[]>(auctionsArray);

  const onChange = useCallback(
    (search: string) => {
      setSearchValue(search);
      const auctionsBySearch =
        search !== EMPTY_STRING
          ? auctionsArray.filter((auction) => {
              const contractNft = nfts[auction.nftContractId];
              const nft = contractNft.tokenMetadata[auction.nftTokenId];
              return nft.metadata.title.toLowerCase().includes(search.toLowerCase());
            })
          : auctionsArray;
      setRetrievedAuctions(auctionsBySearch);
    },
    [auctionsArray, nfts]
  );

  useEffect(() => {
    if (auctionsArray.length !== retrievedAuctions.length && searchValue === EMPTY_STRING) {
      setRetrievedAuctions(auctionsArray);
    }
  }, [auctionsArray, retrievedAuctions.length, searchValue]);

  if (loading.auction) return <Loading />;
  return (
    <styles.Container>
      <styles.Header>
        <styles.StyledInput>
          <styles.Search />
          <styles.Input
            value={searchValue}
            onChange={(value) => onChange(value.target.value)}
            placeholder={t('Search')}
          />
          {searchValue && <styles.ClearSearch onClick={() => onChange(EMPTY_STRING)} />}
        </styles.StyledInput>
        <Button variant="contained" color="secondary" onClick={() => navigate(APP_ROUTES.AUCTION.CREATE_SALE)}>
          <Translate value="Action.CreateAuction" />
        </Button>
      </styles.Header>
      <styles.WrapperCards>
        {retrievedAuctions.map((auction) => (
          <AuctionCard key={auction.id} auction={auction} />
        ))}
      </styles.WrapperCards>
    </styles.Container>
  );
}
