import Card from 'shared/components/Card';
import FutureCard from 'shared/components/Card/FutureCard';
import { IFutureSale, ISale } from 'shared/interfaces';

export default function Cards({ future, sales }: { future: Array<IFutureSale>; sales: Array<ISale> }) {
  return (
    <>
      {future.map((sale) => (
        <FutureCard key={`${sale.metadata.name} - ${sale.id}`} sale={sale} />
      ))}
      {sales.map((sale) => (
        <Card key={`${sale.metadata.name} - ${sale.id}`} sale={sale} />
      ))}
    </>
  );
}
