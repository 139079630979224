import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FormikErrors } from 'formik';

import { createFormattedDate } from 'services/helpers';
import { EFromValues, IValues } from 'shared/interfaces';

import { translate, Translate } from '../Translate';
import styles from './styles';

interface IRoundDate {
  values: IValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: FormikErrors<IValues>;
}

export default function RoundDate({ values, setFieldValue, errors }: IRoundDate) {
  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateSale.RoundDate" />
      </styles.Title>
      <styles.ChildWrapper>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => (
              <TextField {...props} error={Boolean(errors.roundStartDate)} helperText={errors.roundStartDate} />
            )}
            label={translate({ value: 'CreateSale.StartDate' })}
            value={values.roundStartDate}
            onChange={(date) => {
              if (!date) return;
              setFieldValue(EFromValues.roundStartDate, createFormattedDate(date));
            }}
            ampm={false}
          />
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => (
              <TextField {...props} error={Boolean(errors.roundFinishDate)} helperText={errors.roundFinishDate} />
            )}
            label={translate({ value: 'CreateSale.EndDate' })}
            value={values.roundFinishDate}
            onChange={(date) => {
              if (!date) return;
              setFieldValue(EFromValues.roundFinishDate, createFormattedDate(date));
            }}
            ampm={false}
          />
        </LocalizationProvider>
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
