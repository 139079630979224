import { useStakingProvider } from 'providers/LocalProviders/StakingProvider';
import { useAppSelector } from 'shared/hooks/redux';
import { selectToken } from 'store/selectors/selectToken';
import { selectStaking } from 'store/slices/staking';

import StakingCard from '../StakingCard';
import Statistics from '../Statistics';
import styles from './styles';
import convertStakingValuesForm from './utils';

export default function StickyContainer() {
  const { createStakingForm } = useStakingProvider();
  const staking = useAppSelector(selectStaking);
  const rewardToken = useAppSelector((state) => selectToken(state, staking.rewardTokenId));
  const metadata = rewardToken?.metadata;
  if (!rewardToken || !metadata) return null;
  const convertedStaking = convertStakingValuesForm(
    createStakingForm.values,
    staking.farms.arr.length,
    metadata.decimals,
    rewardToken.contractId,
    staking.totalStaked
  );
  return (
    <styles.Container>
      <Statistics metadata={metadata} />
      {convertedStaking && <StakingCard staking={convertedStaking} token={rewardToken} />}
    </styles.Container>
  );
}
