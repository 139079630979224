import { SaleContract } from 'services/contract';
import { SaleOutput } from 'services/interfaces';
import { DEFAULT_PAGE_LIMIT } from 'shared/constants';
import { isNotNullOrUndefined, formatSale } from 'shared/utils';
import formatFutureSale from 'shared/utils/formatFutureSale';

import { assertFulfilled } from './index';

export async function retrieveSaleResult(pages: number, contract: SaleContract) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) => contract.getSales(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT))
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveSaleArray(contract: SaleContract) {
  const salesLength = await contract.getNumSales();
  const pages = Math.ceil(salesLength ? salesLength / DEFAULT_PAGE_LIMIT : 0);
  const salesResult = await retrieveSaleResult(pages, contract);
  const saleArray = salesResult.filter(isNotNullOrUndefined).map((sale: SaleOutput) => formatSale(sale));
  return saleArray;
}

export async function retrieveSaleAccountsResult(pages: number, contract: SaleContract, saleId: number) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) => contract.getSaleAccounts(saleId, i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT))
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveSaleAccountsArray(contract: SaleContract, saleId: number, numAccountSales: number) {
  const pages = Math.ceil(numAccountSales / DEFAULT_PAGE_LIMIT);
  const resultsArray = await Promise.allSettled(
    [...Array(pages)].map((_, i) => contract.getSaleAccounts(saleId, i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT))
  );
  const filteredResultsArray = resultsArray
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
  return filteredResultsArray;
}

export async function retrieveFutureSaleResult(pages: number, contract: SaleContract) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) => contract.getFutureSales(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT))
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveFutureSale(contract: SaleContract) {
  const salesLength = await contract.getNumFutureSales();
  const pages = Math.ceil(salesLength ? salesLength / DEFAULT_PAGE_LIMIT : 0);
  const salesResult = await retrieveFutureSaleResult(pages, contract);
  return salesResult.filter(isNotNullOrUndefined).map(([id, sale]) => formatFutureSale(id, sale));
}
