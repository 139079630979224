import Big from 'big.js';
import dayjs, { Dayjs } from 'dayjs';
import { utils } from 'near-api-js';

import { ZERO, ZERO_STR } from 'shared/constants';

export const getGas = (gas?: string) => (gas ? new Big(gas) : new Big('100000000000000'));
export const getAmount = (amount?: string) =>
  amount ? new Big(utils.format.parseNearAmount(amount) ?? ZERO_STR) : new Big(ZERO_STR);

export const createFormattedDate = (date: Date | string | null | undefined | Dayjs) =>
  dayjs(date).set('seconds', ZERO).format('YYYY-MM-DD HH:mm:ss');
