import { IAction } from 'services/interfaces';
import {
  AuctionInput,
  AuctionMetadata,
  IAuctionAccount,
  IAuctionContract,
  IAuctionOutput,
  IParticipantArray,
} from 'services/interfaces/auctionContract';
import { IRPCProviderService } from 'services/RPCProviderService';

import { AuctionChangeMethods, AuctionViewMethods } from './contractMethods';

export default class AuctionContract implements IAuctionContract {
  readonly contractId: string;

  private provider: IRPCProviderService;

  public constructor(contractId: string, provider: IRPCProviderService) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getAuctions(from: number, limit: number): Promise<IAuctionOutput[] | undefined> {
    return this.provider.viewFunction(AuctionViewMethods.getAuctions, this.contractId, { from_index: from, limit });
  }

  async getNumberOfAuctions(): Promise<number | undefined> {
    return this.provider.viewFunction(AuctionViewMethods.getNumAuctions, this.contractId);
  }

  async getAuctionAccount(auctionId: number, accountId: string): Promise<IAuctionAccount | undefined> {
    return this.provider.viewFunction(AuctionViewMethods.getAuctionAccount, this.contractId, {
      auction_id: auctionId,
      account_id: accountId,
    });
  }

  async getNumberOfParticipantsAuction(auctionId: number): Promise<number | undefined> {
    return this.provider.viewFunction(AuctionViewMethods.getAuctionsAccounts, this.contractId, {
      auction_id: auctionId,
    });
  }

  async getAuctionAccounts(auctionId: number, fromIndex: number, limit: number): Promise<Array<IParticipantArray>> {
    return this.provider.viewFunction(AuctionViewMethods.getAuctionAccounts, this.contractId, {
      auction_id: auctionId,
      from_index: fromIndex,
      limit,
    });
  }

  async getAuction(auctionId: number): Promise<IAuctionOutput | undefined> {
    return this.provider.viewFunction(AuctionViewMethods.getAuction, this.contractId, { auction_id: auctionId });
  }

  removeAuction(auctionId: number): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: AuctionChangeMethods.removeAuction,
          args: { auction_id: auctionId },
        },
      ],
    };
  }

  updateAuctionDates(auctionId: number, startDate: number, endDate: number): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: AuctionChangeMethods.updateAuctionDates,
          args: {
            auction_id: auctionId,
            start_date: startDate.toFixed(),
            end_date: endDate.toFixed(),
          },
        },
      ],
    };
  }

  updateAuctionClaimAvailable(auctionId: number, claimAvailable: boolean): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: AuctionChangeMethods.updateAuctionClaimAvailable,
          args: {
            auction_id: auctionId,
            claim_available: claimAvailable,
          },
        },
      ],
    };
  }

  updateAuctionRefundAvailable(auctionId: number, refundAvailable: boolean): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: AuctionChangeMethods.updateAuctionRefundAvailable,
          args: {
            auction_id: auctionId,
            refund_available: refundAvailable,
          },
        },
      ],
    };
  }

  updateAuctionMetadata(auctionId: number, metadata: AuctionMetadata): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: AuctionChangeMethods.updateMetadata,
          args: {
            auction_id: auctionId,
            metadata,
          },
        },
      ],
    };
  }

  createAuction(auction: AuctionInput): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: AuctionChangeMethods.createAuction,
          args: { auction },
        },
      ],
    };
  }
}
