import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISale, IFutureSale } from 'shared/interfaces/sale';
import { RootState } from 'store';

type SalesState = {
  obj: Record<number, ISale>;
  future: Record<number, IFutureSale>;
  loading: boolean;
};

const initialState: SalesState = {
  obj: {},
  future: {},
  loading: true,
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setSales(state, action: PayloadAction<Record<string, ISale>>) {
      return {
        ...state,
        obj: action.payload,
      };
    },
    setFutureSales(state, action: PayloadAction<Record<string, IFutureSale>>) {
      return {
        ...state,
        future: action.payload,
      };
    },
    setSalesLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

// SELECTORS
export const selectSales = (state: RootState) => state.sales.obj;
export const selectSalesLoading = (state: RootState) => state.sales.loading;
export const selectFutureSales = (state: RootState) => state.sales.future;

// ACTIONS
export const { setSalesLoading, setSales, setFutureSales } = salesSlice.actions;

export const salesReducer = salesSlice.reducer;
