import dayjs from 'dayjs';
import * as Yup from 'yup';

import { usn } from 'services/config';
import i18n from 'services/translation';
import { EMPTY_STRING, VALID_VALUE_CONTRACT_ID } from 'shared/constants';

export const saleValidationSchema = Yup.object({
  projectName: Yup.string().trim().required('Required'),
  description: Yup.string().trim().required('Required'),
  depositTokenId: Yup.string()
    .trim()
    .test('Is valid token id?', `Reward token id must contain ${VALID_VALUE_CONTRACT_ID}!`, (value) => {
      if (value && value.toLowerCase() === usn) return true;
      if (value && value.includes(VALID_VALUE_CONTRACT_ID)) return true;
      return false;
    })
    .required('Required'),

  maxAmount: Yup.number()
    .when('minAllocation', (minAllocation: number | undefined, schema) =>
      minAllocation
        ? schema.min(minAllocation, 'Must be greater than or equal Min Allocation')
        : schema.min(1, 'Must be greater than or equal 1')
    )
    .required('Required'),
  distributeTokenId: Yup.string()
    .trim()
    .test('Is valid token id?', `Distribute token id must contain ${VALID_VALUE_CONTRACT_ID}!`, (value) => {
      if (value && value.toLowerCase() === usn) return true;
      if (value && value.includes(VALID_VALUE_CONTRACT_ID)) return true;
      return false;
    })
    .required('Required'),
  minAllocation: Yup.number().positive('Value must be a positive number').required('Required'),
  maxAllocation: Yup.number()
    .when('minAllocation', (minAllocation: number | undefined, schema) =>
      minAllocation ? schema.min(minAllocation, 'Must be greater than or equal Min Allocation') : schema
    )
    .required('Required'),
  limitPerTransaction: Yup.number()
    .when('maxAllocation', (maxAllocation: number | undefined, schema) =>
      maxAllocation
        ? schema.max(maxAllocation, 'Must be less than or equal Max Allocation')
        : schema.positive('Value must be a positive number')
    )
    .required('Required'),

  roundType: Yup.string()
    .oneOf(['ByAmount', 'BySubscription'], 'Must be ByAmount or BySubscription')
    .required('Choose one of the values: Amount or Subscription'),
  price: Yup.number().positive('Value must be a positive number').required('Required'),

  roundStartDate: Yup.string()
    .required('Required')
    .test('Is valid date', 'The selected date must be greater than the current one', (value) => {
      if (!value) return true;
      const dateNow = Date.now();
      const date = new Date(value).valueOf();
      return date >= dateNow;
    }),
  roundFinishDate: Yup.string()
    .required('Required')
    .test('Is valid date', 'The selected date must be greater than the current one', (value) => {
      if (!value) return true;
      const dateNow = Date.now();
      const date = new Date(value).valueOf();
      return date >= dateNow;
    }),
  projectSite: Yup.string().url('Must be a valid URL').required('Required'),
  socialTg: Yup.string().url('Must be a valid URL').notRequired(),
  socialTwitter: Yup.string().url('Must be a valid URL').notRequired(),
  socialMedium: Yup.string().url('Must be a valid URL').notRequired(),
});

export const futureSaleValidationSchema = Yup.object({
  projectName: Yup.string().trim().required('Required'),
  description: Yup.string().trim().required('Required'),
  depositTokenId: Yup.string()
    .trim()
    .test('Is valid token id?', `Reward token id must contain ${VALID_VALUE_CONTRACT_ID}!`, (value) => {
      if (value && value.toLowerCase() === usn) return true;
      if (value && value.includes(VALID_VALUE_CONTRACT_ID)) return true;
      return false;
    })
    .required('Required'),
  distributeTokenId: Yup.string()
    .trim()
    .test('Is valid token id?', `Distribute token id must contain ${VALID_VALUE_CONTRACT_ID}!`, (value) => {
      if (value && value.toLowerCase() === usn) return true;
      if (value && value.includes(VALID_VALUE_CONTRACT_ID)) return true;
      return false;
    })
    .required('Required'),
  projectSite: Yup.string().url('Must be a valid URL').required('Required'),
  socialTg: Yup.string().url('Must be a valid URL').notRequired(),
  socialTwitter: Yup.string().url('Must be a valid URL').notRequired(),
  socialMedium: Yup.string().url('Must be a valid URL').notRequired(),

  maxAmount: Yup.number().when('minAllocation', (minAllocation: number | undefined, schema) =>
    minAllocation
      ? schema.min(minAllocation, 'Must be greater than or equal Min Allocation')
      : schema.min(1, 'Must be greater than or equal 1')
  ),
  minAllocation: Yup.number().positive('Value must be a positive number'),
  maxAllocation: Yup.number().when('minAllocation', (minAllocation: number | undefined, schema) =>
    minAllocation ? schema.min(minAllocation, 'Must be greater than or equal Min Allocation') : schema
  ),
  limitPerTransaction: Yup.number().when('maxAllocation', (maxAllocation: number | undefined, schema) =>
    maxAllocation
      ? schema.max(maxAllocation, 'Must be less than or equal Max Allocation')
      : schema.positive('Value must be a positive number')
  ),
  roundType: Yup.string().oneOf(['ByAmount', 'BySubscription'], 'Must be ByAmount or BySubscription'),
  price: Yup.number().positive('Value must be a positive number'),

  roundStartDate: Yup.string().test(
    'Is valid date',
    'The selected date must be greater than the current one',
    (value) => {
      if (!value) return true;
      const dateNow = Date.now();
      const date = new Date(value).valueOf();
      return date >= dateNow;
    }
  ),
  roundFinishDate: Yup.string().test(
    'Is valid date',
    'The selected date must be greater than the current one',
    (value) => {
      if (!value) return true;
      const dateNow = Date.now();
      const date = new Date(value).valueOf();
      return date >= dateNow;
    }
  ),
});

export const auctionValidationSchema = Yup.object({
  nftContractId: Yup.string().trim().required('Required'),
  nftTokenId: Yup.string().trim().required('Required'),
  depositTokenId: Yup.string()
    .trim()
    .test('Is valid token id?', `Reward token id must contain ${VALID_VALUE_CONTRACT_ID}!`, (value) => {
      if (value && value.toLowerCase() === usn) return true;
      if (value && value.includes(VALID_VALUE_CONTRACT_ID)) return true;
      return false;
    })
    .required('Required'),

  initialPrice: Yup.number().positive('Value must be a positive number').required('Required'),
  buyoutPrice: Yup.number().positive('Value must be a positive number'),
  auctionStep: Yup.number().positive('Value must be a positive number'),
  auctionMinStep: Yup.number().positive('Value must be a positive number'),

  startDate: Yup.date()
    .required('Required')
    .test('Is valid date', 'The selected date must be greater than the current one', (value) => {
      if (!value) return true;
      const dateNow = Date.now();
      const date = new Date(value).valueOf();
      return date >= dateNow;
    }),
  endDate: Yup.date()
    .required('Required')
    .test('Is valid date', 'The selected date must be greater than the current one', (value) => {
      if (!value) return true;
      const dateNow = Date.now();
      const date = new Date(value).valueOf();
      return date >= dateNow;
    })
    .when('startDate', (startDate: Date | undefined, schema) =>
      startDate ? schema.min(startDate, 'Must be greater than or Start Date') : schema
    ),

  projectLink: Yup.string().url('Must be a valid URL').required('Required'),
  twitterLink: Yup.string().url('Must be a valid URL').notRequired(),
  mediumLink: Yup.string().url('Must be a valid URL').notRequired(),
  telegramLink: Yup.string().url('Must be a valid URL').notRequired(),
});

export const validationCreateStakingSchema = Yup.object({
  sessionInterval: Yup.number()
    .test('Is positive?', i18n.t('Error.MustBePositiveValue') || EMPTY_STRING, (value) => (value || 0) >= 0)
    .required(i18n.t('Error.Required') || EMPTY_STRING),
  distributionInDay: Yup.number()
    .positive(i18n.t('Error.MustBePositiveValue') || EMPTY_STRING)
    .required(i18n.t('Error.Required') || EMPTY_STRING),
  distributionAmount: Yup.number()
    .positive(i18n.t('Error.MustBePositiveValue') || EMPTY_STRING)
    .required(i18n.t('Error.Required') || EMPTY_STRING),

  startAt: Yup.string()
    .test('Is valid date?', i18n.t('Error.StartDate') || EMPTY_STRING, (value) => {
      const newDate = dayjs(value).add(dayjs().utcOffset(), 'minutes').valueOf();
      if (newDate > new Date().valueOf()) return true;
      return false;
    })
    .required(i18n.t('Error.Required') || EMPTY_STRING),
  minDeposit: Yup.number()
    .test('Is positive?', i18n.t('Error.MustBePositiveValue') || EMPTY_STRING, (value) => (value || 0) >= 0)
    .notRequired(),
});
