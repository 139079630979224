import FailedToLoad from 'assets/images/failed-to-load.svg';

import { translate } from '../Translate';
import styles from './styles';

interface INFTMedia {
  media: string;
  isLoading: boolean;
}

export default function NFTMedia({ media, isLoading }: INFTMedia) {
  if (isLoading) {
    return <styles.Loading />;
  }
  return (
    <styles.Image
      src={media}
      alt={translate({ value: 'NFT' })}
      onError={(e) => {
        (e.target as HTMLInputElement).onerror = null;
        (e.target as HTMLInputElement).src = FailedToLoad;
      }}
    />
  );
}
