export const MARS = 'mars';
export const AUCTION = 'auction';
export const FUTURE = 'future';
export const WHITELIST = 'whitelist';
export const STAKING = 'staking';

export const APP_ROUTES = {
  MARS: {
    HOME: `/${MARS}`,
    SALE_BY_ID: `/${MARS}/:id`,
    FUTURE_SALE_BY_ID: `/${MARS}/${FUTURE}/:id`,
    CREATE_SALE: `/${MARS}/create-sale`,
  },
  AUCTION: {
    HOME: `/${AUCTION}`,
    AUCTION_BY_ID: `/${AUCTION}/:id`,
    CREATE_SALE: `/${AUCTION}/create-sale`,
  },
  WHITELIST: `/${WHITELIST}`,
  STAKING: `/${STAKING}`,
  DEFAULT: '*',
};

export const toSale = (id: number) => `${APP_ROUTES.MARS.HOME}/${id}`;
export const toAuction = (id: number) => `${APP_ROUTES.AUCTION.HOME}/${id}`;
export const toFutureSale = (id: number) => `${APP_ROUTES.MARS.HOME}/${FUTURE}/${id}`;

export const getCurrentRoute = () => {
  const path = window.location.pathname.split('/')[1];
  switch (path) {
    case MARS:
      return APP_ROUTES.MARS.HOME;
    case AUCTION:
      return APP_ROUTES.AUCTION.HOME;
    case WHITELIST:
      return APP_ROUTES.WHITELIST;
    case STAKING:
      return APP_ROUTES.STAKING;
    default:
      return APP_ROUTES.DEFAULT;
  }
};
