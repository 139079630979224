import styles from 'pages/Mars/create-sale-page/styles';
import { ESaleKind } from 'services/interfaces';
import PlaneCheckbox from 'shared/components/PlaneCheckbox';
import { Translate } from 'shared/components/Translate';

export default function Header({
  saleKind,
  changeSaleKind,
}: {
  saleKind: ESaleKind;
  changeSaleKind: ({ target }: { target: HTMLInputElement }) => void;
}) {
  return (
    <styles.Header>
      <p>
        <Translate value="CreateSale.SaleKind.Simple" />
      </p>
      <PlaneCheckbox checked={saleKind === ESaleKind.FUTURE} onChange={changeSaleKind} />
      <p>
        <Translate value="CreateSale.SaleKind.Future" />
      </p>
    </styles.Header>
  );
}
