import { useEffect, useMemo, useState } from 'react';

import { IParticipants, initialParticipantsValue } from 'pages/Auction/auction-page/participantData';
import { UPDATE_AUCTION_INTERVAL, ZERO_STR } from 'shared/constants';
import { EStatus, IAuction } from 'shared/interfaces';
import { checkAuctionShouldUpdate, formatAuction } from 'shared/utils';
import { retrieveParticipants } from 'store/actions/helpers';
import { selectAuctionContract } from 'store/slices/services';

import { useAppSelector } from './redux';

const useAuctionUpdater = (
  auction: IAuction | null,
  status: EStatus,
  setAuction?: React.Dispatch<React.SetStateAction<IAuction | null>>
) => {
  const isAuctionPage = useMemo(() => !!setAuction, [setAuction]);
  const auctionContract = useAppSelector(selectAuctionContract);

  const [participants, setParticipants] = useState<IParticipants>(initialParticipantsValue);
  const [winnerBid, setWinnerBid] = useState<string>(auction?.winnerBid || ZERO_STR);
  const [yourBid, setYourBid] = useState<string>(auction?.userData?.amount || ZERO_STR);

  useEffect(() => {
    const updateData = async () => {
      const shouldUpdate = (isAuctionPage && !participants.isUpdated) || checkAuctionShouldUpdate(auction, status);
      if (!auction || !shouldUpdate) return;
      try {
        if (!auctionContract) return;
        const updatedAuction = await auctionContract.getAuction(auction.id);
        if (!updatedAuction) return;
        const newUpdatedAuction = formatAuction(updatedAuction);
        const isEqualBids = newUpdatedAuction.winnerBid === auction.winnerBid;
        if (!isEqualBids) {
          setWinnerBid(newUpdatedAuction.winnerBid);
          setYourBid(newUpdatedAuction.userData?.amount || ZERO_STR);
        }
        if (!isAuctionPage) return;
        const retrievedParticipants = await retrieveParticipants(auctionContract, auction.id);
        if (retrievedParticipants) {
          const participantData = {
            length: retrievedParticipants.length,
            isUpdated: true,
            arr: retrievedParticipants.arr,
          };
          setParticipants(participantData);
        }
        if (!isEqualBids && setAuction) setAuction(newUpdatedAuction);
      } catch (e) {
        console.warn(`Error: ${e} while update auction data`);
      }
    };
    const interval = setInterval(updateData, UPDATE_AUCTION_INTERVAL);
    return () => clearInterval(interval);
  }, [auction, auctionContract, isAuctionPage, participants.isUpdated, setAuction, status]);

  return { winnerBid, yourBid, participants };
};

export default useAuctionUpdater;
