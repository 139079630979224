import { ESaleType } from 'services/interfaces';
import { ZERO_STR } from 'shared/constants';
import { ISale, IFutureSale } from 'shared/interfaces';
import { getTimeByDate } from 'shared/utils';

const formatFutureSaleToSimpleSale = (futureSale: IFutureSale): ISale => {
  const { minBuy, maxBuy, totalAmount, startDate, endDate, price, limitPerTransaction, saleType } = futureSale;

  const sale: ISale = {
    ...futureSale,
    claimAvailable: false,
    refundAvailable: false,
    saleType: saleType || ESaleType.Empty,
    minBuy: minBuy || ZERO_STR,
    maxBuy: maxBuy || ZERO_STR,
    price: price || ZERO_STR,
    totalAmount: totalAmount || ZERO_STR,
    collectedAmount: ZERO_STR,
    startDate: !startDate ? 0 : getTimeByDate(startDate),
    endDate: !endDate ? 0 : getTimeByDate(endDate),
    limitPerTransaction: limitPerTransaction || ZERO_STR,
  };
  return sale;
};

export default formatFutureSaleToSimpleSale;
