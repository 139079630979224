import useVesting from 'shared/hooks/useVesting';
import { ETypeVesting, IVesting, Cliff } from 'shared/interfaces';

import Timer from '../Timer';
import { Translate } from '../Translate';
import styles from './styles';
import VestingList from './VestingList';

interface IVestingSection {
  rewardTicker: string;
  vesting: IVesting[];
  vestingType: ETypeVesting | null;
  cliff: Cliff | null;
  totalClaimAvailable: string;
}

export default function VestingSection({
  rewardTicker,
  vesting,
  vestingType,
  cliff,
  totalClaimAvailable,
}: IVestingSection) {
  const { timestamp, vestingEnded, vestingDetails } = useVesting(vesting, vestingType, totalClaimAvailable);

  if (cliff?.active) {
    return (
      <styles.DistributionInContainer>
        <Timer time={cliff.timestamp} isDistributionIn={cliff.active} />
      </styles.DistributionInContainer>
    );
  }

  return (
    <styles.Container>
      <styles.Title>
        <Translate value="Vesting.Title" />
      </styles.Title>
      <styles.UserInfo>
        {vestingDetails.tokensArray.map((el) => (
          <styles.UserInfoRow key={el.title}>
            <p>{el.title}</p>
            <p>
              <Translate
                value="Sale.Price"
                interpolation={{
                  value: el.value,
                  symbol: rewardTicker,
                }}
              />
            </p>
          </styles.UserInfoRow>
        ))}
      </styles.UserInfo>
      {vestingType === ETypeVesting.Stepwise && (
        <VestingList vesting={vestingDetails.array} rewardTicker={rewardTicker} />
      )}
      {!vestingEnded && <Timer time={timestamp} vestingType={vestingType} hideBackground />}
    </styles.Container>
  );
}
