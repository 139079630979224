import { createSelector } from '@reduxjs/toolkit';

import { selectAuctionsLoading } from 'store/slices/auctions';
import { selectNftsLoading } from 'store/slices/nfts';
import { selectSalesLoading } from 'store/slices/sales';
import { selectStakingLoading } from 'store/slices/staking';
import { selectTokensLoading } from 'store/slices/tokens';

export const selectLoading = createSelector(
  [selectAuctionsLoading, selectNftsLoading, selectSalesLoading, selectStakingLoading, selectTokensLoading],
  (auctionsLoading, nftsLoading, salesLoading, stakingLoading, tokensLoading) => ({
    auction: tokensLoading && auctionsLoading && nftsLoading,
    sale: tokensLoading && salesLoading,
    staking: tokensLoading && stakingLoading,
  })
);
