import { useLocation, useNavigate } from 'react-router-dom';

import { useWalletData } from 'providers/WalletProvider';
import { APP_ROUTES, AUCTION } from 'routes/constant';
import { useAppSelector } from 'shared/hooks/redux';
import { selectUser } from 'store/slices/user';

import { translate } from '../Translate';
import Products from './components/Products';
import styles from './styles';

export default function Header() {
  const { openModal, signOut } = useWalletData();
  const { isSignedIn, id: accountId } = useAppSelector(selectUser);

  const navigate = useNavigate();
  const title = isSignedIn ? accountId : translate({ value: 'Action.ConnectWallet' });
  const location = useLocation();
  const isAuction = location.pathname.includes(AUCTION);
  const handleClick = isSignedIn ? signOut : openModal;
  return (
    <styles.Header>
      <styles.LogoContainer onClick={() => navigate(isAuction ? APP_ROUTES.AUCTION.HOME : APP_ROUTES.MARS.HOME)}>
        <styles.BocaChicaLogo />
      </styles.LogoContainer>
      <Products />
      <styles.StyledButton variant="contained" color={isAuction ? 'secondary' : 'warning'} onClick={handleClick}>
        <styles.WalletLogo />
        {title}
      </styles.StyledButton>
    </styles.Header>
  );
}
