import { DASH } from 'shared/constants';
import { getParseDate } from 'shared/utils';

import { Translate } from '../Translate';
import SaleDataStyles from './styles';

interface ISaleDate {
  startDate: number;
  endDate: number;
}

export default function SaleDate({ startDate, endDate }: ISaleDate) {
  const parseStartDate = getParseDate(new Date(startDate).toString());
  const parseEndDate = getParseDate(new Date(endDate).toString());

  const startStr =
    startDate === 0 ? (
      DASH
    ) : (
      <Translate
        value="Sale.LongDate"
        interpolation={{
          month: parseStartDate.monthString,
          day: parseStartDate.day,
          year: parseStartDate.year,
          hours: parseStartDate.hours,
          minutes: parseStartDate.minutes,
        }}
      />
    );
  const finishStr =
    endDate === 0 ? (
      DASH
    ) : (
      <Translate
        value="Sale.LongDate"
        interpolation={{
          month: parseEndDate.monthString,
          day: parseEndDate.day,
          year: parseEndDate.year,
          hours: parseEndDate.hours,
          minutes: parseEndDate.minutes,
        }}
      />
    );
  return (
    <SaleDataStyles.DeadlineWrapper>
      <div>
        <span>
          <Translate value="Sale.Start" />
        </span>
        <p>{startStr}</p>
      </div>
      <div>
        <span>
          <Translate value="Sale.Finish" />
        </span>
        <p>{finishStr}</p>
      </div>
    </SaleDataStyles.DeadlineWrapper>
  );
}
