import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction, VestingOutput } from 'services/interfaces';
import { RootState } from 'store';

export const changeVesting = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    saleId: number;
    inputVesting: VestingOutput[];
  },
  { state: RootState }
>('sales/changeVesting', async ({ sendTransaction, saleId, inputVesting }, { getState }) => {
  try {
    const {
      services: { saleContract },
    } = getState();
    if (!saleContract) return;
    const action = saleContract.changeVesting(saleId, inputVesting);
    await sendTransaction([action]);
  } catch (error) {
    console.error('Error during changeVesting: ', error);
  }
});
