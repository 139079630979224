import { EFromValues } from 'shared/interfaces';

export const formTitles: { [key: string]: string } = {
  [EFromValues.rewardToken]: 'Reward Token',

  [EFromValues.poolId]: 'Pool',
  [EFromValues.minDeposit]: 'Min Deposit',
  [EFromValues.startAt]: 'Start At',
  [EFromValues.sessionInterval]: 'Session Interval',
  [EFromValues.distributionInDay]: 'Distribution duration in days',
  [EFromValues.distributionAmount]: 'Distribution amount',

  [EFromValues.farmIndex]: 'Farm Index',
};

export const formPlaceholders: { [key: string]: string } = {
  [EFromValues.rewardToken]: 'token.bocachica_mars.near',

  [EFromValues.poolId]: '4: WNEAR/CHICA - $41602.5686',
  [EFromValues.minDeposit]: 'optional parament',
  [EFromValues.startAt]: '1655412424',
  [EFromValues.sessionInterval]: '60',
  [EFromValues.distributionInDay]: '333',
  [EFromValues.distributionAmount]: '10000',

  [EFromValues.farmIndex]: '0',
};
