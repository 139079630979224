import Big from 'big.js';

import { FungibleTokenContract } from 'services/contract';
import { createFormattedDate } from 'services/helpers';
import { ESaleType, ITokenMetadata } from 'services/interfaces';
import { EMPTY_STRING, ZERO, ZERO_STR } from 'shared/constants';
import { EStatus, ETypeVesting, IAdminValues, IValues, IFutureSale, ISale, ESocial } from 'shared/interfaces';
import { getToken } from 'store/actions/helpers';

import { getUTC } from './dateOperation';
import getTimeByDate from './getTimeByDate';
import {
  parseTokenAmount,
  isNullOrEmpty,
  millisecondsToNanoseconds,
  formatTokenAmount,
  hexToUint8Array,
} from './index';

export const setupInitialAdminValues = (sale: ISale, decimals: number): IAdminValues => ({
  claimAvailable: sale.claimAvailable,
  refundAvailable: sale.refundAvailable,
  newPrice: formatTokenAmount(sale.price, decimals),
  newMaxAmount: formatTokenAmount(sale.totalAmount, decimals),
  distributeTokenId: sale.distributeTokenId || '',
  newStartDate: getUTC(sale.startDate).toString(),
  newEndDate: getUTC(sale.endDate).toString(),
  vestingType: ETypeVesting.OneTime,
  vesting: [],
  whitelistRootHash: sale.whitelistRootHash || EMPTY_STRING,
});

export enum EAdminValue {
  claimAvailable = 'claimAvailable',
  refundAvailable = 'refundAvailable',
  newPrice = 'newPrice',
  distributeTokenId = 'distributeTokenId',
  newMaxAmount = 'newMaxAmount',
  newStartDate = 'newStartDate',
  newEndDate = 'newEndDate',
  vestingType = 'vestingType',
  vesting = 'vesting',
  whitelistRootHash = 'whitelistRootHash',
}

export const disableFields = (
  sale: ISale,
  value: IAdminValues,
  isSignedIn: boolean,
  isValidVesting: boolean,
  decimals: number
) => {
  const dateNow = Date.now();

  const isSaleStart = dateNow > sale.startDate;
  const similarDisable = !isSignedIn || isSaleStart;

  const disableSalePrice =
    similarDisable ||
    isNullOrEmpty(value.newPrice) ||
    Big(value.newPrice || ZERO).eq(formatTokenAmount(sale.price, decimals)) ||
    isNullOrEmpty(value.newMaxAmount) ||
    Big(value.newMaxAmount || ZERO).eq(formatTokenAmount(sale.totalAmount, decimals));

  const disableDistributeTokenId =
    !isSignedIn || isNullOrEmpty(value.distributeTokenId) || value.distributeTokenId === sale.distributeTokenId;

  const disableSaleDates =
    similarDisable ||
    isNullOrEmpty(value.newStartDate) ||
    value.newStartDate === createFormattedDate(new Date(sale.startDate)) ||
    isNullOrEmpty(value.newEndDate) ||
    value.newEndDate === createFormattedDate(new Date(sale.endDate));

  const disableRemoveSale = !isSignedIn || Big(sale.collectedAmount).gt(ZERO_STR);
  const disableCancelSale =
    !isSignedIn || sale.status === EStatus.Soon || sale.saleType === ESaleType.Cancelled || sale.claimBegun;

  const disableClaimAndRefund =
    !isSignedIn ||
    (sale.claimAvailable === value.claimAvailable && sale.refundAvailable === value.refundAvailable) ||
    !sale.distributeTokenId ||
    !sale.distributeTokenDecimals;

  const disableChangeVesting = !isSignedIn || !isValidVesting;

  const disableUpdateWhitelistRootHash = similarDisable;

  const buttons = {
    disableSalePrice,
    disableDistributeTokenId,
    disableSaleDates,
    disableRemoveSale,
    disableClaimAndRefund,
    disableChangeVesting,
    disableCancelSale,
    disableUpdateWhitelistRootHash,
  };

  const inputSalePrice = similarDisable;
  const inputSaleDates = similarDisable;
  const inputWhitelistRootHash = similarDisable;
  const inputSaleRefund = sale.saleType === ESaleType.ByAmount || !isSignedIn;
  const inputs = {
    inputSalePrice,
    inputSaleDates,
    inputSaleRefund,
    inputWhitelistRootHash,
  };
  return {
    buttons,
    inputs,
  };
};

export const formatAdminValue = (values: IAdminValues, tokenMetadata: ITokenMetadata) => {
  const { decimals } = tokenMetadata;

  const {
    claimAvailable,
    refundAvailable,
    newPrice,
    newMaxAmount,
    distributeTokenId,
    newStartDate,
    newEndDate,
    vesting,
    whitelistRootHash,
  } = values;

  const newValues = {
    claimAvailable,
    refundAvailable,
    newPrice: parseTokenAmount(newPrice, decimals),
    newMaxAmount: parseTokenAmount(newMaxAmount, decimals),
    distributeTokenId,
    newStartDate: millisecondsToNanoseconds(getTimeByDate(newStartDate)),
    newEndDate: millisecondsToNanoseconds(getTimeByDate(newEndDate)),
    vesting,
    whitelistRootHash: hexToUint8Array(whitelistRootHash),
  };
  return newValues;
};

export const setupInitialFutureAdminValues = (
  sale: IFutureSale,
  decimals: number,
  tokens: { [key: string]: FungibleTokenContract }
): IValues => {
  const distributeToken = getToken(sale.distributeTokenId, tokens);
  const depositToken = getToken(sale.depositTokenId, tokens);
  return {
    price: sale.price ? formatTokenAmount(sale.price, decimals) : EMPTY_STRING,
    maxAmount: sale.totalAmount ? formatTokenAmount(sale.totalAmount, decimals) : EMPTY_STRING,
    roundStartDate: sale.startDate ? getUTC(sale.startDate).toString() : EMPTY_STRING,
    roundFinishDate: sale.endDate ? getUTC(sale.endDate).toString() : EMPTY_STRING,
    vestingType: ETypeVesting.OneTime,
    vesting: [],
    minAllocation: sale.minBuy ? formatTokenAmount(sale.minBuy, decimals) : EMPTY_STRING,
    maxAllocation: sale.maxBuy ? formatTokenAmount(sale.maxBuy, decimals) : EMPTY_STRING,
    limitPerTransaction: sale.limitPerTransaction
      ? formatTokenAmount(sale.limitPerTransaction, decimals)
      : EMPTY_STRING,
    roundType: sale.saleType || ESaleType.Empty,

    projectName: sale.metadata.name || EMPTY_STRING,
    projectSite: sale.metadata.projectUrl || EMPTY_STRING,
    description: sale.metadata.description || EMPTY_STRING,
    distributeTokenId: sale.distributeTokenId || EMPTY_STRING,
    distributeToken,
    depositTokenId: sale.depositTokenId || EMPTY_STRING,
    depositToken,

    socialTg: sale.metadata.socials.find((el) => el.type === ESocial.Telegram)?.value || EMPTY_STRING,
    socialTwitter: sale.metadata.socials.find((el) => el.type === ESocial.Twitter)?.value || EMPTY_STRING,
    socialMedium: sale.metadata.socials.find((el) => el.type === ESocial.Medium)?.value || EMPTY_STRING,
    whitelistRootHash: sale.whitelistRootHash || EMPTY_STRING,
  };
};
