import { Button } from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { APP_ROUTES } from 'routes/constant';
import Loading from 'shared/components/Loading';
import { Translate } from 'shared/components/Translate';
import { EMPTY_STRING } from 'shared/constants';
import { useAppSelector } from 'shared/hooks/redux';
import { IFutureSale, ISale } from 'shared/interfaces';
import { selectData } from 'store/selectors/selectData';

import Cards from './Cards';
import styles from './styles';
import { filterSalesBySearch } from './utils';

export default function HomePage() {
  const { sales, loading } = useAppSelector(selectData);
  const salesArray = sales.arr.reverse();
  const futureSalesArray = sales.future.arr.reverse();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>(EMPTY_STRING);
  const [filteredSales, setFilteredSales] = useState<Array<ISale>>(salesArray);
  const [filteredFutureSales, setFilteredFutureSales] = useState<Array<IFutureSale>>(futureSalesArray);

  const onChange = useCallback(
    (search: string) => {
      setSearchValue(search);
      const searchLowerCase = search.toLowerCase();
      const isSearchEmpty = search === EMPTY_STRING;

      const salesBySearch = !isSearchEmpty ? filterSalesBySearch(salesArray, searchLowerCase) : salesArray;
      const futureSalesBySearch = !isSearchEmpty
        ? filterSalesBySearch(futureSalesArray, searchLowerCase)
        : futureSalesArray;

      setFilteredFutureSales(futureSalesBySearch);
      setFilteredSales(salesBySearch);
    },
    [salesArray, futureSalesArray]
  );

  useEffect(() => {
    const hasSalesArrayChanged = salesArray.length !== filteredSales.length;
    const hasFutureSalesArrayChanged = futureSalesArray.length !== filteredFutureSales.length;
    const isSearchValueEmpty = searchValue === EMPTY_STRING;
    if (hasSalesArrayChanged && isSearchValueEmpty) setFilteredSales(salesArray);
    if (hasFutureSalesArrayChanged && isSearchValueEmpty) setFilteredFutureSales(futureSalesArray);
  }, [searchValue, filteredSales.length, salesArray, futureSalesArray, filteredFutureSales.length]);

  if (loading.sale) return <Loading />;
  return (
    <styles.Container>
      <styles.Header>
        <styles.StyledInput>
          <styles.Search />
          <styles.Input
            value={searchValue}
            onChange={(value) => onChange(value.target.value)}
            placeholder={t('Search')}
          />
          {searchValue && <styles.ClearSearch onClick={() => onChange(EMPTY_STRING)} />}
        </styles.StyledInput>
        <Button variant="contained" color="warning" onClick={() => navigate(APP_ROUTES.MARS.CREATE_SALE)}>
          <Translate value="Action.CreateSale" />
        </Button>
      </styles.Header>
      <styles.WrapperCards>
        <Cards future={filteredFutureSales} sales={filteredSales} />
      </styles.WrapperCards>
    </styles.Container>
  );
}
