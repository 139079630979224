export enum EImage {
  JPEG = 'JPEG',
  JPG = 'JPG',
  PNG = 'PNG',
  SVG = 'SVG',
  GIF = 'GIF',
}

export const getUrlExtension = (url: string): string | undefined => url.split(/[#?]/)[0].split('.').pop();
export const regexImageType = /jpeg|jpg|gif|png|svg/;

export const getImageFileExtensions = (media?: string, blobType?: string, mimeType?: string) => {
  const type = media ? getUrlExtension(media) : '';
  const match = (mimeType || blobType || type || '').match(regexImageType);
  if (match) return match[0].toUpperCase();
  return EImage.JPG;
};
