import styled from 'styled-components';

import { ReactComponent as RocketIcon } from 'assets/images/icon/rocket-icon.svg';

const StyledLoading = styled(RocketIcon)`
  margin-top: 3rem;
  display: flex;
  align-self: center;
  max-width: 300px;
  max-height: 300px;
`;

export default function Loading() {
  return <StyledLoading />;
}
