import { Button } from '@mui/material';
import Big from 'big.js';
import { useState } from 'react';

import { IStakeInput, useStakingProvider } from 'providers/LocalProviders/StakingProvider';
import { useWalletData } from 'providers/WalletProvider';
import { FungibleTokenContract } from 'services/contract';
import { EStakingStatus } from 'services/interfaces';
import { Translate } from 'shared/components/Translate';
import { ZERO_STR } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { IFormattedStaking } from 'shared/interfaces';
import { parseTokenAmount, formatTokenAmount } from 'shared/utils';
import { transferStakingToken } from 'store/actions/staking/transferStakingToken';
import { selectIsSignedIn, selectUserStakingTokenBalance } from 'store/slices/user';

import styles from './styles';
import { FarmStatusLocales, calcFarmData, parseDateInSeconds } from './utils';

export default function StakingCard({ staking, token }: { staking: IFormattedStaking; token: FungibleTokenContract }) {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const { sendTransaction } = useWalletData();
  const dispatch = useAppDispatch();
  const stakingTokenBalance = useAppSelector(selectUserStakingTokenBalance);
  const { stakingInputs, setStakingInputs } = useStakingProvider();
  const [showInput, setShowInput] = useState<boolean>(false);
  if (!token || !token.metadata) return null;
  const { decimals, symbol } = token.metadata;
  const { id, stakeId, status, startAt, endAt } = staking;
  const farmsInputData: IStakeInput | undefined = stakingInputs[id];
  const farmData = calcFarmData(staking, farmsInputData, decimals, symbol);
  const sendToken = async () => {
    const parsedAmount = parseTokenAmount(farmsInputData.depositAmount, decimals);
    await dispatch(transferStakingToken({ sendTransaction, amount: parsedAmount, stakingId: staking.stakeId, token }));
  };
  const readableBalance = formatTokenAmount(stakingTokenBalance, decimals);
  const insufficientBalance = Big(farmsInputData?.depositAmount || ZERO_STR).gt(readableBalance);
  const disableButton =
    !isSignedIn || Big(farmsInputData?.depositAmount || ZERO_STR).eq(ZERO_STR) || insufficientBalance;

  const onChangeDepositAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStakingInputs({
      ...stakingInputs,
      [id]: {
        ...farmsInputData,
        depositAmount: e.target.value,
      },
    });
  };
  return (
    <styles.Wrapper>
      <styles.Container>
        <styles.UpperRow>
          <p>{stakeId}</p>
          <styles.Status type={status}>{FarmStatusLocales[status]}</styles.Status>
        </styles.UpperRow>
        <styles.Table>
          {farmData.map(({ title, value, color }) => (
            <div key={title}>
              <styles.ElementTitle>{title}</styles.ElementTitle>
              <styles.ElementValue color={color}>{value}</styles.ElementValue>
            </div>
          ))}
        </styles.Table>
        <styles.Button onClick={() => setShowInput(!showInput)} disabled={staking.status === EStakingStatus.Ended}>
          {showInput ? <styles.Minus /> : <styles.Plus />}
        </styles.Button>
        {showInput && farmsInputData && (
          <>
            <div>
              <styles.StyledInput
                value={farmsInputData.depositAmount}
                onChange={onChangeDepositAmount}
                placeholder="0.0"
                autoComplete="off"
                type="number"
                inputMode="decimal"
                onWheel={(event) => event.currentTarget.blur()}
                error={insufficientBalance}
              />
              {insufficientBalance && (
                <styles.Error>
                  <Translate value="Error.InsufficientBalance" />
                </styles.Error>
              )}
            </div>
            <Button variant="contained" color="warning" onClick={sendToken} disabled={disableButton}>
              <Translate value="Action.DepositReward" />
            </Button>
          </>
        )}
      </styles.Container>
      <styles.Time isDynamicEndDate={false}>
        {parseDateInSeconds(startAt)}
        {'\xa0 \u2014 \xa0'}
        <span>{parseDateInSeconds(endAt)}</span>
      </styles.Time>
    </styles.Wrapper>
  );
}
