import { ESaleType } from 'services/interfaces';

import { EStatus } from './index';

interface SaleMetadata {
  name: string;
  depositSymbol: string;
  description: string;
  projectUrl: string;
  rewardTokenLogo: string;
  rewardTicker: string;
  rewardDescription: string | null;
  socials: { value: string | null; type: ESocial }[];
}

export interface Cliff {
  active: boolean;
  timestamp: number;
}

export interface ISale {
  id: number;
  metadata: SaleMetadata;
  depositTokenId: string;
  claimAvailable: boolean;
  refundAvailable: boolean;
  distributeTokenId: string | null;
  distributeTokenDecimals: number | null;
  minBuy: string;
  maxBuy: string;
  totalAmount: string;
  startDate: number;
  endDate: number;
  rewardDate?: number;
  price: string;
  limitPerTransaction: string;
  collectedAmount: string;
  numAccountSales: number;
  saleType: ESaleType;
  claimBegun: boolean;
  vesting: Array<IVesting> | null;
  vestingType: ETypeVesting | null;
  cliff: Cliff | null;
  status: EStatus;
  whitelistRootHash: string | null;
}

export enum ETypeVesting {
  OneTime = 'OneTime',
  Stepwise = 'Stepwise',
}

export interface IVesting {
  dateObj: IParseDate;
  steps: number;
  quota: number;
  date: number;
  previousVestingDate?: number;
}

export interface IParseDate {
  year: string;
  month: string;
  monthString: string;
  day: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export enum EVestingStatus {
  SOON = 'SOON',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  AVAILABLE_TO_CLAIM = 'AVAILABLE_TO_CLAIM',
}

export interface IVestingArray extends IVesting {
  amount: string;
  status: EVestingStatus;
}

export interface IVestingDetails {
  totalLocked: string;
  availableTokens: string;
  array: IVestingArray[];
  tokensArray: {
    title: string;
    value: string;
  }[];
}

export enum ESocial {
  Medium,
  Telegram,
  Twitter,
}

export interface ISocials {
  value: string | null;
  type: ESocial;
}

export interface IFutureSale {
  id: number;
  metadata: SaleMetadata;
  status: EStatus;
  depositTokenId: string;
  distributeTokenId: string;

  stakingContracts: Array<string> | null;
  distributeTokenDecimals: number | null;
  minBuy: string | null;
  maxBuy: string | null;
  totalAmount: string | null;
  startDate: number | null;
  endDate: number | null;
  rewardDate?: number;
  price: string | null;
  limitPerTransaction: string | null;
  numAccountSales: number;
  saleType: ESaleType | null;
  claimBegun: boolean;
  vesting: Array<IVesting> | null;
  vestingType: ETypeVesting | null;
  cliff: Cliff | null;

  whitelistRootHash: string | null;
}
