import { useNavigate } from 'react-router-dom';

import { toAuction } from 'routes/constant';
import NFTMedia from 'shared/components/NFTMedia';
import Status from 'shared/components/Status';
import { translate, Translate } from 'shared/components/Translate';
import { useAppSelector } from 'shared/hooks/redux';
import useAuctionStatus from 'shared/hooks/useAuctionStatus';
import useAuctionUpdater from 'shared/hooks/useAuctionUpdater';
import useImageDataUpload from 'shared/hooks/useImageDataUpload';
import { IAuction } from 'shared/interfaces';
import { getBidDetailsArray, getCurrentBid } from 'shared/utils';
import { selectData } from 'store/selectors/selectData';

import Footer from './Footer';
import styles from './styles';

const isShowingMinMarkup = false;

export default function AuctionCard({ auction }: { auction: IAuction }): JSX.Element | null {
  const { nfts, tokens } = useAppSelector(selectData);
  const navigate = useNavigate();
  const status = useAuctionStatus(auction.startDate, auction.endDate, auction.status);
  const { winnerBid, yourBid } = useAuctionUpdater(auction, status);
  const contractNft = nfts[auction.nftContractId] || null;
  const nft = contractNft?.tokenMetadata?.[auction.nftTokenId] || null;
  const token = tokens[auction.depositTokenId] || null;

  const { media, isLoading, typeImage } = useImageDataUpload(nft?.metadata.media, nft?.metadata.mime_type);

  if (!nft || !token || !contractNft || !token.metadata) return null;
  const currentBid = getCurrentBid(auction.initialPrice, winnerBid);

  const bidArray = getBidDetailsArray({
    currentBid,
    yourBid,
    decimals: token.metadata.decimals,
    symbol: token.metadata.symbol,
    isShowingMinMarkup,
  });

  return (
    <styles.Container onClick={() => navigate(toAuction(auction.id))}>
      <styles.ImageContainer>
        <NFTMedia media={media} isLoading={isLoading} />
      </styles.ImageContainer>
      <styles.TitleBlock>
        <styles.Title>{nft.metadata.title}</styles.Title>
        <Status type={status} />
      </styles.TitleBlock>

      <styles.LabelBlock>
        <styles.LogoToken src={token.metadata.icon} alt={translate({ value: 'DepositTokenLogo' })} />
        <styles.Label>
          <Translate value="AuctionLabel" interpolation={{ symbol: token.metadata.symbol, typeImage }} />
        </styles.Label>
      </styles.LabelBlock>

      <styles.BidBlock>
        {bidArray.map(({ title, value }) => (
          <div key={title}>
            <styles.BidTitle>
              <Translate value={title} />
            </styles.BidTitle>
            <styles.BidLabel>{value}</styles.BidLabel>
          </div>
        ))}
      </styles.BidBlock>
      <Footer status={status} startDate={auction.startDate} endDate={auction.endDate} />
    </styles.Container>
  );
}
