import { useEffect, useState } from 'react';

import { calcStrokeDasharray } from 'shared/calculation';
import { ONE_SECOND, radiusProgressCircle } from 'shared/constants';
import colors from 'shared/theme/colors';

interface IProgressCircle {
  startPeriod: number;
  endPeriod: number;
}

function checkFullProgress(fullProgress: number, currentProgress: number) {
  if (currentProgress > fullProgress) return fullProgress;
  if (currentProgress < 0) return 0;
  return currentProgress;
}

export default function ProgressCircle({ startPeriod, endPeriod }: IProgressCircle) {
  const strokeDasharray = calcStrokeDasharray(radiusProgressCircle);
  const periodDifference = endPeriod - startPeriod;
  const diffFromCurrentTime = endPeriod - Date.now();
  const calcStrokeDashoffset = (strokeDasharray * diffFromCurrentTime) / periodDifference;
  const [strokeDashoffset, setStrokeDashoffset] = useState<number>(
    checkFullProgress(strokeDasharray, calcStrokeDashoffset)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedDiffFromCurrentTime = endPeriod - Date.now();
      const updatedStrokeDashoffset = (strokeDasharray * updatedDiffFromCurrentTime) / periodDifference;
      setStrokeDashoffset(checkFullProgress(strokeDasharray, updatedStrokeDashoffset));
    }, ONE_SECOND);
    return () => clearInterval(intervalId);
  });

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8.5" r={radiusProgressCircle} stroke="#FFB29A" strokeWidth="1.33" />
      <circle
        cx="8"
        cy="8.5"
        r={radiusProgressCircle}
        strokeWidth="1.33"
        stroke={colors.orange}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        style={{
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
        }}
      />
    </svg>
  );
}
