import { ITranslationKeys } from 'services/translation';
import { EStatus, ETypeVesting } from 'shared/interfaces';

export const StatusLocales: { [key: string]: ITranslationKeys } = {
  [EStatus.Open]: 'Sale.Status.Open',
  [EStatus.Soon]: 'Sale.Status.Soon',
  [EStatus.Closed]: 'Sale.Status.Closed',
  [EStatus.Cancelled]: 'Sale.Status.Cancelled',
};

export const StatusTimeLocales: { [key: string]: ITranslationKeys } = {
  [EStatus.Open]: 'Sale.Status.LeftToFinish',
  [EStatus.Soon]: 'Sale.Status.LeftToStart',
};

export const StatusVestingTimeLocales: { [key: string]: ITranslationKeys } = {
  [ETypeVesting.OneTime]: 'Sale.Status.OneTime',
  [ETypeVesting.Stepwise]: 'Sale.Status.Stepwise',
};
