import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction } from 'services/interfaces';
import { ISale } from 'shared/interfaces';
import { RootState } from 'store';

export const updateSaleClaimAndRefund = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    saleId: number;
    claimAvailable: boolean;
    refundAvailable: boolean;
    sale: ISale;
  },
  { state: RootState }
>(
  'sales/updateSaleClaimAndRefund',
  async ({ sendTransaction, saleId, claimAvailable, refundAvailable, sale }, { getState }) => {
    try {
      const {
        services: { saleContract },
      } = getState();
      if (!saleContract) return;
      const actions: IAction[] = [];
      if (sale.claimAvailable !== claimAvailable) {
        actions.push(saleContract.updateSaleClaimAvailable(saleId, claimAvailable));
      }
      if (sale.refundAvailable !== refundAvailable) {
        actions.push(saleContract.updateSaleRefundAvailable(saleId, refundAvailable));
      }
      if (!actions.length) return;
      await sendTransaction(actions);
    } catch (error) {
      console.error('Error during updateSaleClaimAndRefund: ', error);
    }
  }
);
