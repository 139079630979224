import Big from 'big.js';

import { DASH, ONE_HUNDRED, ZERO, ZERO_STR } from 'shared/constants';
import { formatTokenAmount } from 'shared/utils';

export const calcDistributeAmount = (totalAmount: string | undefined, percent: string) => {
  if (!totalAmount || !percent) return DASH;
  const amount = Big(totalAmount).mul(percent).div(ONE_HUNDRED).toFixed();
  return amount;
};

export const calcProgress = (collectedAmount: string, totalAmount: string) => {
  if (Big(collectedAmount).eq(ZERO_STR)) return ZERO;
  const progress = Big(collectedAmount).mul(100).div(totalAmount).toNumber();
  return Math.floor(progress);
};

export const calcStrokeDasharray = (radius: number) => 2 * Math.PI * radius;

export const calcTotalClaimAvailable = (
  totalAmount: string,
  collectedAmount: string,
  price: string,
  depositTokenDecimals: number
) => {
  if (totalAmount === ZERO_STR || price === ZERO_STR) {
    return ZERO_STR;
  }
  const currentAmount = Big(collectedAmount).gt(totalAmount) ? totalAmount : collectedAmount;
  const readableTotalAmount = formatTokenAmount(currentAmount, depositTokenDecimals);
  const readablePrice = formatTokenAmount(price, depositTokenDecimals);

  return Big(readableTotalAmount).div(readablePrice).toFixed(0);
};

export const calcTotalVestingValue = (collectedAmount: string, price: string) => {
  if (collectedAmount === ZERO_STR || price === ZERO_STR) {
    return ZERO_STR;
  }
  return Big(collectedAmount).div(price).toFixed(ZERO);
};
