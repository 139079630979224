import { useEffect, useState } from 'react';

import { ONE_SECOND } from 'shared/constants';
import { EStatus } from 'shared/interfaces';

export const getStatus = (startDate: number, endDate: number): EStatus => {
  const dateNow = Date.now();

  if (dateNow > endDate) return EStatus.Closed;
  if (startDate > dateNow) return EStatus.Soon;
  if (dateNow > startDate && dateNow < endDate) return EStatus.Open;
  return EStatus.Closed;
};

const useAuctionStatus = (startDate: number, endDate: number, auctionStatus: EStatus | undefined) => {
  const calcStatus = getStatus(startDate, endDate);
  const [status, setStatus] = useState<EStatus>(auctionStatus || calcStatus);

  useEffect(() => {
    const updateStatus = () => {
      if (auctionStatus === EStatus.Closed) return;
      const newCalcStatus = getStatus(startDate, endDate);
      setStatus(newCalcStatus);
    };
    updateStatus();
    const interval = setInterval(updateStatus, ONE_SECOND);
    return () => clearInterval(interval);
  }, [auctionStatus, endDate, startDate]);

  return status;
};

export default useAuctionStatus;
