import { ISocialNetwork } from 'shared/interfaces';

import { TextInput } from '../Input/FormikInput';
import { translate, Translate } from '../Translate';
import styles from './styles';

export default function Network({ social }: { social: ISocialNetwork[] }) {
  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateSale.Network" />
      </styles.Title>
      <styles.ChildWrapper>
        {social.map(({ label, type, name, placeholder }) => (
          <TextInput
            key={name}
            label={translate({ value: label })}
            name={name}
            type={type}
            placeholder={translate({ value: placeholder })}
          />
        ))}
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
