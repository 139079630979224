import { useNavigate } from 'react-router-dom';

import { APP_ROUTES } from 'routes/constant';
import { calcTotalClaimAvailable } from 'shared/calculation';
import AmountProgress from 'shared/components/AmountProgress';
import SaleDate from 'shared/components/SaleDate';
import SaleLayoutInfo from 'shared/components/SaleLayoutInfo';
import SocialNetwork from 'shared/components/SocialNetwork';
import ValueSale from 'shared/components/ValueSale';
import VestingSchedule from 'shared/components/VestingSchedule';
import VestingSection from 'shared/components/VestingSection';
import { EMPTY_STRING, ZERO, ZERO_STR } from 'shared/constants';
import { useAppSelector } from 'shared/hooks/redux';
import useSaleStatus from 'shared/hooks/useSaleStatus';
import { ISale, EStatus } from 'shared/interfaces';
import colors from 'shared/theme/colors';
import { getParticipantData } from 'shared/utils';
import { selectLoading } from 'store/selectors/selectLoading';
import { selectToken } from 'store/selectors/selectToken';

import styles from './styles';

type MainCardProps = ISale & { isCentered?: boolean };

export default function MainCard({ isCentered = true, ...sale }: MainCardProps) {
  const loading = useAppSelector(selectLoading);
  const navigate = useNavigate();
  const { status } = useSaleStatus(
    sale?.startDate || ZERO,
    sale?.endDate || ZERO,
    sale?.totalAmount || ZERO_STR,
    sale?.collectedAmount || ZERO_STR,
    sale?.saleType,
    sale?.status
  );

  const token = useAppSelector((state) => selectToken(state, sale.depositTokenId));

  if (loading.sale || !sale) return null;

  const participantData = getParticipantData(
    {
      depositSymbol: token?.metadata?.symbol || EMPTY_STRING,
      rewardTicker: sale.metadata.rewardTicker,
      minBuy: sale.minBuy,
      maxBuy: sale.maxBuy,
      claimBegun: sale.claimBegun,
      price: sale.price,
      totalAmount: sale.totalAmount,
      numAccountSales: sale.numAccountSales,
    },
    token?.metadata?.decimals || 0
  );
  const showVesting = sale.claimAvailable && status === EStatus.Closed;
  const showVestingSchedule = status === EStatus.Soon || status === EStatus.Open || sale.cliff?.active;
  return (
    <styles.Container isCentered={isCentered}>
      <styles.Wrapper>
        <styles.CloseBtn onClick={() => navigate(APP_ROUTES.MARS.HOME)} />
        <SaleLayoutInfo
          currency={token?.metadata?.symbol || EMPTY_STRING}
          name={sale.metadata.name}
          tokenIcon={token?.metadata?.icon || EMPTY_STRING}
          saleType={sale.saleType}
          status={status}
        />
        {sale.vesting && showVesting && (
          <VestingSection
            rewardTicker={sale.metadata.rewardTicker}
            vesting={sale.vesting}
            vestingType={sale.vestingType}
            cliff={sale.cliff}
            totalClaimAvailable={calcTotalClaimAvailable(
              sale.totalAmount,
              sale.collectedAmount,
              sale.price,
              token?.metadata?.decimals || 0
            )}
          />
        )}
        <styles.InfoWrapper>
          <ValueSale participantData={participantData} />
          <AmountProgress
            collectedAmount={sale.collectedAmount}
            currency={token?.metadata?.symbol || EMPTY_STRING}
            depositTokenDecimals={token?.metadata?.decimals || 0}
            totalAmount={sale.totalAmount}
          />
          <SaleDate endDate={sale.endDate} startDate={sale.startDate} />
        </styles.InfoWrapper>
        {sale.vesting && showVestingSchedule && (
          <VestingSchedule vesting={sale.vesting} vestingType={sale.vestingType} />
        )}
        <styles.Description>{sale.metadata.description}</styles.Description>
        <styles.SocialWrapper>
          <styles.SocialBox href={sale.metadata.projectUrl}>
            <p>{sale.metadata.name}</p>
            <styles.ExternalLink />
          </styles.SocialBox>
          <SocialNetwork socials={sale.metadata.socials} color={colors.black} isCardPage />
        </styles.SocialWrapper>
      </styles.Wrapper>
    </styles.Container>
  );
}
