import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuction } from 'shared/interfaces';
import { RootState } from 'store';

type AuctionsState = {
  obj: Record<number, IAuction>;
  loading: boolean;
};

const initialState: AuctionsState = {
  obj: {},
  loading: true,
};

export const auctionsSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {
    setAuctions(state, action: PayloadAction<Record<string, IAuction>>) {
      return {
        ...state,
        obj: action.payload,
      };
    },
    setAuctionsLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

// SELECTORS
export const selectAuctions = (state: RootState) => state.auctions.obj;
export const selectAuctionsLoading = (state: RootState) => state.auctions.loading;

// ACTIONS
export const { setAuctionsLoading, setAuctions } = auctionsSlice.actions;

export const auctionsReducer = auctionsSlice.reducer;
