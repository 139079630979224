import styled from 'styled-components';

import colors from 'shared/theme/colors';

import { EColorType } from '../../utils';

const getColorByType = (colorType: EColorType) => {
  switch (colorType) {
    case EColorType.ACTIVE:
      return colors.black;
    default:
      return colors.grey;
  }
};

const ProductsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const ProductsButton = styled.div<{ colorType: EColorType }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.563rem;
  background: ${({ theme, colorType }) => (colorType === EColorType.ACTIVE ? theme.white : theme.opacityGray)};
  color: ${({ colorType }) => getColorByType(colorType)};
  border-radius: 1rem;
  text-decoration: none;
  transition: all 0.3s;
  & > svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    path {
      fill: ${({ colorType }) => getColorByType(colorType)};
    }
  }
  :last-child {
    margin-right: 0;
  }
  :hover {
    color: ${({ theme }) => theme.black};
    background: ${({ theme }) => theme.white};
    svg {
      path {
        fill: ${({ theme }) => theme.black};
      }
    }
  }
`;

const ProductsName = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
`;

export default {
  ProductsWrapper,
  ProductsButton,
  ProductsName,
};
