import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { TextField, TextFieldProps, Button, FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';

import { useWalletData } from 'providers/WalletProvider';
import { createFormattedDate } from 'services/helpers';
import { AuctionMetadata } from 'services/interfaces';
import TextInput from 'shared/components/Input/TextInput';
import { translate, Translate } from 'shared/components/Translate';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { IAuction } from 'shared/interfaces';
import { millisecondsToNanoseconds, getTimeByDate } from 'shared/utils';
import {
  removeAuction,
  updateAuctionDates,
  updateAuctionMetadata,
  updateAuctionClaimAndRefund,
} from 'store/actions/auction';
import { selectIsSignedIn } from 'store/slices/user';

import { EAuctionMethod, IAdminValues } from './interfaces';
import styles from './styles';
import { disabledInputsAndButtons, EAdminValue, setupInitialAuctionValues } from './utils';

export default function AdminPanel({ auction }: { auction: IAuction }) {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const { sendTransaction } = useWalletData();
  const dispatch = useAppDispatch();

  const [values, setValues] = useState<IAdminValues>(setupInitialAuctionValues(auction));

  const { buttons, inputs } = disabledInputsAndButtons(auction, values, isSignedIn);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleClick = (method: EAuctionMethod) => {
    switch (method) {
      case EAuctionMethod.REMOVE_SALE:
        dispatch(removeAuction({ sendTransaction, auctionId: auction.id }));
        break;
      case EAuctionMethod.UPDATE_SALE_DATES: {
        const startDate = millisecondsToNanoseconds(getTimeByDate(values.startDate));
        const endDate = millisecondsToNanoseconds(getTimeByDate(values.endDate));
        dispatch(updateAuctionDates({ sendTransaction, auctionId: auction.id, startDate, endDate }));
        break;
      }
      case EAuctionMethod.UPDATE_METADATA: {
        const metadata: AuctionMetadata = {
          project_link: values.projectLink || null,
          twitter_link: values.twitterLink || null,
          medium_link: values.mediumLink || null,
          telegram_link: values.telegramLink || null,
        };
        dispatch(updateAuctionMetadata({ sendTransaction, auctionId: auction.id, metadata }));
        break;
      }
      case EAuctionMethod.UPDATE_SALE_CLAIM_AND_REFUND:
        dispatch(
          updateAuctionClaimAndRefund({
            sendTransaction,
            auctionId: auction.id,
            claimAvailable: values.claimAvailable,
            refundAvailable: values.refundAvailable,
            auction,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <styles.Container>
      <p>
        <Translate value="AdminPanel.Title" />
      </p>
      <styles.Row>
        <TextInput
          value={values.projectLink}
          label="AdminPanel.ProjectLink.Label"
          name={EAdminValue.projectLink}
          type="text"
          placeholder="AdminPanel.ProjectLink.Placeholder"
          handleChange={handleChange}
          disabled={inputs.inputAuctionMetadata}
        />
      </styles.Row>
      <styles.Row>
        <TextInput
          value={values.mediumLink}
          label="AdminPanel.MediumLink.Label"
          name={EAdminValue.mediumLink}
          type="text"
          placeholder="AdminPanel.MediumLink.Placeholder"
          handleChange={handleChange}
          disabled={inputs.inputAuctionMetadata}
        />
        <TextInput
          value={values.telegramLink}
          label="AdminPanel.TelegramLink.Label"
          name={EAdminValue.telegramLink}
          type="text"
          placeholder="AdminPanel.TelegramLink.Placeholder"
          handleChange={handleChange}
          disabled={inputs.inputAuctionMetadata}
        />
        <TextInput
          value={values.twitterLink}
          label="AdminPanel.TwitterLink.Label"
          name={EAdminValue.twitterLink}
          type="text"
          placeholder="AdminPanel.TwitterLink.Placeholder"
          handleChange={handleChange}
          disabled={inputs.inputAuctionMetadata}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClick(EAuctionMethod.UPDATE_METADATA)}
          disabled={buttons.disableMetadata}
        >
          <Translate value="Action.UpdateAuctionMetadata" />
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
            label={translate({ value: 'AdminPanel.StartDate' })}
            value={values.startDate}
            onChange={(date: string | null) => {
              if (!date) return;
              setValues({
                ...values,
                [EAdminValue.startDate]: createFormattedDate(date),
              });
            }}
            ampm={false}
            disabled={inputs.inputAuctionDates}
          />
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
            label={translate({ value: 'AdminPanel.EndDate' })}
            value={values.endDate}
            onChange={(date: string | null) => {
              if (!date) return;
              setValues({
                ...values,
                [EAdminValue.endDate]: createFormattedDate(date),
              });
            }}
            ampm={false}
            disabled={inputs.inputAuctionDates}
          />
        </LocalizationProvider>
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="secondary"
          disabled={buttons.disableAuctionDates}
          onClick={() => handleClick(EAuctionMethod.UPDATE_SALE_DATES)}
        >
          <Translate value="Action.UpdateAuctionDates" />
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <FormControlLabel
          value={values.claimAvailable}
          checked={values.claimAvailable}
          onChange={(e, checked) => setValues({ ...values, [EAdminValue.claimAvailable]: checked })}
          control={<Switch color="secondary" />}
          label={translate({ value: 'AdminPanel.Claim' })}
          labelPlacement="start"
          disabled={!isSignedIn}
        />
        <FormControlLabel
          value={values.refundAvailable}
          checked={values.refundAvailable}
          onChange={(e, checked) => setValues({ ...values, [EAdminValue.refundAvailable]: checked })}
          control={<Switch color="secondary" />}
          label={translate({ value: 'AdminPanel.Refund' })}
          labelPlacement="start"
          disabled={!isSignedIn}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="secondary"
          disabled={buttons.disableClaimAndRefund}
          onClick={() => handleClick(EAuctionMethod.UPDATE_SALE_CLAIM_AND_REFUND)}
        >
          <Translate value="Action.UpdateClaimAndRefund" />
        </Button>
      </styles.WrapperButton>
      <Button
        variant="contained"
        color="error"
        disabled={buttons.disableRemoveAuction}
        onClick={() => handleClick(EAuctionMethod.REMOVE_SALE)}
      >
        <Translate value="Action.RemoveAuction" />
      </Button>
    </styles.Container>
  );
}
