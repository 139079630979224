import { useTranslation } from 'react-i18next';

import i18n, { ITranslationKeys } from 'services/translation';

interface TranslateProps {
  value: ITranslationKeys;
  interpolation?: object;
}

export function Translate({ value, interpolation }: TranslateProps) {
  const { t } = useTranslation();
  return <>{t(value, interpolation)}</>;
}

export const translate = ({ value, interpolation }: TranslateProps) => {
  return i18n.t(value, interpolation);
};
