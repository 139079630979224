import { StakingProvider } from 'providers/LocalProviders/StakingProvider';
import Token from 'shared/components/Token';
import { useAppSelector } from 'shared/hooks/redux';
import { sortingStaking } from 'shared/utils';
import { selectLoading } from 'store/selectors/selectLoading';
import { selectToken } from 'store/selectors/selectToken';
import { selectStaking } from 'store/slices/staking';

import CreateStaking from './components/CreateStaking';
import StakingCard from './components/StakingCard';
import StickyContainer from './components/StickyContainer';
import styles from './styles';

export default function Staking() {
  const staking = useAppSelector(selectStaking);
  const loading = useAppSelector(selectLoading);
  const rewardToken = useAppSelector((state) => selectToken(state, staking?.rewardTokenId));
  const metadata = rewardToken?.metadata;
  if (!rewardToken || !metadata || loading.staking) return null;
  const sortedStakingFarms = sortingStaking(staking.farms.arr);
  return (
    <StakingProvider token={rewardToken}>
      <styles.Container>
        <Token token={rewardToken} />
        <styles.Wrapper>
          <styles.StaticContainer>
            <styles.StakingList>
              {sortedStakingFarms.map((stakingElement) => (
                <StakingCard key={stakingElement.stakeId} staking={stakingElement} token={rewardToken} />
              ))}
            </styles.StakingList>
            <CreateStaking />
          </styles.StaticContainer>
          <StickyContainer />
        </styles.Wrapper>
      </styles.Container>
    </StakingProvider>
  );
}
