import { createAsyncThunk } from '@reduxjs/toolkit';

import { auctionContractId } from 'services/config';
import { NonFungibleTokenContract } from 'services/contract';
import { EStatus } from 'shared/interfaces';
import { toArray, toMap } from 'shared/utils';
import { setNfts, setNftsLoading } from 'store/slices/nfts';
import { setUserNFTs } from 'store/slices/user';

import { RootState } from '../index';
import {
  createNFTContracts,
  retrieveFilteredNFTMetadata,
  retrieveNFTTokenAddresses,
  retrieveUserNFTs,
} from './helpers';

export const loadNfts = createAsyncThunk<void, void, { state: RootState }>(
  'nfts/loadNfts',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const {
        user: { isSignedIn },
        services: { provider },
      } = state;
      const auctionArray = toArray(state.auctions.obj);
      const nftTokenAddresses = retrieveNFTTokenAddresses(auctionArray);
      const nftTokensAndIds = auctionArray.map((nftSale) => ({
        nftAddress: nftSale.nftContractId,
        nftId: nftSale.nftTokenId,
      }));
      const contracts = createNFTContracts(provider, nftTokenAddresses);

      const nftsMetadataFiltered = await retrieveFilteredNFTMetadata(provider, nftTokensAndIds, contracts);

      if (!nftsMetadataFiltered) return;
      const nftsMap = toMap(nftsMetadataFiltered, 'contractId');

      let retrievedUserNFTs = {};
      if (isSignedIn) {
        const nftsAtTheStartOfTheAuction = auctionArray
          .filter((nftSale) => nftSale.status !== EStatus.Closed)
          .reduce((acc: { [key: string]: NonFungibleTokenContract }, auction) => {
            return { ...acc, [auction.nftContractId]: nftsMap[auction.nftContractId] };
          }, {});
        retrievedUserNFTs = await retrieveUserNFTs(auctionContractId, provider, nftsAtTheStartOfTheAuction);
      }
      dispatch(setNfts(nftsMap));
      dispatch(setUserNFTs(retrievedUserNFTs));
    } catch (e) {
      console.error(`Error: while loading token metadata \n ${e}`);
    } finally {
      dispatch(setNftsLoading(false));
    }
  }
);
