import { useNavigate } from 'react-router-dom';

import { toFutureSale } from 'routes/constant';
import stylesTimer from 'shared/components/Timer/styles';
import { useAppSelector } from 'shared/hooks/redux';
import { EStatus, IFutureSale } from 'shared/interfaces';
import { selectToken } from 'store/selectors/selectToken';

import SaleLayoutInfo from '../SaleLayoutInfo';
import { Translate } from '../Translate';
import styles from './styles';

export default function FutureCard({ sale }: { sale: IFutureSale }) {
  const navigate = useNavigate();
  const token = useAppSelector((state) => selectToken(state, sale.depositTokenId));

  if (!token?.metadata) return null;

  return (
    <styles.Container onClick={() => navigate(toFutureSale(sale.id))} isOpen={sale.status === EStatus.Open}>
      <SaleLayoutInfo
        name={sale.metadata.name}
        tokenIcon={token.metadata.icon}
        currency={token.metadata.symbol}
        status={sale.status}
      />
      <styles.Footer>
        <styles.SaleInfoWrapper>
          <styles.SaleInfoBlock>
            <p>
              <Translate
                value="Sale.SalePrice"
                interpolation={{
                  symbol: sale.metadata.rewardTicker,
                }}
              />
            </p>
            <p>{token.metadata.symbol}</p>
          </styles.SaleInfoBlock>
          <styles.SaleInfoBlock>
            <p>
              <Translate value="Sale.Target" />
            </p>
            <p>{token.metadata.symbol}</p>
          </styles.SaleInfoBlock>
        </styles.SaleInfoWrapper>
        <styles.RightSide>
          <stylesTimer.TimerBlock>
            <span>
              <Translate value="Sale.Status.LeftToStart" />
            </span>
            <styles.Timer>
              <Translate value="Sale.Status.ComingSoon" />
            </styles.Timer>
          </stylesTimer.TimerBlock>
          <styles.ArrowLogoContainer>
            <styles.ArrowIcon />
          </styles.ArrowLogoContainer>
        </styles.RightSide>
      </styles.Footer>
    </styles.Container>
  );
}
