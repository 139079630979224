import { useState } from 'react';

import { ESaleKind } from 'services/interfaces';
import LoadingRocket from 'shared/components/LoadingRocket';
import { useAppSelector } from 'shared/hooks/redux';
import { selectIsSignedIn } from 'store/slices/user';

import Form from './components/Form';
import Header from './components/Header';

export default function CreateSalePage() {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const [saleKind, setSaleKind] = useState<ESaleKind>(ESaleKind.SIMPLE);

  const changeSaleKind = ({ target }: { target: HTMLInputElement }) => {
    const { checked } = target;
    setSaleKind(checked ? ESaleKind.FUTURE : ESaleKind.SIMPLE);
  };

  return isSignedIn ? (
    <>
      <Header saleKind={saleKind} changeSaleKind={changeSaleKind} />
      <Form saleKind={saleKind} />
    </>
  ) : (
    <LoadingRocket />
  );
}
