import { configureStore } from '@reduxjs/toolkit';

import { auctionsReducer } from './slices/auctions';
import { nftsReducer } from './slices/nfts';
import { salesReducer } from './slices/sales';
import { servicesReducer } from './slices/services';
import { stakingReducer } from './slices/staking';
import { tokensReducer } from './slices/tokens';
import { userReducer } from './slices/user';

const store = configureStore({
  reducer: {
    sales: salesReducer,
    tokens: tokensReducer,
    auctions: auctionsReducer,
    nfts: nftsReducer,
    staking: stakingReducer,
    user: userReducer,
    services: servicesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
