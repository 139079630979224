import { ListSubheader, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { EAuctionFromValues, IAuctionValues } from 'pages/Auction/create-auction-page/interfaces';
import { auctionContractId } from 'services/config';
import { Translate, translate } from 'shared/components/Translate';
import { useAppSelector } from 'shared/hooks/redux';
import { getNFT } from 'store/actions/helpers';
import { selectData } from 'store/selectors/selectData';

import styles from '../../styles';
import NFTDialogList from './NFTDialogList';

export default function NFTBlock() {
  const {
    nfts,
    user: { userNFTs },
  } = useAppSelector(selectData);
  const { values, setFieldValue } = useFormikContext<IAuctionValues>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const isAnyNFT = !Object.keys(userNFTs).length;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = async (tokenData: { contractId: string; tokenId: string } | null) => {
    setOpen(false);
    if (!tokenData) return;
    const retrievedNFT = getNFT({
      contractId: tokenData.contractId,
      tokenId: tokenData.tokenId,
      nfts,
      userNFTs,
    });
    setFieldValue(EAuctionFromValues.nftContractId, tokenData.contractId);
    setFieldValue(EAuctionFromValues.nftTokenId, tokenData.tokenId);
    setFieldValue(EAuctionFromValues.nftToken, retrievedNFT?.tokenMetadata);
    setFieldValue(EAuctionFromValues.nftContract, retrievedNFT?.contract);
  };

  useEffect(() => {
    if (values.nftTokenId !== values.nftToken?.token_id || values.nftContractId !== values.nftContract?.contractId) {
      setFieldValue(EAuctionFromValues.nftToken, null);
    }
  }, [
    setFieldValue,
    values.nftContract?.contractId,
    values.nftContractId,
    values.nftToken?.token_id,
    values.nftTokenId,
  ]);

  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateAuction.NFTBlock" />
      </styles.Title>
      <styles.ChildWrapper>
        {values.nftContract && values.nftToken && (
          <>
            <ListSubheader>{values.nftContract.contractId}</ListSubheader>
            <ListItem style={{ gap: '1rem' }} key={values.nftToken.token_id}>
              <ListItemAvatar>
                <Avatar sx={{ width: '6.25rem', height: '6.25rem' }} src={values.nftToken.metadata.media} />
              </ListItemAvatar>
              <ListItemText
                style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                primary={values.nftToken.metadata.title}
                secondary={translate({
                  value: 'CreateAuction.TokenId',
                  interpolation: { tokenId: values.nftToken.token_id },
                })}
              />
            </ListItem>
          </>
        )}
        <Button variant="outlined" onClick={handleClickOpen} sx={{ marginTop: '1rem' }} disabled={isAnyNFT}>
          <Translate
            value={isAnyNFT ? 'Action.NoAvailableNFT' : 'Action.OpenUserNFTs'}
            interpolation={{ contractId: auctionContractId }}
          />
        </Button>
        <NFTDialogList isOpen={isOpen} onClose={handleCloseDialog} />
        <styles.Description>
          <span>
            <strong>
              <Translate value="CreateAuction.Note.Title" />
            </strong>
          </span>
          <Translate value="CreateAuction.Note.NFTDescription" />
        </styles.Description>
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
