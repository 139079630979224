import { stakingSeedId } from 'services/config';
import StakingContract from 'services/contract/StakingContract';
import { ZERO_STR } from 'shared/constants';
import { IStaking } from 'shared/interfaces';
import { formatStaking, retrieveStakingData } from 'shared/utils';
import { initialStakingData } from 'store/slices/staking';

export async function retrieveStaking(stakingContract: StakingContract): Promise<IStaking> {
  try {
    const [listStaking, seedInfo] = await Promise.all([
      stakingContract.getListFarmsBySeed(stakingSeedId),
      stakingContract.getSeedInfo(stakingSeedId),
    ]);

    if (!listStaking || !seedInfo) return initialStakingData;
    const listStakingArray = listStaking.map((staking) => formatStaking(staking, seedInfo?.amount || ZERO_STR));
    const stakingData = retrieveStakingData(listStakingArray, seedInfo?.amount || ZERO_STR);
    return stakingData || initialStakingData;
  } catch (error) {
    console.warn(`Error: ${error} \n while retrieveStaking from contractId: ${stakingContract.contractId}`);
    return initialStakingData;
  }
}
