import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NonFungibleTokenContract } from 'services/contract';
import { EMPTY_STRING, ZERO_STR } from 'shared/constants';
import { RootState } from 'store';

type UserState = {
  id: string;
  isSignedIn: boolean;
  userNFTs: Record<string, NonFungibleTokenContract>;
  stakingTokenBalance: string;
};

const initialState: UserState = {
  id: EMPTY_STRING,
  isSignedIn: false,
  userNFTs: {},
  stakingTokenBalance: ZERO_STR,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccountId(state, action: PayloadAction<string>) {
      return { ...state, id: action.payload };
    },
    setIsSignedIn(state, action: PayloadAction<boolean>) {
      return { ...state, isSignedIn: action.payload };
    },
    setUserNFTs(state, action: PayloadAction<Record<string, NonFungibleTokenContract>>) {
      return { ...state, userNFTs: action.payload };
    },
    setStakingTokenBalance(state, action: PayloadAction<string>) {
      return { ...state, stakingTokenBalance: action.payload };
    },
  },
});

// SELECTORS
export const selectUser = (state: RootState) => state.user;
export const selectAccountId = (state: RootState) => state.user.id;
export const selectIsSignedIn = (state: RootState) => state.user.isSignedIn;
export const selectUserNfts = (state: RootState) => state.user.userNFTs;
export const selectUserStakingTokenBalance = (state: RootState) => state.user.stakingTokenBalance;

// ACTIONS
export const { setUserNFTs, setStakingTokenBalance, setAccountId, setIsSignedIn } = userSlice.actions;

export const userReducer = userSlice.reducer;
