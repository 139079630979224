import { IAuctionValues } from 'pages/Auction/create-auction-page/interfaces';
import { AuctionInput, ESaleType, ITokenMetadata, SaleInputProps } from 'services/interfaces';
import { EMPTY_STRING, ZERO_STR } from 'shared/constants';
import { ESocial, ETypeVesting, ISale, IValues, IVesting } from 'shared/interfaces';

import { getSaleStatus } from './calculationDate';
import { hexToUint8Array, millisecondsToNanoseconds, parseTokenAmount } from './formatAmount';
import getTimeByDate from './getTimeByDate';
import { formatVesting, getVestingArray, getVestingType, isNullOrEmpty } from './index';

export const formatValues = (
  values: IValues,
  depositTokenMetadata: ITokenMetadata,
  distributeTokenMetadata: ITokenMetadata
): SaleInputProps => {
  const {
    projectName,
    projectSite,
    description,
    distributeTokenId,
    maxAmount,
    depositTokenId,
    minAllocation,
    maxAllocation,
    limitPerTransaction,
    roundType,
    price,
    roundStartDate,
    roundFinishDate,
    socialTg,
    socialTwitter,
    socialMedium,
    vesting,
    whitelistRootHash,
  } = values;
  const { symbol, decimals } = depositTokenMetadata;

  const parsedMaxAmount = maxAmount ? parseTokenAmount(maxAmount, decimals) : undefined;
  const parsedMinAllocation = minAllocation ? parseTokenAmount(minAllocation, decimals) : undefined;
  const parsedMaxAllocation = maxAllocation ? parseTokenAmount(maxAllocation, decimals) : undefined;
  const parsedLimitPerTransaction = limitPerTransaction ? parseTokenAmount(limitPerTransaction, decimals) : undefined;
  const parsedPrice = price ? parseTokenAmount(price, decimals) : undefined;
  const parsedWhitelistRootHash = whitelistRootHash ? hexToUint8Array(whitelistRootHash) : null;

  const startDate = roundStartDate ? millisecondsToNanoseconds(getTimeByDate(roundStartDate)).toString() : undefined;
  const endDate = roundFinishDate ? millisecondsToNanoseconds(getTimeByDate(roundFinishDate)).toString() : undefined;

  return {
    metadata: {
      name: projectName,
      symbol,
      description,
      smart_contract_url: projectSite,
      logo_url: EMPTY_STRING,
      output_ticker: distributeTokenMetadata.symbol,
      project_telegram: socialTg || undefined,
      project_medium: socialMedium || undefined,
      project_twitter: socialTwitter || undefined,
    },
    staking_contracts: [],
    max_amount: parsedMaxAmount,
    min_near_deposit: ZERO_STR,
    deposit_token_id: depositTokenId,
    distribute_token_decimals: distributeTokenMetadata.decimals,
    distribute_token_id: distributeTokenId,
    min_buy: parsedMinAllocation,
    max_buy: parsedMaxAllocation,
    limit_per_transaction: parsedLimitPerTransaction,
    start_date: startDate,
    end_date: endDate,
    price: parsedPrice,
    sale_type: roundType === ESaleType.Empty ? undefined : roundType,
    claim_available: false,
    refund_available: false,
    vesting,

    whitelist_hash: parsedWhitelistRootHash,
  };
};

export const formatValuesToSale = (values: IValues, id: number) => {
  const {
    projectName,
    projectSite,
    description,
    roundType,
    roundStartDate,
    roundFinishDate,
    socialTg,
    socialTwitter,
    socialMedium,
    maxAmount,
    minAllocation,
    maxAllocation,
    price,
    vesting,
    depositToken,
  } = values;

  const rewardTicker = values.distributeToken?.metadata?.symbol || '';
  const roundStartDateValue = isNullOrEmpty(roundStartDate) ? 0 : getTimeByDate(roundStartDate);
  const roundFinishDateValue = isNullOrEmpty(roundFinishDate) ? 0 : getTimeByDate(roundFinishDate);

  const minBuy = isNullOrEmpty(minAllocation) ? ZERO_STR : minAllocation.toString();
  const maxBuy = isNullOrEmpty(maxAllocation) ? ZERO_STR : maxAllocation.toString();
  const formattedPrice = isNullOrEmpty(price) ? ZERO_STR : price.toString();
  const totalAmount = isNullOrEmpty(maxAmount) ? ZERO_STR : maxAmount.toString();
  const collectedAmount = ZERO_STR;

  const status = getSaleStatus(roundStartDateValue, roundFinishDateValue, totalAmount, collectedAmount, roundType);

  let vestingArray: IVesting[] = [];
  let vestingType: ETypeVesting | null = null;
  if (vesting && vesting.length) {
    const formattedVesting = formatVesting(vesting);
    vestingType = getVestingType(formattedVesting);
    vestingArray = getVestingArray(formattedVesting, vestingType, roundFinishDateValue) || [];
  }
  const sale: ISale = {
    id,
    depositTokenId: values.depositTokenId,
    claimAvailable: false,
    refundAvailable: false,
    distributeTokenId: values.depositTokenId,
    saleType: roundType,
    status,
    minBuy,
    maxBuy,
    price: formattedPrice,
    totalAmount,
    numAccountSales: 0,
    collectedAmount,
    endDate: roundFinishDateValue,
    startDate: roundStartDateValue,
    vesting: vestingArray,
    vestingType,
    distributeTokenDecimals: values.distributeToken?.metadata?.decimals || 0,
    limitPerTransaction: values.limitPerTransaction,
    claimBegun: false,
    cliff: null,
    metadata: {
      name: projectName,
      rewardTicker,
      description,
      projectUrl: projectSite,
      socials: [
        { value: socialTg, type: ESocial.Telegram },
        { value: socialTwitter, type: ESocial.Medium },
        { value: socialMedium, type: ESocial.Twitter },
      ],
      depositSymbol: depositToken?.metadata?.symbol || EMPTY_STRING,
      rewardTokenLogo: depositToken?.metadata?.icon || EMPTY_STRING,
      rewardDescription: null,
    },
    whitelistRootHash: null,
  };
  return sale;
};

export const formatAuctionValues = (auction: IAuctionValues, decimals: number): AuctionInput => {
  const {
    nftContractId,
    nftTokenId,
    depositTokenId,
    startDate,
    endDate,
    initialPrice,
    buyoutPrice,
    auctionStep,
    auctionMinStep,
    projectLink,
    twitterLink,
    mediumLink,
    telegramLink,
  } = auction;
  const parsedStartDate = getTimeByDate(startDate);
  const parsedEndDate = getTimeByDate(endDate);
  const parsedInitialPrice = parseTokenAmount(initialPrice, decimals);

  const parsedBuyoutPrice = buyoutPrice ? parseTokenAmount(buyoutPrice, decimals) : undefined;
  const parsedAuctionStep = auctionStep ? parseTokenAmount(auctionStep, decimals) : undefined;
  const parsedAuctionMinStep = auctionMinStep ? parseTokenAmount(auctionMinStep, decimals) : undefined;

  return {
    nft_contract_id: nftContractId,
    nft_token_id: nftTokenId,
    deposit_token_id: depositTokenId,
    start_date: millisecondsToNanoseconds(parsedStartDate).toString(),
    end_date: millisecondsToNanoseconds(parsedEndDate).toString(),
    initial_price: parsedInitialPrice,
    buyout_price: parsedBuyoutPrice,
    auction_step: parsedAuctionStep,
    auction_min_step: parsedAuctionMinStep,
    metadata: {
      project_link: projectLink,
      twitter_link: twitterLink,
      medium_link: mediumLink,
      telegram_link: telegramLink,
    },
  };
};
