import { useNavigate } from 'react-router-dom';

import { toSale } from 'routes/constant';
import { calcTotalClaimAvailable } from 'shared/calculation';
import { useAppSelector } from 'shared/hooks/redux';
import useStatus from 'shared/hooks/useSaleStatus';
import { EStatus, ISale } from 'shared/interfaces';
import { formatTokenAmount } from 'shared/utils';
import { selectToken } from 'store/selectors/selectToken';

import SaleLayoutInfo from '../SaleLayoutInfo';
import { Translate } from '../Translate';
import HelperSection from './HelperSection';
import styles from './styles';

interface ICard {
  sale: ISale;
}

export default function SaleCard({ sale }: ICard) {
  const navigate = useNavigate();
  const { status } = useStatus(
    sale.startDate,
    sale.endDate,
    sale.totalAmount,
    sale.collectedAmount,
    sale.saleType,
    sale.status
  );

  const token = useAppSelector((state) => selectToken(state, sale.depositTokenId));

  if (!token?.metadata) return null;
  const depositTokenPrice = formatTokenAmount(sale.price, token.metadata.decimals);
  const formatTotalAmount = formatTokenAmount(sale.totalAmount, token.metadata.decimals);

  return (
    <styles.Container onClick={() => navigate(toSale(sale.id))} isOpen={status === EStatus.Open}>
      <SaleLayoutInfo
        name={sale.metadata.name}
        tokenIcon={token.metadata.icon}
        currency={token.metadata.symbol}
        saleType={sale.saleType}
        status={status}
      />
      <styles.Footer>
        <styles.SaleInfoWrapper>
          <styles.SaleInfoBlock>
            <p>
              <Translate
                value="Sale.SalePrice"
                interpolation={{
                  symbol: sale.metadata.rewardTicker,
                }}
              />
            </p>
            <p>
              <Translate
                value="Sale.Price"
                interpolation={{
                  value: depositTokenPrice,
                  symbol: token.metadata.symbol,
                }}
              />
            </p>
          </styles.SaleInfoBlock>
          <styles.SaleInfoBlock>
            <p>
              <Translate value="Sale.Target" />
            </p>
            <p>
              <Translate
                value="Sale.Price"
                interpolation={{
                  value: formatTotalAmount,
                  symbol: token.metadata.symbol,
                }}
              />
            </p>
          </styles.SaleInfoBlock>
        </styles.SaleInfoWrapper>
        <styles.RightSide>
          <HelperSection
            sale={sale}
            status={status}
            totalClaimAvailable={calcTotalClaimAvailable(
              sale.totalAmount,
              sale.collectedAmount,
              sale.price,
              token.metadata.decimals
            )}
          />
          <styles.ArrowLogoContainer>
            <styles.ArrowIcon />
          </styles.ArrowLogoContainer>
        </styles.RightSide>
      </styles.Footer>
    </styles.Container>
  );
}
