import { IParticipantData } from 'shared/utils/getParticipantData';

import { Translate } from '../Translate';
import styles from './styles';

export default function ValueSale({ participantData }: { participantData: IParticipantData[] }) {
  return (
    <styles.Wrapper>
      {participantData.map(({ title, additionalTitle, amount, currency }) => (
        <styles.Item key={title}>
          <styles.Title>
            <Translate
              value={title}
              interpolation={{
                symbol: additionalTitle,
              }}
            />
          </styles.Title>
          <styles.Data>
            {currency ? (
              <Translate
                value="Sale.Price"
                interpolation={{
                  value: amount,
                  symbol: currency,
                }}
              />
            ) : (
              amount
            )}
          </styles.Data>
        </styles.Item>
      ))}
    </styles.Wrapper>
  );
}
