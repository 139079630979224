import styled from 'styled-components';

import { ISocials } from 'shared/interfaces';
import { ImageMap } from 'shared/utils';

export interface ISocialNetwork {
  socials: ISocials[];
  color: string;
  isCardPage?: boolean;
}

const SocialNetworksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialNetworksIcon = styled.a<{ isCardPage?: boolean }>`
  ${({ isCardPage }) => (isCardPage ? 'margin-left: 2rem;' : 'margin-right: 2rem;')}
  & > svg {
    width: 2rem;
    height: 2rem;
  }
  ${({ theme, isCardPage }) => theme.mediaWidth.upToSmall`
    margin: ${isCardPage ? '0 1rem' : '0 2rem 0 0'};
  `}
`;

export default function SocialNetwork({ socials, color, isCardPage }: ISocialNetwork) {
  const socialsLength = socials.length;
  return (
    <SocialNetworksWrapper>
      {socialsLength &&
        socials.map(({ value, type }) => {
          if (!value) return null;
          const Image = ImageMap[type];
          return (
            <SocialNetworksIcon
              key={`${value}-${type}`}
              href={value}
              target="_blank"
              rel="noreferrer"
              isCardPage={isCardPage}
            >
              <Image fill={color} />
            </SocialNetworksIcon>
          );
        })}
    </SocialNetworksWrapper>
  );
}
