import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/images/icon/close-icon.svg';
import { ReactComponent as ExternalLink } from 'assets/images/icon/external-link.svg';

const Container = styled.div<{ isCentered: boolean }>`
  max-width: 43rem;
  width: 100%;
  ${({ isCentered }) => isCentered && 'margin: 0 auto;'}
`;

const InfoWrapper = styled.div`
  padding: 0 0.5rem;
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.white};
  position: relative;
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.shadowWrapper};
  border-radius: 1rem;
  padding: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0;
    padding: 1rem 1rem 5rem;
  `}
`;

const CloseBtn = styled(CloseIcon)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1%;
  right: 1%;
  cursor: pointer;
  z-index: 1;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 0.875rem;
  line-height: 140%;
  margin-top: 3rem;
  padding: 0 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 2.25rem;
  `}
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 4.375rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1.875rem;
    flex-direction: column;
    justify-content: center;
    div {
      margin-top: 1.875rem;
    }
  `}
  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.orange};
    font-weight: 600;
    margin-right: 0.938rem;
  }
`;

const SocialBox = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export default {
  Container,
  Wrapper,
  CloseBtn,
  InfoWrapper,
  Description,
  SocialWrapper,
  ExternalLink,
  SocialBox,
};
