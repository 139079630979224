import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITokenMetadata } from 'services/interfaces';
import { generateList, downloadFile } from 'shared/utils';
import { RootState } from 'store';

import { retrieveSaleAccountsArray } from '../helpers';

export const downloadSaleAccounts = createAsyncThunk<
  void,
  {
    saleId: number;
    numAccountSales: number;
    metadata: ITokenMetadata;
  },
  { state: RootState }
>('sales/downloadSaleAccounts', async ({ saleId, numAccountSales, metadata }, { getState }) => {
  try {
    const {
      services: { saleContract },
    } = getState();
    if (!saleContract) return;
    const resultsArray = await retrieveSaleAccountsArray(saleContract, saleId, numAccountSales);
    const list = generateList(resultsArray, metadata);
    downloadFile(list, saleId);
  } catch (error) {
    console.error('Error during downloadSaleAccounts: ', error);
  }
});
