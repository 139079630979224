const colors = {
  black: '#131313',
  white: '#FFFFFF',
  grey: '#A1A1A1',
  blue: '#4299e1',
  orange: '#FF5924',
  orangeAlt: '#ed6c02',
  grayOp01: '#F4F4f5',
  grayOp04: '#D2D4D7',
  grayOp06: '#BBBEC2',

  boxShadow: 'rgba(34, 60, 80, 0.75)',
  greyBorderBox: '#E1E1E1',
  grayHover: '#E1E1E1',

  grayBg: 'rgba(179, 179, 179, 0.2)',
  opacityGray: 'rgba(181, 187, 194, 0.2)',
  orangeFocused: 'rgba(255, 122, 0, 0.1)',
  orange1: '#FF7A00',
  progressBar: '#FFC3B0',
  progressBarCompleted: '#FF5924',
  blackText: '#424242',
  greySearch: '#F0F0F0',
  placeholder: '#E1E1E1',
  error: '#FF0505',

  statusOpenBg: '#DCEED1',
  statusSoonBg: '#FDE9CC',
  statusEndedBg: '#E8E9EB',
  statusCanceledBg: '#FDDCDC',
  statusOpenText: '#51A81B',
  statusSoonText: '#F49302',
  statusEndedText: '#8E939A',
  statusCanceledText: '#F65151',

  lightOrange: '#FFEEE9',
  primaryGrey: '#8E939A',
  orangeDivision: '#FFD0C1',

  blackOpacity015: 'rgba(0, 0, 0, 0.15)',
  orangeRGB: '255, 89, 36',
  bgGrey: '181,187,194',
  lightGrey: '#676C73',
  darkGrey: '#B3B3B3',

  red: '#F65151',
  redDot: '#F86069',
  shadowWrapper: 'rgba(19, 19, 19, 0.2)',
  boxShadowInput: 'rgba(255, 89, 36, 0.1)',
  opacityGrey: 'rgba(181, 187, 194, 0.2)',
  transparent: 'transparent',

  orangeHover: '#F54F1A',
  orangeActive: '#EB4510',
  lightOrangeHover: '#FFDED3',
  lightOrangeActive: '#FFCDBD',

  orangeTextHighlight: '#FFCDBD',
  statusOpenCard: '#51A81B',

  blackOp015: 'rgba(0, 0, 0, 0.15)',
  pink: '#BC439A',
  pinkOp01: '#F8ECF5',
  darkPink: '#572649',
  darkPinkHv: '#461E3A',
  darkPinkActive: '#38182E',
  pinkHover: '#B23990',
  pinkActive: '#A82F86',
  lightPink: 'rgba(188, 67, 154, 0.1)',
  lightPinkHover: 'rgba(188, 67, 154, 0.2)',
  lightPinkActive: 'rgba(188, 67, 154, 0.3)',

  gray: '#8E939A',
  grayLine: '#8E939A',
  lightRed: '#F86069',

  boxShadowCard: 'rgba(19, 19, 19, 0.2)',
  pinkBgFooter: '#F8ECF5',
  pinkCircle: '#BC439A',
  pinkTextHighlight: '#F2D9EB',
  cardDescription: '#676C73',
  textShadow: '#24272A',
};

export default colors;
