import { LocalizationProvider, DateTimePicker } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { TextField } from '@mui/material';
import { FieldInputProps, FieldMetaProps } from 'formik';

import { EFromValues } from 'shared/interfaces';

import { formPlaceholders, formTitles } from './utils';

interface IInput<Value> {
  field: FieldInputProps<Value>;
  meta: FieldMetaProps<Value>;
  name: EFromValues;
}

interface DatePicker<Value> extends IInput<Value> {
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
}

export function StakingDatePickerInput({ field, meta, name, setFieldValue }: DatePicker<Date>) {
  const error = Boolean(meta.touched && meta.error);
  const handleChange = (newValue: Date | null) => {
    if (!newValue) return;
    setFieldValue(name, newValue.toString());
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={field.value}
        onChange={handleChange}
        ampm={false}
        disablePast
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label={formTitles[name]}
            autoComplete="off"
            error={error}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export function StakingNumericalInput({ field, meta, name }: IInput<number>) {
  const error = Boolean(meta.touched && meta.error);
  return (
    <TextField
      type="number"
      label={formTitles[name]}
      placeholder={formPlaceholders[name]}
      variant="filled"
      {...field}
      error={error}
      helperText={meta.touched && meta.error}
    />
  );
}
