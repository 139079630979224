import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NonFungibleTokenContract } from 'services/contract';
import { RootState } from 'store';

interface INftsState {
  obj: { [key: string]: NonFungibleTokenContract };
  loading: boolean;
}

const initialState: INftsState = {
  obj: {},
  loading: true,
};

export const nftsSlice = createSlice({
  name: 'nfts',
  initialState,
  reducers: {
    setNfts: (state, action: PayloadAction<{ [key: string]: NonFungibleTokenContract }>) => {
      return {
        ...state,
        obj: action.payload,
      };
    },
    setNftsLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

// SELECTORS
export const selectNfts = (state: RootState) => state.nfts.obj;
export const selectNftsLoading = (state: RootState) => state.nfts.loading;

// ACTIONS
export const { setNfts, setNftsLoading } = nftsSlice.actions;

export const nftsReducer = nftsSlice.reducer;
