import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { TextField, TextFieldProps, Button, FormControlLabel, Switch } from '@mui/material';
import { useCallback, useState } from 'react';

import { useWalletData } from 'providers/WalletProvider';
import { createFormattedDate } from 'services/helpers';
import { ITokenMetadata, VestingOutput } from 'services/interfaces';
import { EMPTY_STRING } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { ESaleMethod, IAdminValues, ISale } from 'shared/interfaces';
import { disableFields, EAdminValue, setupInitialAdminValues, formatAdminValue, formatTokenAmount } from 'shared/utils';
import {
  removeSale,
  updateSaleDates,
  updateSaleDistributeTokenId,
  updateSalePrice,
  updateSaleClaimAndRefund,
  downloadSaleAccounts,
  changeVesting,
  cancelSale,
  changeWhitelistHash,
} from 'store/actions/sale';
import { selectIsSignedIn } from 'store/slices/user';

import TextInput from '../../Input/TextInput';
import { translate, Translate } from '../../Translate';
import VestingAdminPanel from '../../VestingAdminPanel';
import styles from '../styles';

interface IAdminPanel {
  sale: ISale;
  depositTokenMetadata: ITokenMetadata;
}

export default function AdminPanel({ sale, depositTokenMetadata }: IAdminPanel) {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const dispatch = useAppDispatch();
  const { sendTransaction } = useWalletData();

  const [values, setValues] = useState<IAdminValues>(setupInitialAdminValues(sale, depositTokenMetadata.decimals));
  const [isValidVesting, setIsValidVesting] = useState<boolean>(false);

  const { buttons, inputs } = disableFields(sale, values, isSignedIn, isValidVesting, depositTokenMetadata.decimals);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleClick = useCallback(
    (saleMethod: ESaleMethod) => {
      const newValues = formatAdminValue(values, depositTokenMetadata);
      switch (saleMethod) {
        case ESaleMethod.REMOVE_SALE:
          dispatch(removeSale({ sendTransaction, saleId: sale.id }));
          break;
        case ESaleMethod.UPDATE_SALE_DATES:
          dispatch(
            updateSaleDates({
              sendTransaction,
              saleId: sale.id,
              startDate: newValues.newStartDate,
              endDate: newValues.newEndDate,
            })
          );
          break;
        case ESaleMethod.UPDATE_SALE_DISTRIBUTE_TOKEN_ID:
          dispatch(
            updateSaleDistributeTokenId({
              sendTransaction,
              saleId: sale.id,
              distributeTokenId: newValues.distributeTokenId,
            })
          );
          break;
        case ESaleMethod.UPDATE_SALE_PRICE:
          dispatch(
            updateSalePrice({
              sendTransaction,
              saleId: sale.id,
              price: newValues.newPrice,
              totalAmount: newValues.newMaxAmount,
            })
          );
          break;
        case ESaleMethod.UPDATE_SALE_CLAIM_AND_REFUND:
          dispatch(
            updateSaleClaimAndRefund({
              sendTransaction,
              saleId: sale.id,
              claimAvailable: newValues.claimAvailable,
              refundAvailable: newValues.refundAvailable,
              sale,
            })
          );
          break;
        case ESaleMethod.DOWNLOAD_SALE_ACCOUNTS:
          dispatch(
            downloadSaleAccounts({
              saleId: sale.id,
              numAccountSales: sale.numAccountSales,
              metadata: depositTokenMetadata,
            })
          );
          break;
        case ESaleMethod.CHANGE_VESTING:
          dispatch(changeVesting({ sendTransaction, saleId: sale.id, inputVesting: newValues.vesting }));
          break;
        case ESaleMethod.CANCEL_SALE:
          dispatch(cancelSale({ sendTransaction, saleId: sale.id }));
          break;
        case ESaleMethod.CHANGE_WHITELIST_HASH:
          if (newValues.whitelistRootHash) {
            changeWhitelistHash({ sendTransaction, saleId: sale.id, newHash: newValues.whitelistRootHash });
          }
          break;

        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeSale, sale, depositTokenMetadata, values]
  );

  return (
    <styles.Container>
      <p>
        <Translate value="AdminPanel.Title" />
      </p>
      <styles.Row>
        <TextInput
          value={values.newPrice}
          label="AdminPanel.Price.Label"
          name={EAdminValue.newPrice}
          type="number"
          placeholder="AdminPanel.Price.Placeholder"
          handleChange={handleChange}
          disabled={inputs.inputSalePrice}
        />
        <TextInput
          value={values.newMaxAmount}
          label="AdminPanel.TargetAmount.Label"
          name={EAdminValue.newMaxAmount}
          type="number"
          placeholder="AdminPanel.TargetAmount.Placeholder"
          handleChange={handleChange}
          disabled={inputs.inputSalePrice}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableSalePrice}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_PRICE)}
        >
          <Translate value="Action.UpdateSalePrice" />
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <TextInput
          value={values.distributeTokenId}
          label="AdminPanel.DistributeTokenId.Label"
          name={EAdminValue.distributeTokenId}
          type="text"
          placeholder="AdminPanel.DistributeTokenId.Placeholder"
          handleChange={handleChange}
          disabled={!isSignedIn}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableDistributeTokenId}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_DISTRIBUTE_TOKEN_ID)}
        >
          <Translate value="Action.UpdateDistributeTokenId" />
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
            label={translate({ value: 'AdminPanel.StartDate' })}
            value={values.newStartDate}
            onChange={(date: string | null) => {
              if (!date) return;
              setValues({
                ...values,
                [EAdminValue.newStartDate]: createFormattedDate(date),
              });
            }}
            ampm={false}
            disabled={inputs.inputSaleDates}
          />
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
            label={translate({ value: 'AdminPanel.EndDate' })}
            value={values.newEndDate}
            onChange={(date: string | null) => {
              if (!date) return;
              setValues({
                ...values,
                [EAdminValue.newEndDate]: createFormattedDate(date),
              });
            }}
            ampm={false}
            disabled={inputs.inputSaleDates}
          />
        </LocalizationProvider>
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableSaleDates}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_DATES)}
        >
          <Translate value="Action.UpdateSaleDates" />
        </Button>
      </styles.WrapperButton>
      <styles.Row>
        <FormControlLabel
          value={values.claimAvailable}
          checked={values.claimAvailable}
          onChange={(e, checked) => setValues({ ...values, [EAdminValue.claimAvailable]: checked })}
          control={<Switch color="warning" />}
          label={translate({ value: 'AdminPanel.Claim' })}
          labelPlacement="start"
          disabled={!isSignedIn}
        />
        <FormControlLabel
          value={values.refundAvailable}
          checked={values.refundAvailable}
          onChange={(e, checked) => setValues({ ...values, [EAdminValue.refundAvailable]: checked })}
          control={<Switch color="warning" />}
          label={translate({ value: 'AdminPanel.Refund' })}
          labelPlacement="start"
          disabled={inputs.inputSaleRefund}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableClaimAndRefund}
          onClick={() => handleClick(ESaleMethod.UPDATE_SALE_CLAIM_AND_REFUND)}
        >
          <Translate value="Action.UpdateClaimAndRefund" />
        </Button>
      </styles.WrapperButton>
      <styles.VestingContainer>
        <VestingAdminPanel
          initialVestingType={values.vestingType}
          saleTotalAmount={formatTokenAmount(sale.totalAmount, depositTokenMetadata.decimals)}
          saleEndDate={sale.endDate}
          setIsValidVesting={setIsValidVesting}
          handleChange={(vesting: VestingOutput[]) => setValues({ ...values, [EAdminValue.vesting]: vesting })}
          currentVesting={values.vesting}
          rewardTicker={sale.metadata.rewardTicker}
        />
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleClick(ESaleMethod.CHANGE_VESTING)}
          disabled={buttons.disableChangeVesting}
        >
          <Translate value="Action.UpdateVesting" />
        </Button>
      </styles.VestingContainer>
      <styles.Row>
        <TextInput
          value={values.whitelistRootHash || EMPTY_STRING}
          label="Whitelist.Input.Update.Label"
          name={EAdminValue.whitelistRootHash}
          type="text"
          placeholder="Whitelist.Input.Placeholder"
          handleChange={handleChange}
          disabled={inputs.inputWhitelistRootHash}
        />
      </styles.Row>
      <styles.WrapperButton>
        <Button
          variant="contained"
          color="warning"
          disabled={buttons.disableUpdateWhitelistRootHash}
          onClick={() => handleClick(ESaleMethod.CHANGE_WHITELIST_HASH)}
        >
          <Translate value="Action.ChangeWhitelistRootHash" />
        </Button>
      </styles.WrapperButton>
      {isSignedIn && sale.numAccountSales > 0 && (
        <Button variant="outlined" color="info" onClick={() => handleClick(ESaleMethod.DOWNLOAD_SALE_ACCOUNTS)}>
          <Translate value="Action.DownloadListAccount" />
        </Button>
      )}
      <Button
        variant="contained"
        color="inherit"
        disabled={buttons.disableCancelSale}
        onClick={() => handleClick(ESaleMethod.CANCEL_SALE)}
      >
        <Translate value="Action.CancelSale" />
      </Button>
      <Button
        variant="contained"
        color="error"
        disabled={buttons.disableRemoveSale}
        onClick={() => handleClick(ESaleMethod.REMOVE_SALE)}
      >
        <Translate value="Action.RemoveSale" />
      </Button>
    </styles.Container>
  );
}
