import Big from 'big.js';
import { t } from 'i18next';

import { IStakeInput } from 'providers/LocalProviders/StakingProvider';
import { EStakingStatus } from 'services/interfaces';
import i18n from 'services/translation';
import { ZERO_STR } from 'shared/constants';
import { IFormattedStaking } from 'shared/interfaces';
import colors from 'shared/theme/colors';
import {
  displayAmount,
  displayAmountWithSpace,
  getParseDate,
  parseTokenAmount,
  secondsToMilliseconds,
} from 'shared/utils';

export const FarmStatusLocales = {
  [EStakingStatus.Running]: i18n.t('Staking.Status.Active'),
  [EStakingStatus.Created]: i18n.t('Staking.Status.Pending'),
  [EStakingStatus.Ended]: i18n.t('Staking.Status.Ended'),
  [EStakingStatus.Cleared]: i18n.t('Staking.Status.Cleared'),
};

export const calcFarmData = (
  staking: IFormattedStaking,
  stakingInput: IStakeInput | undefined,
  decimals: number,
  symbol: string
) => {
  const { totalReward, claimedReward, unclaimedReward, rewardPerSeconds, apy } = staking;
  const parsedDepositAmount = parseTokenAmount(stakingInput?.depositAmount || ZERO_STR, decimals);
  const currentTotalReward = Big(totalReward).plus(parsedDepositAmount).toFixed();
  return [
    {
      title: t('Staking.TotalReward'),
      value: t('TokenAmount', {
        amount: displayAmountWithSpace({
          amount: currentTotalReward,
          decimals,
          precision: 0,
        }),
        symbol,
      }),
      color: Big(totalReward).eq(currentTotalReward) ? undefined : colors.orange,
    },
    {
      title: t('Staking.RewardPerSeconds'),
      value: t('TokenAmount', {
        amount: displayAmount(rewardPerSeconds, decimals),
        symbol,
      }),
    },
    {
      title: t('Staking.Apy.Title'),
      value: t('Staking.Apy.Value', { apy }),
      color: colors.orange,
    },
    {
      title: t('Staking.ClaimedReward'),
      value: t('TokenAmount', {
        amount: displayAmountWithSpace({
          amount: claimedReward,
          decimals,
          precision: 0,
        }),
        symbol,
      }),
    },
    {
      title: t('Staking.UnclaimedReward'),
      value: t('TokenAmount', {
        amount: displayAmountWithSpace({
          amount: unclaimedReward,
          decimals,
          precision: 0,
        }),
        symbol,
      }),
    },
  ];
};

export const parseDateInSeconds = (dateInSeconds: number) => {
  const dateInMilliseconds = secondsToMilliseconds(dateInSeconds);
  const { year, month, day, hours, minutes, seconds } = getParseDate(dateInMilliseconds);
  return t('Staking.Date', {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
  });
};
