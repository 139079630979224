import styled from 'styled-components';

import { ReactComponent as ClearSearchIcon } from 'assets/images/icon/clear-search.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  & > svg {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`flex: 0;`}
`;

const WrapperCards = styled.div`
  max-width: 100vw;
  display: grid;
  grid-template-columns: repeat(3, minmax(328px, 1fr));

  grid-gap: 1.5rem;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: repeat(auto-fit, minmax(328px, 1fr));
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
  margin-bottom: 1.75rem;
  align-self: center;
  max-width: 43rem;
  width: 100%;
  transition: all 1s ease;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 2rem;
    max-width: 100%;
  `}
`;

const StyledInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 30%;
  border: 2px solid ${({ theme }) => theme.grey};
  border-radius: 8px;
  min-height: 48px;
  :focus-within {
    border: 2px solid ${({ theme }) => theme.pink};
  }
`;

const Input = styled.input`
  margin: 0 48px;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.white};
`;

const ClearSearch = styled(ClearSearchIcon)`
  position: absolute;
  width: 22px;
  height: 22px;
  right: 14px;
  fill: ${({ theme }) => theme.grey};
  :hover {
    fill: ${({ theme }) => theme.white};
  }
`;

const Search = styled(SearchIcon)`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 14px;
  fill: ${({ theme }) => theme.grey};
`;

export default {
  Container,
  LogoContainer,
  WrapperCards,
  Header,
  StyledInput,
  Input,
  ClearSearch,
  Search,
};
