import Big from 'big.js';
import { Fragment } from 'react';

import { ITranslationKeys } from 'services/translation';
import { ONE_HUNDRED, VESTING_PRECISION } from 'shared/constants';
import { ETypeVesting, IVesting } from 'shared/interfaces';

import { Translate } from '../Translate';
import styles from './styles';

interface IVestingSchedule {
  vesting: IVesting[];
  vestingType: ETypeVesting | null;
  totalClaimAvailable?: string;
}
interface IVestingScheduleWrapper {
  children: JSX.Element | JSX.Element[];
  label?: ITranslationKeys;
}

export function VestingScheduleWrapper({ children, label }: IVestingScheduleWrapper) {
  return (
    <styles.Container>
      <styles.Title>Token Vesting Schedule</styles.Title>
      {label && (
        <styles.Label>
          <Translate value={label} />
        </styles.Label>
      )}
      <styles.Vesting>{children}</styles.Vesting>
    </styles.Container>
  );
}

export default function VestingSchedule({ vesting, vestingType, totalClaimAvailable }: IVestingSchedule) {
  switch (vestingType) {
    case ETypeVesting.OneTime:
      return (
        <VestingScheduleWrapper>
          {vesting.map((row) => (
            <Fragment key={row.date}>
              <styles.Row isOneTimeVesting>
                <span>
                  {totalClaimAvailable ? (
                    <Translate value="Vesting.UnlockDateTotal" interpolation={{ totalClaimAvailable }} />
                  ) : (
                    <Translate value="Vesting.UnlockDate" />
                  )}
                </span>
                <span>
                  <Translate
                    value="Vesting.Date"
                    interpolation={{
                      day: row.dateObj.day,
                      month: row.dateObj.monthString,
                      year: row.dateObj.year,
                      hours: row.dateObj.hours,
                      minutes: row.dateObj.minutes,
                    }}
                  />
                </span>
              </styles.Row>
              <styles.Division />
            </Fragment>
          ))}
        </VestingScheduleWrapper>
      );
    default:
      return (
        <VestingScheduleWrapper label="Vesting.UnlockDate">
          {vesting.map((row, index) => {
            const amount = totalClaimAvailable
              ? Big(totalClaimAvailable).mul(row.quota).div(ONE_HUNDRED).toFixed(VESTING_PRECISION)
              : '';
            return (
              <Fragment key={row.date}>
                <styles.Row>
                  <span>
                    {totalClaimAvailable ? (
                      <Translate
                        value="Vesting.UnlockRowAmount"
                        interpolation={{
                          number: index + 1,
                          percent: row.quota.toFixed(VESTING_PRECISION),
                          amount,
                        }}
                      />
                    ) : (
                      <Translate
                        value="Vesting.UnlockRow"
                        interpolation={{
                          number: index + 1,
                          percent: row.quota.toFixed(VESTING_PRECISION),
                        }}
                      />
                    )}
                  </span>
                  <span>
                    <Translate
                      value="Vesting.Date"
                      interpolation={{
                        day: row.dateObj.day,
                        month: row.dateObj.monthString,
                        year: row.dateObj.year,
                        hours: row.dateObj.hours,
                        minutes: row.dateObj.minutes,
                      }}
                    />
                  </span>
                </styles.Row>
                <styles.Division />
              </Fragment>
            );
          })}
        </VestingScheduleWrapper>
      );
  }
}
