import { EMPTY_STRING } from 'shared/constants';
import { ISocialNetwork } from 'shared/interfaces';

import { EAuctionFromValues, IAuctionValues } from './interfaces';

export const social: ISocialNetwork[] = [
  {
    label: 'CreateAuction.ProjectSite.Label',
    type: 'text',
    name: EAuctionFromValues.projectLink,
    placeholder: 'CreateAuction.ProjectSite.Placeholder',
  },
  {
    label: 'CreateAuction.Telegram.Label',
    type: 'text',
    name: EAuctionFromValues.telegramLink,
    placeholder: 'CreateAuction.Telegram.Placeholder',
  },
  {
    label: 'CreateAuction.Twitter.Label',
    type: 'text',
    name: EAuctionFromValues.twitterLink,
    placeholder: 'CreateAuction.Twitter.Placeholder',
  },
  {
    label: 'CreateAuction.Medium.Label',
    type: 'text',
    name: EAuctionFromValues.mediumLink,
    placeholder: 'CreateAuction.Medium.Placeholder',
  },
];

export const financeData = [
  EAuctionFromValues.initialPrice,
  EAuctionFromValues.buyoutPrice,
  EAuctionFromValues.auctionStep,
  EAuctionFromValues.auctionMinStep,
];

export const initialAuctionValues: IAuctionValues = {
  nftContract: null,
  nftToken: null,
  nftContractId: EMPTY_STRING,
  nftTokenId: EMPTY_STRING,

  depositToken: null,
  depositTokenId: EMPTY_STRING,

  startDate: EMPTY_STRING,
  endDate: EMPTY_STRING,

  initialPrice: EMPTY_STRING,

  buyoutPrice: EMPTY_STRING,
  auctionStep: EMPTY_STRING,
  auctionMinStep: EMPTY_STRING,

  projectLink: EMPTY_STRING,
  twitterLink: EMPTY_STRING,
  mediumLink: EMPTY_STRING,
  telegramLink: EMPTY_STRING,
};
