const ACCOUNT_TRIM_LENGTH = 10;

export const trimString = (isSmallDimension: boolean, string: string): string => {
  const strLength = string.length;
  if (!isSmallDimension || strLength < 17) return string;
  const leftPartStr = string.substring(0, 8);
  const rightPartStr = string.substring(strLength - 8, strLength);
  return `${leftPartStr}...${rightPartStr}`;
};

export const trimAccountId = (accountId: string, isMobile?: boolean) => {
  if (accountId.length > 20) {
    return `${accountId.slice(0, ACCOUNT_TRIM_LENGTH)}...`;
  }
  return isMobile ? `${accountId.slice(0, ACCOUNT_TRIM_LENGTH)}...` : accountId;
};
