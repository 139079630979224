import { TextField, Button, TextFieldProps } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

import { createFormattedDate } from 'services/helpers';
import { calcDistributeAmount } from 'shared/calculation';
import { IPeriod } from 'shared/interfaces';
import { emptyPeriods } from 'shared/utils';

import { Translate, translate } from '../Translate';
import styles from './styles';

interface IPeriodSection {
  periods: IPeriod[];
  setPeriods: (periods: IPeriod[]) => void;
  totalAmount?: string;
  rewardTicker: string;
}

export default function PeriodSection({ periods, setPeriods, totalAmount, rewardTicker }: IPeriodSection) {
  const addPeriod = () => {
    setPeriods([...periods, emptyPeriods]);
  };

  const removePeriod = (id: number) => {
    const shallowCopy = [...periods];
    shallowCopy.splice(id, 1);
    setPeriods(shallowCopy);
  };

  return (
    <>
      {periods.map((period, index) => (
        <styles.PeriodContainer key={`${index + 1}`}>
          <styles.PeriodTitle>
            <p>
              <Translate value="Vesting.Period" interpolation={{ index: index + 1 }} />
            </p>
            <p>
              <Translate
                value="Sale.Price"
                interpolation={{
                  value: calcDistributeAmount(totalAmount, period.percent),
                  symbol: rewardTicker,
                }}
              />
            </p>
          </styles.PeriodTitle>
          <TextField
            label={translate({ value: 'Vesting.PercentOfDistribution' })}
            value={period.percent}
            type="number"
            autoComplete="off"
            onWheel={(e: any) => e.target!.blur()}
            onChange={(e) => {
              setPeriods(
                periods.map((obj, prevStateIndex) => {
                  if (index === prevStateIndex) {
                    return { ...obj, percent: e.target.value };
                  }
                  return obj;
                })
              );
            }}
          />
          {index === 0 && (
            <TextField
              label={translate({ value: 'Vesting.Steps' })}
              value={period.steps}
              type="number"
              autoComplete="off"
              onWheel={(e: any) => e.target!.blur()}
              onChange={(e) => {
                setPeriods(
                  periods.map((obj, prevStateIndex) =>
                    index === prevStateIndex ? { ...obj, steps: e.target.value } : obj
                  )
                );
              }}
            />
          )}
          <styles.Row>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {index === 0 ? (
                <DateTimePicker
                  disablePast
                  renderInput={(params: TextFieldProps) => <TextField {...params} error={false} />}
                  label={translate({ value: 'Vesting.StartPeriod' })}
                  value={period.startPeriod}
                  onChange={(newValue: Dayjs | null) => {
                    setPeriods(
                      periods.map((obj, prevStateIndex) =>
                        index === prevStateIndex
                          ? {
                              ...obj,
                              startPeriod: createFormattedDate(newValue),
                            }
                          : obj
                      )
                    );
                  }}
                  ampm={false}
                />
              ) : (
                <TextField
                  label={translate({ value: 'Vesting.Steps' })}
                  value={period.steps}
                  type="number"
                  autoComplete="off"
                  onWheel={(e: any) => e.target!.blur()}
                  onChange={(e) => {
                    setPeriods(
                      periods.map((obj, prevStateIndex) =>
                        index === prevStateIndex ? { ...obj, steps: e.target.value } : obj
                      )
                    );
                  }}
                />
              )}
              <DateTimePicker
                disablePast
                renderInput={(params) => <TextField {...params} error={false} />}
                label={translate({ value: 'Vesting.EndPeriod' })}
                value={period.endPeriod}
                onChange={(newValue: Dayjs | null) => {
                  setPeriods(
                    periods.map((obj, prevStateIndex) =>
                      index === prevStateIndex ? { ...obj, endPeriod: createFormattedDate(newValue) } : obj
                    )
                  );
                }}
                ampm={false}
              />
            </LocalizationProvider>
          </styles.Row>
          {index !== 0 && (
            <Button variant="outlined" color="warning" onClick={() => removePeriod(index)}>
              <Translate value="Action.RemovePeriod" />
            </Button>
          )}
        </styles.PeriodContainer>
      ))}
      <styles.AddPeriod onClick={addPeriod}>
        <styles.Plus />
        <Translate value="Action.AddPeriod" />
      </styles.AddPeriod>
    </>
  );
}
