import Big from 'big.js';

import { DASH, ZERO_STR } from 'shared/constants';

import { formatTokenAmount } from './formatAmount';

const MIN_AMOUNT = '0.00001';

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes('.') || amount.includes(',')) {
    return amount.replace(/\.?0*$/, '');
  }
  return amount;
};

export const displayAmount = (amount: string, decimals?: number) => {
  if (!decimals) return amount;
  const formateAmount = formatTokenAmount(amount, decimals);
  const amountBig = new Big(formateAmount);
  if (amountBig.eq('0')) return DASH;
  if (amountBig.lte('0.01')) return '>0.01';
  return `${removeTrailingZeros(amountBig.toFixed(3))}`;
};

export const displayPriceWithSpace = (str: string) => str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const displayAmountWithSpace = ({
  amount,
  decimals,
  precision = 5,
}: {
  amount: string;
  decimals?: number;
  precision?: number;
}): string => {
  const formattedAmount = formatTokenAmount(amount, decimals || 0);
  const amountBig = Big(formattedAmount);
  if (amountBig.eq(ZERO_STR)) return ZERO_STR;
  if (amountBig.lte(MIN_AMOUNT)) return `>${MIN_AMOUNT}`;
  const newAmount = removeTrailingZeros(amountBig.toFixed(precision));
  return displayPriceWithSpace(newAmount);
};
