import Big from 'big.js';

import { NonFungibleTokenContract, FungibleTokenContract } from 'services/contract';
import { INFTTokenMetadata, ITokenMetadata } from 'services/interfaces';
import { ITranslationKeys } from 'services/translation';
import { translate } from 'shared/components/Translate';
import { DASH, EDimensions, ZERO_STR } from 'shared/constants';
import { EStatus, IAuction } from 'shared/interfaces';

import getTimeLeft from './dateOperation';
import { formatTokenAmount } from './formatAmount';
import { copy, displayAmount, isNotNullOrUndefined } from './index';
import { getAuctionSocials } from './socialLinks';
import { trimString } from './stringOperation';

interface IBidDetails {
  currentBid: string;
  yourBid?: string;
  minMarkup?: string | null;
  isShowingMinMarkup?: boolean;
  decimals: number;
  symbol: string;
}

interface IBidArray {
  title: ITranslationKeys;
  value: string;
}

interface INftDataArray {
  title: ITranslationKeys;
  value: string;
  onClick?: () => void;
}

const generateColumn = (title: ITranslationKeys, value: string, symbol: string, condition = true) => {
  if (condition) return { title, value: translate({ value: 'TokenAmount', interpolation: { amount: value, symbol } }) };
  return null;
};

export const getBidDetailsArray = ({
  currentBid,
  yourBid,
  minMarkup,
  decimals,
  symbol,
  isShowingMinMarkup = true,
}: IBidDetails): IBidArray[] => {
  const winnerBid = displayAmount(currentBid, decimals);
  const formattedYourBid = displayAmount(yourBid || ZERO_STR, decimals);
  const formattedMinMarkup = formatTokenAmount(minMarkup || ZERO_STR, decimals);
  const auctionMinStep = minMarkup ? formattedMinMarkup : DASH;
  const bidArray = [
    generateColumn('Bid.CurrentBid', winnerBid, symbol),
    generateColumn('Bid.MinMarkup', auctionMinStep, symbol, isShowingMinMarkup),
    generateColumn('Bid.YourBid', formattedYourBid, symbol, Big(yourBid || ZERO_STR).gt(ZERO_STR)),
  ].filter(isNotNullOrUndefined);

  return bidArray;
};

export const getCurrentBid = (initialPrice: string, winnerBid: string): string =>
  Big(initialPrice).gt(ZERO_STR) && Big(initialPrice).gt(winnerBid) ? initialPrice : winnerBid;

export const getAuctionData = (
  auction: IAuction | null,
  nfts: { [key: string]: NonFungibleTokenContract },
  token: FungibleTokenContract | null,
  status: EStatus
) => {
  if (!auction) return null;
  const contractNft = nfts[auction.nftContractId];
  const nft = contractNft?.tokenMetadata[auction.nftTokenId];

  if (!token || !token.metadata || !nft) return null;
  const { decimals, symbol } = token.metadata;
  const currentBid = getCurrentBid(auction.initialPrice, auction.winnerBid);
  const isShowingMinMarkup = false;
  const bidArray = getBidDetailsArray({
    currentBid,
    yourBid: auction.userData?.amount,
    decimals,
    symbol,
    isShowingMinMarkup,
  });

  const titleBid = `${displayAmount(auction.userData?.amount || ZERO_STR, decimals)} ${symbol}`;
  const socials = getAuctionSocials(auction);
  const { projectLink } = auction.links;
  const timeLeft = getTimeLeft(status, auction.startDate, auction.endDate);
  return {
    contractNft,
    nft,
    tokenMetadata: token.metadata,
    currentBid,
    bidArray,
    titleBid,
    socials,
    projectLink,
    timeLeft,
  };
};

export const getNftData = (
  dimension: EDimensions,
  contractNft: NonFungibleTokenContract,
  nft: INFTTokenMetadata,
  metadata: ITokenMetadata,
  auctionMinStep?: string | null
): INftDataArray[] => {
  const formattedMinMarkup = displayAmount(auctionMinStep || ZERO_STR, metadata.decimals);
  const contractAddress = trimString(dimension === EDimensions.SMALL, contractNft.contractId);
  const owner = trimString(dimension === EDimensions.SMALL, nft.owner_id);
  const minMarkup = auctionMinStep ? formattedMinMarkup : DASH;
  return [
    {
      title: 'NftData.ContractAddress',
      value: contractAddress,
      onClick: () => copy(contractNft.contractId),
    },
    {
      title: 'NftData.Owner',
      value: owner,
      onClick: () => copy(nft.owner_id),
    },
    {
      title: 'Bid.MinMarkup',
      value: translate({ value: 'TokenAmount', interpolation: { amount: minMarkup, symbol: metadata.symbol } }),
    },
    {
      title: 'NftData.TokenID',
      value: nft.token_id,
      onClick: () => copy(nft.token_id),
    },
  ];
};
