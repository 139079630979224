import { createAsyncThunk } from '@reduxjs/toolkit';

import { isNotNullOrUndefined, onlyUniqueValues, toMap } from 'shared/utils';
import { RootState } from 'store';
import { setFutureSales, setSales, setSalesLoading } from 'store/slices/sales';

import { retrieveSaleArray, retrieveFutureSale } from './helpers';
import { loadTokens } from './loadTokens';

export const loadSales = createAsyncThunk<void, void, { state: RootState }>(
  'sales/loadSales',
  async (_, { dispatch, getState }) => {
    try {
      const {
        services: { saleContract },
      } = getState();
      if (!saleContract) return;
      const [saleArray, futureSalesArray] = await Promise.all([
        retrieveSaleArray(saleContract),
        retrieveFutureSale(saleContract),
      ]);
      const salesMap = toMap(saleArray, 'id');
      const futureSalesMap = toMap(futureSalesArray, 'id');
      const tokenAddresses = onlyUniqueValues(
        [
          ...saleArray.flatMap((sale) => sale.depositTokenId),
          ...saleArray.flatMap((sale) => sale.distributeTokenId),
          ...futureSalesArray.flatMap((sale) => sale.depositTokenId),
          ...futureSalesArray.flatMap((sale) => sale.distributeTokenId),
        ].filter(isNotNullOrUndefined)
      );
      dispatch(setSales(salesMap));
      dispatch(setFutureSales(futureSalesMap));
      dispatch(loadTokens({ tokenAddresses }));
    } catch (e) {
      console.error(`Error: while load sales \n ${e}`);
    } finally {
      dispatch(setSalesLoading(false));
    }
  }
);
