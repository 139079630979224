import Big from 'big.js';
import { object, string } from 'yup';

import { createFormattedDate } from 'services/helpers';
import { ZERO_STR } from 'shared/constants';
import { IAuction } from 'shared/interfaces';
import { isNullOrEmpty } from 'shared/utils';
import { getUTC } from 'shared/utils/dateOperation';

import { IAdminValues } from './interfaces';

const metadataSchema = object({
  projectLink: string().url().required(),
  twitterLink: string().url().required(),
  mediumLink: string().url().required(),
  telegramLink: string().url().required(),
});

const isValidMetadata = (values: IAdminValues) => {
  try {
    metadataSchema.validateSync(values);
    return true;
  } catch (error) {
    return false;
  }
};

const compareTwoString = (firstWord: string | null | undefined, secondWord: string | null | undefined): boolean => {
  if (!firstWord || !secondWord) return false;
  return firstWord.toLowerCase() === secondWord.toLowerCase();
};

export const setupInitialAuctionValues = (auction: IAuction): IAdminValues => {
  return {
    claimAvailable: auction.claimAvailable,
    refundAvailable: auction.refundAvailable,
    startDate: getUTC(auction.startDate).toString(),
    endDate: getUTC(auction.endDate).toString(),
    projectLink: auction.links.projectLink || undefined,
    twitterLink: auction.links.twitterLink || undefined,
    mediumLink: auction.links.mediumLink || undefined,
    telegramLink: auction.links.telegramLink || undefined,
  };
};

export enum EAdminValue {
  claimAvailable = 'claimAvailable',
  refundAvailable = 'refundAvailable',
  startDate = 'startDate',
  endDate = 'endDate',
  projectLink = 'projectLink',
  twitterLink = 'twitterLink',
  mediumLink = 'mediumLink',
  telegramLink = 'telegramLink',
}

export const disabledInputsAndButtons = (auction: IAuction, values: IAdminValues, isSignedIn: boolean) => {
  const dateNow = Date.now();

  const isAuctionStart = dateNow > auction.startDate;
  const similarDisable = !isSignedIn || isAuctionStart;

  const disableAuctionDates =
    similarDisable ||
    isNullOrEmpty(values.startDate) ||
    values.startDate === createFormattedDate(new Date(auction.startDate)) ||
    isNullOrEmpty(values.endDate) ||
    values.endDate === createFormattedDate(new Date(auction.endDate));

  const disableMetadata =
    !isSignedIn ||
    !isValidMetadata(values) ||
    (compareTwoString(values.projectLink, auction.links.projectLink) &&
      compareTwoString(values.mediumLink, auction.links.mediumLink) &&
      compareTwoString(values.twitterLink, auction.links.twitterLink) &&
      compareTwoString(values.telegramLink, auction.links.telegramLink));

  const disableRemoveAuction = !isSignedIn || !Big(auction.collectedAmount).eq(ZERO_STR);
  const disableClaimAndRefund =
    !isSignedIn ||
    (auction.claimAvailable === values.claimAvailable && auction.refundAvailable === values.refundAvailable);

  const buttons = {
    disableAuctionDates,
    disableRemoveAuction,
    disableClaimAndRefund,
    disableMetadata,
  };

  const inputAuctionDates = similarDisable;
  const inputAuctionMetadata = !isSignedIn;
  const inputs = {
    inputAuctionDates,
    inputAuctionMetadata,
  };
  return {
    buttons,
    inputs,
  };
};
