import { useMemo } from 'react';

import { ReactComponent as ArrowRightIcon } from 'assets/images/icon/arrow-right-icon.svg';
import Timer from 'shared/components/Timer';
import { EStatus } from 'shared/interfaces';
import { getTimeLeft } from 'shared/utils';

import styles from './styles';

interface IFooter {
  status: EStatus;
  startDate: number;
  endDate: number;
}

export default function Footer({ status, startDate, endDate }: IFooter): JSX.Element {
  const timeLeft = useMemo(() => getTimeLeft(status, startDate, endDate), [status, startDate, endDate]);
  const isShowingData = status !== EStatus.Closed;

  return (
    <styles.Footer isShowingHelpBlock={isShowingData}>
      {status !== EStatus.Closed && <Timer time={timeLeft} status={status} />}
      <styles.ArrowContainer>
        <ArrowRightIcon />
      </styles.ArrowContainer>
    </styles.Footer>
  );
}
