import { providers } from 'near-api-js';
import { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { getCurrentRoute } from 'routes/constant';
import { explorerUrl, nodeUrl } from 'services/config';
import { ITranslationKeys } from 'services/translation';
import ToastService from 'shared/components/Toast';
import { isNotNullOrUndefined, toArray } from 'shared/utils';
import { selectAccountId } from 'store/slices/user';

import { useAppSelector } from './redux';

const PROPERTY_NAME = 'FunctionCall';
const TRANSACTION_HASHES = 'transactionHashes';
const ERROR_CODE = 'errorCode';
const ERROR_MESSAGE = 'errorMessage';
const SUCCESS_VALUE = 'SuccessValue';

const saleMethodsName = {
  confirm: 'confirm',
  create_sale: 'create_sale',
  remove_sale: 'remove_sale',
  update_sale_dates: 'update_sale_dates',
  update_sale_distribute_token_id: 'update_sale_distribute_token_id',
  update_sale_price: 'update_sale_price',
  update_sale_claim_available: 'update_sale_claim_available',
  update_sale_refund_available: 'update_sale_refund_available',
  change_vesting: 'change_vesting',
  change_whitelist_hash: 'change_whitelist_hash',
  create_auction: 'create_auction',
  remove_auction: 'remove_auction',
  delete_future_sale: 'delete_future_sale',
  create_future_sale: 'create_future_sale',
  update_future_sale: 'update_future_sale',
  create_sale_from_future_sale: 'create_sale_from_future_sale',
};

enum TransactionType {
  None = 'None',
  CreateSale = 'CreateSale',
  RemoveSale = 'RemoveSale',
  UpdateSaleDates = 'UpdateSaleDates',
  UpdateSaleDistributeTokenId = 'UpdateSaleDistributeTokenId',
  UpdateSalePrice = 'UpdateSalePrice',
  UpdateSaleClaimAvailable = 'UpdateSaleClaimAvailable',
  UpdateSaleRefundAvailable = 'UpdateSaleRefundAvailable',
  ChangeVesting = 'ChangeVesting',
  ChangeWhitelistRootHash = 'ChangeWhitelistRootHash',
  CreateAuction = 'CreateAuction',
  RemoveAuction = 'RemoveAuction',
  DeleteFutureSale = 'DeleteFutureSale',
  CreateFutureSale = 'CreateFutureSale',
  UpdateFutureSale = 'UpdateFutureSale',
  CreateSaleFromFutureSale = 'CreateSaleFromFutureSale',
}

enum StatusType {
  None,
  SuccessValue,
  Failure,
}

const clearHash = (queryParams: URLSearchParams) => {
  const url = new URL(window.location.href);
  if (queryParams.has(TRANSACTION_HASHES)) queryParams.delete(TRANSACTION_HASHES);

  if (queryParams.has(ERROR_CODE) || queryParams.has(ERROR_MESSAGE)) {
    queryParams.delete(ERROR_CODE);
    queryParams.delete(ERROR_MESSAGE);
  }
  window.history.replaceState({}, document.title, url.pathname);
};

const retrieveTypeTransaction = (methodName: string) => {
  switch (methodName) {
    case saleMethodsName.create_sale: {
      return TransactionType.CreateSale;
    }
    case saleMethodsName.remove_sale: {
      return TransactionType.RemoveSale;
    }
    case saleMethodsName.update_sale_dates: {
      return TransactionType.UpdateSaleDates;
    }
    case saleMethodsName.update_sale_distribute_token_id: {
      return TransactionType.UpdateSaleDistributeTokenId;
    }
    case saleMethodsName.update_sale_price: {
      return TransactionType.UpdateSalePrice;
    }
    case saleMethodsName.update_sale_claim_available: {
      return TransactionType.UpdateSaleClaimAvailable;
    }
    case saleMethodsName.update_sale_refund_available: {
      return TransactionType.UpdateSaleRefundAvailable;
    }
    case saleMethodsName.change_vesting: {
      return TransactionType.ChangeVesting;
    }
    case saleMethodsName.change_whitelist_hash: {
      return TransactionType.ChangeWhitelistRootHash;
    }
    case saleMethodsName.create_auction: {
      return TransactionType.CreateAuction;
    }
    case saleMethodsName.remove_auction: {
      return TransactionType.RemoveAuction;
    }
    case saleMethodsName.delete_future_sale: {
      return TransactionType.DeleteFutureSale;
    }
    case saleMethodsName.update_future_sale: {
      return TransactionType.UpdateFutureSale;
    }
    case saleMethodsName.create_future_sale: {
      return TransactionType.CreateFutureSale;
    }
    case saleMethodsName.create_sale_from_future_sale: {
      return TransactionType.CreateSaleFromFutureSale;
    }
    default: {
      return TransactionType.None;
    }
  }
};
const detailsTransaction = (transaction: providers.FinalExecutionOutcome) => {
  const methodName = transaction.transaction.actions[0][PROPERTY_NAME].method_name as string;
  const type = retrieveTypeTransaction(methodName);
  const successStatus = Object.prototype.hasOwnProperty.call(transaction.status, SUCCESS_VALUE);
  const hash = transaction.transaction.hash as string;
  return {
    methodName,
    href: `${explorerUrl}/transactions/${hash}`,
    status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
    type,
    title: `Toast.${type}` as ITranslationKeys,
  };
};

const analyzeTransactions = (transactions: providers.FinalExecutionOutcome[]) => {
  return transactions
    .map((tx) => {
      const isSaleMethod =
        toArray(saleMethodsName).indexOf(tx.transaction.actions[0][PROPERTY_NAME].method_name) !== -1;
      return isSaleMethod ? detailsTransaction(tx) : null;
    })
    .filter(isNotNullOrUndefined);
};

export function parseTransactions(transactions: providers.FinalExecutionOutcome[], navigate: NavigateFunction) {
  const suitableTransactions = analyzeTransactions(transactions);
  if (!suitableTransactions.length) return;
  suitableTransactions.forEach((transaction) => {
    if (
      transaction.type === TransactionType.RemoveSale ||
      transaction.type === TransactionType.RemoveAuction ||
      transaction.type === TransactionType.CreateSale ||
      transaction.type === TransactionType.CreateAuction ||
      transaction.type === TransactionType.DeleteFutureSale ||
      transaction.type === TransactionType.CreateFutureSale
    ) {
      navigate(getCurrentRoute());
    }
    if (transaction.status === StatusType.SuccessValue) {
      ToastService.success(transaction.title, transaction.href);
    } else if (transaction.status === StatusType.Failure) {
      ToastService.error(transaction.title, transaction.href);
    }
  });
}

export default function useTransactionHash() {
  const accountId = useAppSelector(selectAccountId);
  const url = new URL(window.location.href);
  const navigate = useNavigate();
  return useEffect(() => {
    if (accountId) {
      const queryParams = new URLSearchParams(url.search);
      const transactions = queryParams?.get(TRANSACTION_HASHES);
      const errorCode = queryParams?.get(ERROR_CODE);
      const errorMessage = queryParams?.get(ERROR_MESSAGE);
      if (errorCode || errorMessage) {
        ToastService.error('Toast.UserRejected');
      }
      clearHash(queryParams);

      if (transactions) {
        const provider = new providers.JsonRpcProvider({ url: nodeUrl });
        try {
          Promise.all(transactions.split(',').map((txHash) => provider.txStatus(txHash, accountId)))
            .then((res) => parseTransactions(res, navigate))
            .catch((e) => console.warn(`${e} error while parse transactions`));
        } catch (e) {
          console.warn(`${e} error while loading tx`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, url.search]);
}
