import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  max-width: 46.125rem;
  min-height: auto;
  width: 100%;
  padding: 0.5rem;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  background-color: ${({ theme }) => theme.lightOrange};
  border-radius: 1rem;
`;

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  margin-right: 1rem;
  gap: 0.5rem;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.black};
`;

const Subtitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.black};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bgToken};
  border-radius: 1rem;
  height: 3.125rem;
  min-width: 3.125rem;
  & > img {
    border-radius: 1rem;
    width: 3rem;
    height: 3rem;
  }
`;

const TokenId = styled.span`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.grey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

export default {
  Container,
  DescriptionBlock,
  Title,
  Subtitle,
  LogoContainer,
  TokenId,
};
