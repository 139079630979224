import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction } from 'services/interfaces';
import { IFormattedStakingValues } from 'shared/interfaces';
import { RootState } from 'store';

export const createStaking = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    formValues: IFormattedStakingValues;
  },
  { state: RootState }
>('staking/createStaking', async ({ sendTransaction, formValues }, { getState }) => {
  try {
    const {
      services: { stakingContract },
    } = getState();
    if (!stakingContract) return;
    const action = stakingContract.createStaking(formValues);
    await sendTransaction([action]);
  } catch (error) {
    console.error('Error during createStaking: ', error);
  }
});
