import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction } from 'services/interfaces';
import { IAuction } from 'shared/interfaces';
import { RootState } from 'store';

export const updateAuctionClaimAndRefund = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    auctionId: number;
    claimAvailable: boolean;
    refundAvailable: boolean;
    auction: IAuction;
  },
  { state: RootState }
>(
  'auctions/updateAuctionClaimAndRefund',
  async ({ sendTransaction, auctionId, claimAvailable, refundAvailable, auction }, { getState }) => {
    try {
      const {
        services: { auctionContract },
      } = getState();
      if (!auctionContract) return;
      const actions: IAction[] = [];
      if (auction.claimAvailable !== claimAvailable) {
        actions.push(auctionContract.updateAuctionClaimAvailable(auctionId, claimAvailable));
      }
      if (auction.refundAvailable !== refundAvailable) {
        actions.push(auctionContract.updateAuctionRefundAvailable(auctionId, refundAvailable));
      }
      if (!actions.length) return;
      await sendTransaction(actions);
    } catch (error) {
      console.error('Error during updateAuctionClaimAndRefund: ', error);
    }
  }
);
