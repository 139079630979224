import { Button } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as BocaChicaLogo } from 'assets/images/bocachica-logo.svg';
import { ReactComponent as WalletLogo } from 'assets/images/icon/wallet.svg';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.white};
  margin: 1.5rem 2rem;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    align-self: center;
    justify-content: space-between;
    padding: 1.5rem 1rem 1.5rem;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 1.25rem;
    & > div:nth-child(2) { 
      grid-area: 2 / 1 / 3 / 3;
      justify-content: center;
    }
  `}
`;

const LogoContainer = styled.div`
  display: flex;
  z-index: 10;
  cursor: pointer;
  width: 11.875rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
  `}
`;

const StyledButton = styled(Button)`
  width: fit-content;
  gap: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: flex-end;
  `}
`;

export default {
  Header,
  LogoContainer,
  BocaChicaLogo,
  StyledButton,
  WalletLogo,
};
