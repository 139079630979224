import { getUserWalletNFTs } from 'services/api/userData';
import { NonFungibleTokenContract } from 'services/contract';
import { INFTTokenMetadata } from 'services/interfaces';
import { IRPCProviderService } from 'services/RPCProviderService';
import { DEFAULT_NFT_TOKENS_PER_PAGE } from 'shared/constants';
import { IAuction } from 'shared/interfaces';
import { isNotNullOrUndefined, onlyUniqueValues } from 'shared/utils';

import { assertFulfilled } from './index';

export function retrieveNFTTokenAddresses(auctionsResult: IAuction[]): string[] {
  return onlyUniqueValues([...auctionsResult.flatMap((auction) => auction.nftContractId)]);
}

export function createNFTContracts(
  provider: IRPCProviderService,
  nftAddresses: string[]
): { [key: string]: NonFungibleTokenContract } {
  return nftAddresses.reduce((acc, address) => {
    const contract = new NonFungibleTokenContract({
      provider,
      contractId: address,
    });
    return {
      ...acc,
      [contract.contractId]: contract,
    };
  }, {});
}

export function tryToGetContract(
  provider: IRPCProviderService,
  contracts: { [key: string]: NonFungibleTokenContract },
  contractId: string
): NonFungibleTokenContract {
  return contracts[contractId] || new NonFungibleTokenContract({ provider, contractId });
}

export async function retrieveFilteredNFTMetadata(
  provider: IRPCProviderService,
  nftAddresses: { nftAddress: string; nftId: string }[],
  contracts: { [key: string]: NonFungibleTokenContract }
): Promise<NonFungibleTokenContract[]> {
  return Promise.all(
    nftAddresses.map(async (address) => {
      const nftTokenContract = tryToGetContract(provider, contracts, address.nftAddress);
      const contractMetadata = await nftTokenContract.getNFTContractMetadata();
      await nftTokenContract.getNFTTokenMetadata(address.nftId, contractMetadata?.base_uri);
      return nftTokenContract;
    })
  );
}

export const getNFT = ({
  contractId,
  tokenId,
  nfts,
  userNFTs,
}: {
  contractId: string;
  tokenId: string;
  nfts: { [key: string]: NonFungibleTokenContract };
  userNFTs?: { [key: string]: NonFungibleTokenContract };
}) => {
  let contract: NonFungibleTokenContract | null = nfts[contractId] || null;
  let tokenMetadata: INFTTokenMetadata | null = contract?.tokenMetadata[tokenId] || null;
  if (!tokenMetadata) {
    contract = userNFTs?.[contractId] || null;
    tokenMetadata = contract?.tokenMetadata[tokenId] || null;
  }
  return { tokenMetadata, contract };
};

export async function retrieveUserNFTsResult(
  pages: number,
  contract: NonFungibleTokenContract,
  accountId: string,
  baseUri: string | null | undefined
) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) =>
        contract.getTokens({
          accountId,
          baseUri,
          fromIndex: i * DEFAULT_NFT_TOKENS_PER_PAGE,
        })
      )
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveUserNFTs(
  accountId: string,
  provider: IRPCProviderService,
  auctionNfts: { [key: string]: NonFungibleTokenContract }
): Promise<{ [key: string]: NonFungibleTokenContract }> {
  try {
    const userContractNFTs = await getUserWalletNFTs(accountId);
    if (!userContractNFTs.length) return {};
    const nonFungibleTokens = await Promise.all(
      userContractNFTs.map(async (contractId) => {
        const nftContract = new NonFungibleTokenContract({ contractId, provider });
        const length = await nftContract.nftSupplyForOwner(accountId);
        const pages = length ? Math.ceil(length / DEFAULT_NFT_TOKENS_PER_PAGE) : 0;
        const contractMetadata = await nftContract.getNFTContractMetadata();
        await retrieveUserNFTsResult(pages, nftContract, accountId, contractMetadata?.base_uri);
        return nftContract;
      })
    );
    const filteredUserNFT = nonFungibleTokens
      .map((nft) => {
        const identicalContracts: NonFungibleTokenContract | null = auctionNfts[nft.contractId] || null;
        if (!identicalContracts) return nft;
        const nftTokens = Object.values(nft.tokenMetadata);
        const auctionNFTs = identicalContracts.tokenMetadata;
        const filteredMetadata = nftTokens.filter((token) => !auctionNFTs[token.token_id]);
        if (!filteredMetadata.length) return null;
        const tokenMetadata = filteredMetadata.reduce((acc, curr) => ({ ...acc, [curr.token_id]: curr }), {});
        return { ...nft, tokenMetadata };
      })
      .filter(isNotNullOrUndefined);
    const nftMetadataMap = filteredUserNFT.reduce((acc, curr) => ({ ...acc, [curr.contractId]: curr }), {});
    return nftMetadataMap;
  } catch (error) {
    console.warn(`Error: ${error} \n while retrieveUserNFTs from contractId: ${accountId}`);
    return {};
  }
}
