import { t } from 'i18next';

import { useStakingProvider } from 'providers/LocalProviders/StakingProvider';
import { ITokenMetadata } from 'services/interfaces';
import { useAppSelector } from 'shared/hooks/redux';
import colors from 'shared/theme/colors';
import { displayAmount, displayAmountWithSpace } from 'shared/utils';
import { selectStaking } from 'store/slices/staking';

import { parseDateInSeconds } from '../StakingCard/utils';
import styles from './styles';
import calculatedStakingStatistics from './utils';

export default function Statistics({ metadata }: { metadata: ITokenMetadata }) {
  const { decimals, symbol } = metadata;
  const staking = useAppSelector(selectStaking);
  const { stakingInputs } = useStakingProvider();
  const updatedData = calculatedStakingStatistics(staking, stakingInputs, decimals);
  if (!updatedData) return null;
  const stakedInfo = [
    {
      title: t('Staking.TotalStaked'),
      value: t('TokenAmount', {
        amount: displayAmountWithSpace({
          amount: staking.totalStaked,
          decimals,
          precision: 0,
        }),
        symbol,
      }),
    },
    {
      title: t('Staking.TotalReward'),
      value: t('TokenAmount', {
        amount: displayAmountWithSpace({
          amount: updatedData.totalReward.value,
          decimals,
          precision: 0,
        }),
        symbol,
      }),
      color: updatedData.totalReward.isChanged ? colors.orange : undefined,
    },
    {
      title: t('Staking.Apy.Title'),
      value: updatedData.apy.isChanged
        ? t('Staking.Apy.NewApy', { apy: staking.apy, newAPY: updatedData.apy.value })
        : t('Staking.Apy.Value', { apy: staking.apy }),
      color: updatedData.apy.isChanged ? colors.orange : undefined,
    },
    {
      title: t('Staking.ClaimedReward'),
      value: t('TokenAmount', {
        amount: displayAmountWithSpace({
          amount: staking.claimedReward,
          decimals,
          precision: 0,
        }),
        symbol,
      }),
    },
    {
      title: t('Staking.UnclaimedReward'),
      value: t('TokenAmount', {
        amount: displayAmountWithSpace({
          amount: staking.unclaimedReward,
          decimals,
          precision: 0,
        }),
        symbol,
      }),
    },
    {
      title: t('Staking.RewardPerSeconds'),
      value: t('TokenAmount', { amount: displayAmount(updatedData.rewardPerSeconds.value, decimals), symbol }),
      color: updatedData.rewardPerSeconds.isChanged ? colors.orange : undefined,
    },
  ];
  return (
    <styles.Wrapper>
      <styles.Row>
        {stakedInfo.slice(0, 3).map(({ title, value, color }) => (
          <div key={title}>
            <styles.ElementTitle>{title}</styles.ElementTitle>
            <styles.ElementValue color={color}>{value}</styles.ElementValue>
          </div>
        ))}
      </styles.Row>
      <styles.Time isDynamicEndDate={updatedData.endDate.isChanged}>
        {parseDateInSeconds(staking.startAt)}
        {'\xa0 \u2014 \xa0'}
        <span>{parseDateInSeconds(updatedData.endDate.value)}</span>
      </styles.Time>
      <styles.Row>
        {stakedInfo.slice(3).map(({ title, value, color }) => (
          <div key={title}>
            <styles.ElementTitle>{title}</styles.ElementTitle>
            <styles.ElementValue color={color}>{value}</styles.ElementValue>
          </div>
        ))}
      </styles.Row>
    </styles.Wrapper>
  );
}
