import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction, AuctionMetadata } from 'services/interfaces';
import { RootState } from 'store';

export const updateAuctionMetadata = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    auctionId: number;
    metadata: AuctionMetadata;
  },
  { state: RootState }
>('auctions/updateAuctionMetadata', async ({ sendTransaction, auctionId, metadata }, { getState }) => {
  try {
    const {
      services: { auctionContract },
    } = getState();
    if (!auctionContract) return;
    const action = auctionContract.updateAuctionMetadata(auctionId, metadata);
    await sendTransaction([action]);
  } catch (error) {
    console.error('Error during updateAuctionMetadata: ', error);
  }
});
