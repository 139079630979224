import styled from 'styled-components';

const WhitelistWrapper = styled.div`
  width: 45rem;
  padding: 1rem;
  max-width: 100%;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  margin: 3rem auto 0 auto;
  box-shadow: 0 0 0 3px transparent;
  color: ${({ theme }) => theme.black};
  background: ${({ theme }) => theme.white};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`;

const Description = styled.div`
  line-height: 140%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.lightGrey};
`;

const AddressesWrapper = styled.div`
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.lightOrange};
`;

const AddressesTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.375rem;
`;

const AddressItem = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.063rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  color: ${({ theme }) => theme.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.orangeDivision};
`;

const RootHash = styled.div`
  display: flex;
  padding: 0.75rem;
  align-items: center;
  border-radius: 1rem;
  margin: 3rem 0 1.5rem 0;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.orangeDivision};

  svg {
    width: 1.5rem;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.orangeAlt};
    }
  }

  div {
    width: 93%;
  }
`;

const ButtonGroup = styled.div`
  gap: 1rem;
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    button,label {
        width: 100%;
    }
  `}
`;

const DownloadLeaves = styled.div`
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;

    label {
        width: 100%;
    }
  `}
`;

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.375rem;
  color: ${({ theme }) => theme.gray};
`;

const Value = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  word-break: break-all;
`;

const FileData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export default {
  WhitelistWrapper,
  Title,
  Description,
  AddressesWrapper,
  AddressesTitle,
  AddressItem,
  RootHash,
  ButtonGroup,
  DownloadLeaves,
  Label,
  Value,
  FileData,
};
