import { AuctionContract } from 'services/contract';
import { IAuctionOutput } from 'services/interfaces';
import { DEFAULT_PAGE_LIMIT } from 'shared/constants';
import { formatAuction, isNotNullOrUndefined } from 'shared/utils';

import { assertFulfilled } from './index';

export async function retrieveAuctionResult(pages: number, contract: AuctionContract) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) => contract.getAuctions(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT))
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveAuctionArray(auctionContract: AuctionContract) {
  const auctionsLength = await auctionContract.getNumberOfAuctions();
  const pages = auctionsLength ? Math.ceil(auctionsLength / DEFAULT_PAGE_LIMIT) : 0;
  const auctionsResult = await retrieveAuctionResult(pages, auctionContract);
  const auctionArray = auctionsResult
    .filter(isNotNullOrUndefined)
    .map((auction: IAuctionOutput) => formatAuction(auction));
  return auctionArray;
}

export async function retrieveParticipantsResult(pages: number, contract: AuctionContract, auctionId: number) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) =>
        contract.getAuctionAccounts(auctionId, i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)
      )
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveParticipants(auctionContract: AuctionContract, auctionId: number) {
  const participantsLength = await auctionContract.getNumberOfParticipantsAuction(auctionId);
  if (!participantsLength) return null;
  const pages = Math.ceil(participantsLength / DEFAULT_PAGE_LIMIT);
  const participants = await retrieveParticipantsResult(pages, auctionContract, auctionId);
  return { arr: participants, length: participantsLength };
}
