import { FormikProps, useFormik } from 'formik';
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useWalletData } from 'providers/WalletProvider';
import { FungibleTokenContract } from 'services/contract';
import { EMPTY_STRING } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { IFormStakingValues } from 'shared/interfaces';
import { toMap, validationCreateStakingSchema } from 'shared/utils';
import { formatStakingValues, initialStakingValues } from 'shared/utils/staking';
import { createStaking } from 'store/actions/staking/createStaking';
import { selectStaking } from 'store/slices/staking';

export interface IStakeInput {
  id: number;
  depositAmount: string;
}

const farmsInputArray = (lengthFarms: number) =>
  Array.from({ length: lengthFarms }, (_, index) => ({ id: index, depositAmount: EMPTY_STRING }));

export interface ICreateStakingValues {
  minDeposit: string;
  startAt: string;
  sessionInterval: string;
  distributionInDay: string;
  distributionAmount: string;
}

interface StakingContextType {
  stakingInputs: { [key: number]: IStakeInput };
  setStakingInputs: Dispatch<SetStateAction<{ [key: number]: IStakeInput }>>;
  createStakingForm: FormikProps<IFormStakingValues>;
}

const StakingContextHOC = createContext<StakingContextType>({} as StakingContextType);

export function StakingProvider({ children, token }: { children: JSX.Element; token: FungibleTokenContract }) {
  const staking = useAppSelector(selectStaking);
  const dispatch = useAppDispatch();
  const { sendTransaction } = useWalletData();
  const [stakingInputs, setStakingInputs] = useState<{ [key: number]: IStakeInput }>(
    toMap(farmsInputArray(staking.farms.arr.length), 'id')
  );

  const submitCreateStakingForm = useCallback(
    async (values: IFormStakingValues) => {
      if (!token || !token.metadata) return;
      const rewardToken = token?.contractId;
      const formattedValues = formatStakingValues(values, token.metadata, rewardToken);
      await dispatch(createStaking({ sendTransaction, formValues: formattedValues }));
    },
    [token, dispatch, sendTransaction]
  );
  const createStakingForm = useFormik<IFormStakingValues>({
    initialValues: initialStakingValues,
    onSubmit: submitCreateStakingForm,
    validationSchema: validationCreateStakingSchema,
  });

  const data = useMemo(
    () => ({
      stakingInputs,
      setStakingInputs,
      createStakingForm,
    }),
    [stakingInputs, createStakingForm]
  );
  return <StakingContextHOC.Provider value={data}>{children}</StakingContextHOC.Provider>;
}

export const useStakingProvider = () => useContext(StakingContextHOC);
