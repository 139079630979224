import { Autocomplete, FormControl, FormHelperText } from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { ISelect, ITextAreaInput, ITextInput } from 'shared/interfaces';

import styles from './styles';

export function TextInput({ ...props }: ITextInput) {
  const [field, meta] = useField(props);
  return (
    <>
      <styles.TextInput
        {...field}
        {...props}
        autoComplete="off"
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error}
        onWheel={(e: any) => e.target!.blur()}
      />
      {props.optionList && (
        <datalist id={props.name}>
          {props.optionList.map(({ title, value }) => (
            <option key={title} value={value}>
              {title}
            </option>
          ))}
        </datalist>
      )}
    </>
  );
}

export function Select({ ...props }: ISelect) {
  const [field, meta] = useField(props);
  return (
    <FormControl error={Boolean(meta.touched && meta.error)}>
      <styles.StyledSelect {...field} {...props} />
      {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

export function TextAreaInput({ ...props }: ITextAreaInput) {
  const [field, meta] = useField(props);
  return (
    <>
      <styles.TextArea {...field} {...props} />
      {meta.touched && meta.error ? <styles.ErrorBlock>{meta.error}</styles.ErrorBlock> : null}
    </>
  );
}

export interface IAutocompleteInput {
  name: string;
  label: string;
  type: string;
  value: string;
  placeholder: string;
  optionList: {
    title: string;
    value: string;
  }[];
}

export function AutocompleteInput({ optionList, ...props }: IAutocompleteInput) {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  return (
    <Autocomplete
      freeSolo
      value={props.value}
      options={optionList.map(({ title }) => title)}
      onChange={(e) => {
        const symbol = e.currentTarget.textContent;
        const selectedOption = optionList.find(({ title }) => title === symbol);
        setFieldValue(props.name, selectedOption ? selectedOption.value : symbol);
      }}
      renderInput={(params) => (
        <styles.TextInput
          {...params}
          {...field}
          {...props}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
}
