export interface IAdminValues {
  claimAvailable: boolean;
  refundAvailable: boolean;
  startDate: string;
  endDate: string;
  projectLink?: string;
  twitterLink?: string;
  mediumLink?: string;
  telegramLink?: string;
  whitelistRootHash?: string;
}

export enum EAuctionMethod {
  REMOVE_SALE,
  UPDATE_SALE_DATES,
  UPDATE_SALE_CLAIM_AND_REFUND,
  UPDATE_METADATA,
}
