import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction } from 'services/interfaces';
import { RootState } from 'store';

export const cancelSale = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    saleId: number;
  },
  { state: RootState }
>('sales/cancelSale', async ({ sendTransaction, saleId }, { getState }) => {
  try {
    const {
      services: { saleContract },
    } = getState();
    if (!saleContract) return;
    const action = saleContract.cancelSale(saleId);
    await sendTransaction([action]);
  } catch (error) {
    console.error('Error during cancelSale: ', error);
  }
});
