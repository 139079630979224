import Big from 'big.js';
import dayjs from 'dayjs';

import { stakingSeedId } from 'services/config';
import { EStakingStatus } from 'services/interfaces';
import { ZERO_STR, SECONDS_IN_A_DAY, DAYS_A_YEAR, ONE_HUNDRED } from 'shared/constants';
import { IFormStakingValues, IFormattedStaking } from 'shared/interfaces';
import { millisecondsToSeconds, calcRewardPerSession, parseTokenAmount, getStakingStatus } from 'shared/utils';

const convertStakingValuesForm = (
  createStakingForm: IFormStakingValues,
  id: number,
  decimals: number,
  rewardTokenId: string,
  totalStaked: string
): IFormattedStaking | null => {
  if (
    !createStakingForm.distributionAmount ||
    !createStakingForm.distributionInDay ||
    !createStakingForm.sessionInterval ||
    !createStakingForm.startAt
  )
    return null;

  const startAtInMilliseconds = dayjs(createStakingForm.startAt).add(dayjs().utcOffset(), 'minutes').valueOf();
  const startAt = millisecondsToSeconds(startAtInMilliseconds);
  const { rewardPerSession } = calcRewardPerSession(
    createStakingForm.distributionInDay,
    createStakingForm.distributionAmount,
    createStakingForm.sessionInterval
  );

  const parseRewardPerSession = parseTokenAmount(rewardPerSession, decimals);
  const rewardPerSeconds = Big(parseRewardPerSession).div(createStakingForm.sessionInterval).toFixed();
  const parseTotalReward = parseTokenAmount(createStakingForm.distributionAmount, decimals);
  const stakingDuration = Big(parseTotalReward).div(rewardPerSeconds).toNumber();
  const endAt = startAt + stakingDuration;
  const statusFarm = getStakingStatus(EStakingStatus.Created, startAt, endAt);
  let apy = ZERO_STR;
  if (Big(totalStaked).gt(ZERO_STR)) {
    const rewardForYear = Big(rewardPerSeconds).times(SECONDS_IN_A_DAY).times(DAYS_A_YEAR).toFixed();
    apy = Big(rewardForYear).div(totalStaked).times(ONE_HUNDRED).toFixed(2);
  }
  return {
    id: Number(id),
    stakeId: `${stakingSeedId}#${id}`,
    type: 'SIMPLE_FARM',
    status: statusFarm,
    seedId: stakingSeedId,
    rewardTokenId,
    startAt,
    endAt,
    rewardPerSession: parseRewardPerSession,
    sessionInterval: Number(createStakingForm.sessionInterval),
    totalReward: parseTotalReward,
    currentRound: 0,
    lastRound: 0,
    claimedReward: ZERO_STR,
    unclaimedReward: ZERO_STR,
    beneficiaryReward: ZERO_STR,
    rewardPerSeconds,
    apy,
  };
};

export default convertStakingValuesForm;
