import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { auctionContractId } from 'services/config';
import { FungibleTokenContract } from 'services/contract';
import { IAction, AuctionInput } from 'services/interfaces';
import { RootState } from 'store';

export const createAuction = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    auction: AuctionInput;
    token: FungibleTokenContract;
  },
  { state: RootState }
>('auctions/createAuction', async ({ sendTransaction, auction, token }, { getState }) => {
  try {
    const {
      services: { auctionContract },
    } = getState();
    if (!auctionContract) return;
    const actions: IAction[] = [];
    const storageDeposit = await token.checkStorageBalance({ accountId: auctionContractId });
    if (storageDeposit) actions.push(storageDeposit);
    const createdAuctionTransaction = auctionContract.createAuction(auction);
    actions.push(createdAuctionTransaction);
    await sendTransaction(actions);
  } catch (error) {
    console.error('Error during createAuction: ', error);
  }
});
