import { walletId } from 'services/config';
import { EFromValues, ISocialNetwork } from 'shared/interfaces';

export const social: ISocialNetwork[] = [
  {
    label: 'CreateSale.ProjectSite.Label',
    type: 'text',
    name: EFromValues.projectSite,
    placeholder: 'CreateSale.ProjectSite.Placeholder',
  },
  {
    label: 'CreateSale.Telegram.Label',
    type: 'text',
    name: EFromValues.socialTg,
    placeholder: 'CreateSale.Telegram.Placeholder',
  },
  {
    label: 'CreateSale.Twitter.Label',
    type: 'text',
    name: EFromValues.socialTwitter,
    placeholder: 'CreateSale.Twitter.Placeholder',
  },
  {
    label: 'CreateSale.Medium.Label',
    type: 'text',
    name: EFromValues.socialMedium,
    placeholder: 'CreateSale.Medium.Placeholder',
  },
];

export const DEFAULT_PAGE_LIMIT = 100;
export const DEFAULT_NFT_TOKENS_PER_PAGE = 4;
export const ONE_YOCTO_NEAR = '0.000000000000000000000001';
export const DEFAULT_DECIMALS = 18;
export const STORAGE_TO_CREATE_FARM = '0.01';
export const STORAGE_TO_REGISTER_FT = '0.1';
export const STORAGE_TO_REGISTER_WNEAR = '0.00125';
export const ONE_HUNDRED = 100;
export const MICROSECOND = 1000000;
export const VALID_VALUE_CONTRACT_ID = `.${walletId}`;
export const ZERO = 0;
export const ZERO_STR = '0';
export const DASH = '-';

export const NEAR_TOKEN_ID = 'near';
export const NEAR_DECIMALS = 24;

export const ONE_SECOND = 1000;
export const MICRO_SECOND = 1000 * ONE_SECOND;
export const UPDATE_AUCTION_INTERVAL = 3 * ONE_SECOND;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const DAYS_A_YEAR = 365;
export const SECONDS_IN_A_DAY = 86400;

export const DECIMAL_PREFIX_KILO = 1000;

export const TIMESTAMP = {
  ONE_DAY: 60 * 60 * 24,
  ONE_HOUR: 60 * 60,
  ONE_MINUTE: 60,
};
export const radiusProgressCircle = 7.335;

export const ERROR_DATE = 'Invalid Date';

export enum EDimensions {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  UNKNOWN = 'UNKNOWN',
}

export const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 768,
  upToLarge: 1280,
};
export const EMPTY_STRING = '';
export const VESTING_PRECISION = 3;

export const MILI_IN_NANO = 6;
export const BASE = 10;
