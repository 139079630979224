import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { stakingSeedId } from 'services/config';
import { ZERO_STR } from 'shared/constants';
import { IStaking } from 'shared/interfaces';
import { RootState } from 'store';

export const initialStakingData: IStaking = {
  rewardTokenId: stakingSeedId,
  startAt: 0,
  endAt: 0,
  rewardPerSeconds: ZERO_STR,
  apy: ZERO_STR,
  totalStaked: ZERO_STR,
  totalReward: ZERO_STR,
  claimedReward: ZERO_STR,
  unclaimedReward: ZERO_STR,
  farms: {
    obj: {},
    arr: [],
  },
};

type StakingState = { data: IStaking; loading: boolean };

const initialState: StakingState = {
  data: initialStakingData,
  loading: true,
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    setStaking(state, action: PayloadAction<IStaking>) {
      return {
        ...state,
        data: action.payload,
      };
    },
    setStakingLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

// SELECTORS
export const selectStaking = (state: RootState) => state.staking.data;
export const selectStakingLoading = (state: RootState) => state.staking.loading;

// ACTIONS
export const { setStakingLoading, setStaking } = stakingSlice.actions;

export const stakingReducer = stakingSlice.reducer;
