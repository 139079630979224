import { ESaleType } from 'services/interfaces';
import { EFromValues, IValues } from 'shared/interfaces';
import { isNullOrEmpty } from 'shared/utils';

import { Select, TextInput } from '../Input/FormikInput';
import { translate, Translate } from '../Translate';
import styles from './styles';

interface IRoundInformation {
  values: IValues;
}

export default function RoundInformation({ values }: IRoundInformation) {
  const depositTokenId = values.depositToken?.contractId || '';
  const symbol = values.depositToken?.metadata ? values.depositToken.metadata.symbol : depositTokenId.toUpperCase();
  const projectSymbol = values.distributeToken?.metadata?.symbol || 'CHICA';
  const depositTokenSymbol = isNullOrEmpty(symbol) ? 'NEAR' : symbol;
  const depositTokenPrice = isNullOrEmpty(values.price) ? '1' : values.price;

  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateSale.RoundInformation" />
      </styles.Title>
      <styles.ChildWrapper>
        <Select name={EFromValues.roundType}>
          <option value={ESaleType.Empty}>Select a round type</option>
          <option value={ESaleType.ByAmount}>Amount</option>
          <option value={ESaleType.BySubscription}>Subscription</option>
        </Select>
        <TextInput
          label={translate({ value: 'CreateSale.MaxAmount.Label' })}
          name={EFromValues.maxAmount}
          type="number"
          placeholder={translate({
            value: 'CreateSale.MaxAmount.Placeholder',
            interpolation: { symbol },
          })}
        />
        <TextInput
          label={translate({ value: 'CreateSale.MinAllocation.Label' })}
          name={EFromValues.minAllocation}
          type="number"
          placeholder={translate({
            value: 'CreateSale.MinAllocation.Placeholder',
            interpolation: { symbol },
          })}
        />
        <TextInput
          label={translate({ value: 'CreateSale.MaxAllocation.Label' })}
          name={EFromValues.maxAllocation}
          type="number"
          placeholder={translate({
            value: 'CreateSale.MaxAllocation.Placeholder',
            interpolation: { symbol },
          })}
        />
        <TextInput
          label={translate({ value: 'CreateSale.LimitPerTransaction.Label' })}
          name={EFromValues.limitPerTransaction}
          type="number"
          placeholder={translate({
            value: 'CreateSale.LimitPerTransaction.Placeholder',
            interpolation: { symbol },
          })}
        />
        <TextInput
          label={translate({
            value: 'CreateSale.Price.Label',
            interpolation: {
              projectSymbol: projectSymbol.toUpperCase(),
              depositTokenPrice,
              depositTokenSymbol,
            },
          })}
          name={EFromValues.price}
          type="number"
          placeholder={translate({ value: 'CreateSale.Price.Placeholder' })}
        />
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
