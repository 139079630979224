import { ITranslationKeys } from 'services/translation';

import { translate } from '../Translate';
import styles from './styles';

interface ITextInput {
  name: string;
  label: ITranslationKeys;
  type?: string;
  value?: string;
  placeholder: ITranslationKeys;
  min?: string;
  error?: boolean;
  handleChange: any;
  disabled?: boolean;
}

export default function TextInput({ handleChange, ...props }: ITextInput) {
  return (
    <styles.TextInput
      {...props}
      label={translate({ value: props.label })}
      placeholder={translate({ value: props.placeholder })}
      autoComplete="off"
      onChange={handleChange}
      onWheel={(e: any) => e.target!.blur()}
    />
  );
}
