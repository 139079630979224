import { EStatus, ISale } from 'shared/interfaces';
import { detectActiveVesting, getVestingDetails } from 'shared/utils';

import Timer from '../Timer';

interface IHelperSection {
  sale: ISale;
  status: EStatus;
  totalClaimAvailable: string;
}

export default function HelperSection({ sale, status, totalClaimAvailable }: IHelperSection) {
  switch (status) {
    case EStatus.Open: {
      return <Timer status={status} time={sale.endDate} />;
    }
    case EStatus.Soon: {
      return <Timer status={status} time={sale.startDate} />;
    }
    case EStatus.Closed: {
      if (sale.cliff?.active) {
        return <Timer time={sale.cliff.timestamp} isDistributionIn={sale.cliff.active} />;
      }

      if (!sale.vesting || !sale.claimAvailable) return null;
      const userDataByVesting = getVestingDetails(sale.vesting, sale.vestingType, totalClaimAvailable);
      const activeVesting = detectActiveVesting(userDataByVesting.array, sale.vestingType);
      if (!activeVesting) return null;
      return <Timer time={activeVesting.date} vestingType={sale.vestingType} />;
    }

    default: {
      return null;
    }
  }
}
