import { FungibleTokenContract } from 'services/contract';
import { ESaleType, VestingOutput } from 'services/interfaces';

import { ETypeVesting } from './sale';

export interface IValues {
  projectName: string;
  projectSite: string;
  description: string;
  distributeTokenId: string;
  distributeToken: FungibleTokenContract | null;
  depositTokenId: string;
  depositToken: FungibleTokenContract | null;

  maxAmount: string;

  minAllocation: string;
  maxAllocation: string;
  limitPerTransaction: string;
  roundType: ESaleType;
  price: string;

  roundStartDate: string;
  roundFinishDate: string;

  socialTg: string;
  socialTwitter: string;
  socialMedium: string;

  vestingType: ETypeVesting;
  vesting?: Array<VestingOutput>;

  whitelistRootHash: string | null;
}

export enum EFromValues {
  projectName = 'projectName',
  projectSite = 'projectSite',
  distributeToken = 'distributeToken',
  distributeTokenId = 'distributeTokenId',
  distributeTokenDecimals = 'distributeTokenDecimals',
  description = 'description',
  depositToken = 'depositToken',
  depositTokenId = 'depositTokenId',

  maxAmount = 'maxAmount',
  minAllocation = 'minAllocation',
  maxAllocation = 'maxAllocation',
  limitPerTransaction = 'limitPerTransaction',
  roundType = 'roundType',
  price = 'price',

  roundStartDate = 'roundStartDate',
  roundFinishDate = 'roundFinishDate',

  socialTg = 'socialTg',
  socialTwitter = 'socialTwitter',
  socialMedium = 'socialMedium',

  vestingType = 'vestingType',
  vesting = 'vesting',

  whitelistRootHash = 'whitelistRootHash',

  // staking
  rewardToken = 'rewardToken',
  poolId = 'poolId',
  minDeposit = 'minDeposit',
  startAt = 'startAt',
  sessionInterval = 'sessionInterval',
  distributionInDay = 'distributionInDay',
  distributionAmount = 'distributionAmount',
  farmIndex = 'farmIndex',
}

export interface IAdminValues {
  claimAvailable: boolean;
  refundAvailable: boolean;
  newPrice: string;
  newMaxAmount: string;
  distributeTokenId: string;
  newStartDate: string;
  newEndDate: string;

  vestingType: ETypeVesting;
  vesting: Array<VestingOutput>;
  whitelistRootHash: string;
}

export enum ESaleMethod {
  REMOVE_SALE,
  UPDATE_SALE_DATES,
  UPDATE_SALE_DISTRIBUTE_TOKEN_ID,
  UPDATE_SALE_PRICE,
  UPDATE_SALE_CLAIM_AND_REFUND,
  DOWNLOAD_SALE_ACCOUNTS,
  CHANGE_VESTING,
  CANCEL_SALE,
  CHANGE_WHITELIST_HASH,
}
