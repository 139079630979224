import styled from 'styled-components';

import { ReactComponent as Minus } from 'assets/images/icon/minus.svg';
import { ReactComponent as Plus } from 'assets/images/icon/plus.svg';
import { EStakingStatus } from 'services/interfaces';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.lightOrange};
  max-width: 46rem;
  width: 100%;
  border-radius: 1.5rem;
  margin: 0;
  padding: 0;
  min-height: 10rem;
  ::before {
    border-radius: 1.5rem;
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  min-height: 10rem;
  padding: 1.125rem 1.5rem 1.5rem;
  border-radius: 1.5rem;
`;

const Time = styled.div<{ isDynamicEndDate?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  background-color: ${({ theme }) => theme.lightOrange};
  border-radius: 0 0 1.5rem 1.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.black};
  span {
    color: ${({ isDynamicEndDate, theme }) => (isDynamicEndDate ? theme.orange : theme.black)};
  }
`;

const Status = styled.div<{ type: EStakingStatus }>`
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid
    ${({ theme, type }) => {
      if (type === EStakingStatus.Running) return theme.statusOpenText;
      if (type === EStakingStatus.Created) return theme.statusSoonText;
      return theme.statusEndedText;
    }};

  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme, type }) => {
    if (type === EStakingStatus.Running) return theme.statusOpenText;
    if (type === EStakingStatus.Created) return theme.statusSoonText;
    return theme.statusEndedText;
  }};
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  max-height: 22px;
  padding: 0.25rem;
`;

const UpperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    color: ${({ theme }) => theme.black};
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0.5rem;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    :first-child,
    :nth-child(3n + 1) {
      align-items: flex-start;
    }
    :nth-child(3n + 2) {
      align-items: flex-end;
    }
    :nth-child(3n) {
      align-items: center;
      grid-area: 2 / 1 / 3 / 3;
    }
  }
`;

const ElementTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.gray};
`;

const ElementValue = styled.div<{ color?: string }>`
  color: ${({ theme, color }) => color || theme.black};
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
`;

const Button = styled.button`
  outline: none;
  border: none;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.orangeDivision};
  :hover {
    background-color: ${({ theme }) => theme.lightOrange};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const StyledInput = styled.input<{ error?: boolean }>`
  font-size: 1rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  color: ${({ theme }) => theme.black};
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${({ error, theme }) => (error ? theme.error : theme.greyOp04)};
  border-radius: 0.75rem;
  :focus-within {
    border: 1px solid ${({ theme }) => theme.orange};
  }
  ::placeholder {
    color: ${({ theme }) => theme.greyOp04};
  }
`;

const Error = styled.div`
  color: ${({ theme }) => theme.error};
  left: 1rem;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-top: 0.5rem;
`;

export default {
  Wrapper,
  Container,
  Time,
  Status,
  UpperRow,
  Table,
  ElementTitle,
  ElementValue,
  Button,
  Plus,
  Minus,
  StyledInput,
  Error,
};
