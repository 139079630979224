import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FungibleTokenContract } from 'services/contract';
import { RootState } from 'store';

interface ITokensState {
  obj: { [key: string]: FungibleTokenContract };
  loading: boolean;
}

const initialState: ITokensState = {
  obj: {},
  loading: true,
};

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<{ [key: string]: FungibleTokenContract }>) => {
      return {
        ...state,
        obj: action.payload,
      };
    },
    setTokensLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

// SELECTORS
export const selectTokens = (state: RootState) => state.tokens.obj;
export const selectTokensLoading = (state: RootState) => state.tokens.loading;

// ACTIONS
export const { setTokens, setTokensLoading } = tokensSlice.actions;

export const tokensReducer = tokensSlice.reducer;
