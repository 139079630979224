import { ITranslationKeys } from 'services/translation';

export interface ISocialNetwork {
  label: ITranslationKeys;
  type: string;
  name: string;
  placeholder: ITranslationKeys;
}

export interface ITextInput {
  key?: string;
  name: string;
  label: string;
  type?: string;
  value?: string;
  placeholder: string;
  optionList?: { title: string; value: string }[];
}

export interface ISelect {
  name: string;
  children: JSX.Element[];
}

export interface ITextAreaInput {
  name: string;
  placeholder: string;
}

export enum EStatus {
  Open = 'Open',
  Soon = 'Soon',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
  Future = 'Future',
}

export interface ITimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export interface IGetTimeAndStatus {
  calcTime: ITimeLeft;
  calcStatus: EStatus;
}

export interface IPeriod {
  percent: string;
  startPeriod?: string;
  endPeriod: string;
  steps: string;
}

export enum EPeriodsErrors {
  invalidDistributionDate = 'invalidDistributionDate',
  invalidPercentAmount = 'invalidPercentAmount',
  invalidPeriods = 'invalidPeriods',
}

export const ErrorsPeriodsMap: { [key: string]: ITranslationKeys } = {
  [EPeriodsErrors.invalidDistributionDate]: 'Vesting.Errors.InvalidDate.',
  [EPeriodsErrors.invalidPercentAmount]: 'Vesting.Errors.Sum',
  [EPeriodsErrors.invalidPeriods]: 'Vesting.Errors.InvalidPeriods',
};

export * from './auction';
export * from './form';
export * from './sale';
export * from './staking';
