import { ListItemButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Fragment } from 'react';

import { Translate, translate } from 'shared/components/Translate';
import { useAppSelector } from 'shared/hooks/redux';
import { selectUserNfts } from 'store/slices/user';

interface NFTDialogListProps {
  isOpen: boolean;
  onClose: (tokenData: { contractId: string; tokenId: string } | null) => void;
}

export default function NFTDialogList({ onClose, isOpen }: NFTDialogListProps) {
  const userNFTs = useAppSelector(selectUserNfts);

  const handleClose = () => {
    onClose(null);
  };

  const handleListItemClick = (tokenData: { contractId: string; tokenId: string }) => {
    onClose(tokenData);
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Translate value="CreateAuction.NFTList" />
      </DialogTitle>
      <List
        sx={{
          position: 'relative',
          '& ul': { padding: 0 },
        }}
        subheader={<li />}
      >
        {Object.values(userNFTs).map(({ contractId, tokenMetadata }) => (
          <Fragment key={contractId}>
            <ListSubheader>{contractId}</ListSubheader>
            {Object.values(tokenMetadata).map((nft) => (
              <ListItemButton
                style={{ gap: '1rem' }}
                onClick={() => handleListItemClick({ contractId, tokenId: nft.token_id })}
                key={nft.token_id}
              >
                <ListItemAvatar>
                  <Avatar sx={{ width: '6.25rem', height: '6.25rem' }} src={nft.metadata.media} />
                </ListItemAvatar>
                <ListItemText
                  style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                  primary={nft.metadata.title}
                  secondary={translate({ value: 'CreateAuction.TokenId', interpolation: { tokenId: nft.token_id } })}
                />
              </ListItemButton>
            ))}
          </Fragment>
        ))}
      </List>
    </Dialog>
  );
}
