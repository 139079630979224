import { LoadingButton } from '@mui/lab';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';

import { FungibleTokenContract } from 'services/contract';
import { ITranslationKeys } from 'services/translation';
import { useAppSelector } from 'shared/hooks/redux';
import { retrieveMetadataToken } from 'store/actions/helpers';
import { selectProvider } from 'store/slices/services';
import { selectTokens } from 'store/slices/tokens';

import { AutocompleteInput } from '../Input/FormikInput';
import { Translate, translate } from '../Translate';
import styles from './styles';

export default function TokenBlock({
  value,
  label,
  valueName,
  tokenName,
  placeholder,
  optionList,
  token,
}: {
  value: string;
  label: ITranslationKeys;
  valueName: string;
  tokenName: string;
  placeholder: ITranslationKeys;
  optionList: {
    title: string;
    value: string;
  }[];
  token: FungibleTokenContract | null;
}) {
  const provider = useAppSelector(selectProvider);
  const tokens = useAppSelector(selectTokens);

  const { setFieldValue } = useFormikContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingTokenFailed, setIsLoadingTokenFailed] = useState<boolean>(false);
  const metadata = token?.metadata;

  const handleClick = useCallback(async () => {
    setLoading(true);
    const retrievedToken = await retrieveMetadataToken(value, provider, tokens, setLoading, setIsLoadingTokenFailed);
    setFieldValue(tokenName, retrievedToken);
  }, [provider, tokenName, setFieldValue, tokens, value]);

  useEffect(() => {
    if (token?.metadata && token?.contractId !== value) {
      setFieldValue(tokenName, null);
    }
  }, [tokenName, token?.contractId, token?.metadata, value, setFieldValue]);

  const titleButton = metadata ? (
    <styles.TitleMetadata>
      symbol: {metadata.symbol}, decimals: {metadata.decimals}, icon: <img src={metadata.icon} alt={metadata.symbol} />
    </styles.TitleMetadata>
  ) : (
    <Translate value="Action.GetMetadata" />
  );

  return (
    <>
      <AutocompleteInput
        value={value}
        label={translate({ value: label })}
        name={valueName}
        type="text"
        placeholder={translate({ value: placeholder })}
        optionList={optionList}
      />
      <LoadingButton
        onClick={handleClick}
        loading={loading && !token?.metadata}
        variant="contained"
        color={metadata ? 'success' : 'primary'}
      >
        {titleButton}
      </LoadingButton>
      {isLoadingTokenFailed && (
        <styles.ErrorBlock>
          <Translate value="Error.TokenMetadata" interpolation={{ tokenId: value }} />
        </styles.ErrorBlock>
      )}
    </>
  );
}
