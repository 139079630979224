import { IConfig } from '../config';

export default {
  networkId: 'mainnet',
  nodeUrl: 'https://rpc.mainnet.near.org',
  walletUrl: 'https://wallet.near.org',
  helperUrl: 'https://api.kitwallet.app',
  explorerUrl: 'https://explorer.mainnet.near.org',
  saleContractId: 'launchpad.bocachica_mars.near',
  auctionContractId: 'auction-v1.nft_auction_bocachica.near',
  wNearAddress: 'wrap.near',
  walletId: 'near',
  myNearWalletUrl: 'https://app.mynearwallet.com/',
  usn: 'usn',
  parasApiUrl: 'https://api-v2-mainnet.paras.id',
  parasContractId: 'x.paras.near',
  stakingContractId: '', // TODO
  stakingSeedId: '', // TODO
} as IConfig;
