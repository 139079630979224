import { createSelector } from '@reduxjs/toolkit';

import { toArray } from 'shared/utils';
import { selectAuctions } from 'store/slices/auctions';
import { selectNfts } from 'store/slices/nfts';
import { selectFutureSales, selectSales } from 'store/slices/sales';
import { selectStaking } from 'store/slices/staking';
import { selectTokens } from 'store/slices/tokens';
import { selectUser } from 'store/slices/user';

import { selectLoading } from './selectLoading';

export const selectData = createSelector(
  [selectSales, selectTokens, selectUser, selectLoading, selectFutureSales, selectNfts, selectStaking, selectAuctions],
  (sales, tokens, user, loading, futureSales, nfts, staking, auction) => ({
    user,
    sales: {
      future: {
        arr: toArray(futureSales),
        obj: futureSales,
      },
      arr: toArray(sales),
      obj: sales,
    },
    auctions: {
      arr: toArray(auction),
      obj: auction,
    },
    tokens,
    nfts,
    staking,
    loading,
  })
);
