import { EVestingStatus } from 'shared/interfaces';

import ProgressCircle from './ProgressCircle';
import styles from './styles';

interface ICurrentLogo {
  status: EVestingStatus;
  startPeriod?: number;
  endPeriod: number;
}

export default function CurrentLogo({ status, startPeriod, endPeriod }: ICurrentLogo) {
  switch (status) {
    case EVestingStatus.AVAILABLE_TO_CLAIM:
      return <styles.NotClaimedIcon />;

    default: {
      if (status === EVestingStatus.ACTIVE && startPeriod) {
        return <ProgressCircle startPeriod={startPeriod} endPeriod={endPeriod} />;
      }
      return <styles.Circle />;
    }
  }
}
