import {
  IAction,
  ESaleType,
  ISaleAccounts,
  ISaleContract,
  SaleInputProps,
  SaleOutput,
  VestingOutput,
} from 'services/interfaces';
import RPCProviderService, { IRPCProviderService } from 'services/RPCProviderService';

import { SaleViewMethods, SaleChangeMethods } from './contractMethods';

export default class SaleContract implements ISaleContract {
  readonly contractId: string;

  private provider: IRPCProviderService;

  public constructor(contractId: string, provider: RPCProviderService) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getNumSales(): Promise<number | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getNumSales, this.contractId);
  }

  async getSales(from: number, limit: number): Promise<SaleOutput[] | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getSales, this.contractId, { from_index: from, limit });
  }

  async getSaleAccounts(saleId: number, from: number, limit: number): Promise<ISaleAccounts[]> {
    try {
      const accounts = await this.provider.viewFunction(SaleViewMethods.getSaleAccounts, this.contractId, {
        sale_id: saleId,
        from_index: from,
        limit,
      });
      if (!accounts) return [];
      return accounts;
    } catch (e) {
      console.warn(`Error: ${e} while get sale accounts`);
      return [];
    }
  }

  createSale(sale: SaleInputProps): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.createSale,
          args: {
            sale,
          },
        },
      ],
    };
  }

  removeSale(saleId: number): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.removeSale,
          args: { sale_id: saleId },
        },
      ],
    };
  }

  updateSaleDates(saleId: number, startDate: number, endDate: number): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.updateSaleDates,
          args: {
            sale_id: saleId,
            start_date: startDate.toString(),
            end_date: endDate.toString(),
          },
        },
      ],
    };
  }

  updateSaleDistributeTokenId(saleId: number, distributeTokenId: string): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.updateSaleDistributeTokenId,
          args: {
            sale_id: saleId,
            distribute_token_id: distributeTokenId,
          },
        },
      ],
    };
  }

  updateSalePrice(saleId: number, price: string, totalAmount: string): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.updateSalePrice,
          args: {
            sale_id: saleId,
            price,
            max_amount: totalAmount,
          },
        },
      ],
    };
  }

  updateSaleClaimAvailable(saleId: number, claimAvailable: boolean): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.updateSaleClaimAvailable,
          args: {
            sale_id: saleId,
            claim_available: claimAvailable,
          },
        },
      ],
    };
  }

  updateSaleRefundAvailable(saleId: number, refundAvailable: boolean): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.updateSaleRefundAvailable,
          args: {
            sale_id: saleId,
            refund_available: refundAvailable,
          },
        },
      ],
    };
  }

  changeVesting(saleId: number, inputVesting: VestingOutput[]): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.changeVesting,
          args: {
            sale_id: saleId,
            input_vesting: inputVesting,
          },
        },
      ],
    };
  }

  cancelSale(saleId: number): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.changeSaleType,
          args: {
            sale_id: saleId,
            sale_type: ESaleType.Cancelled,
          },
        },
      ],
    };
  }

  changeWhitelistHash(saleId: number, newHash: Array<number>): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.changeWhitelistHash,
          args: {
            sale_id: saleId,
            new_hash: newHash,
          },
        },
      ],
    };
  }

  createFutureSale(futureSale: SaleInputProps): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.createFutureSale,
          args: {
            future_sale: futureSale,
          },
        },
      ],
    };
  }

  deleteFutureSale(id: number): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.deleteFutureSale,
          args: {
            future_sale_id: id,
          },
        },
      ],
    };
  }

  updateFutureSale(id: number, futureSale: SaleInputProps): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.updateFutureSale,
          args: {
            future_sale_id: id,
            future_sale: futureSale,
          },
        },
      ],
    };
  }

  createSaleFromFutureSale(id: number): IAction {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.createSaleFromFutureSale,
          args: {
            future_sale_id: id,
          },
        },
      ],
    };
  }

  async getNumFutureSales() {
    return this.provider.viewFunction(SaleViewMethods.getNumFutureSales, this.contractId);
  }

  async getFutureSales(from: number, limit: number) {
    return this.provider.viewFunction(SaleViewMethods.getFutureSales, this.contractId, { from_index: from, limit });
  }

  async getFutureSale(futureSaleId: number) {
    return this.provider.viewFunction(SaleViewMethods.getFutureSale, this.contractId, {
      future_sale_id: futureSaleId,
    });
  }
}
