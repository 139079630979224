import { css, keyframes } from 'styled-components';

import colors from './colors';

export const link = keyframes`
  0%,20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.3em 0 0 rgba(0, 0, 0, 0), 0.6em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: ${colors.textShadow};
    text-shadow: 0.3em 0 0 rgba(0, 0, 0, 0), 0.6em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.3em 0 0 ${colors.textShadow}, 0.6em 0 0 rgba(0, 0, 0, 0);
  }
  80%,100% {
    text-shadow: 0.3em 0 0 ${colors.textShadow}, 0.6em 0 0 ${colors.textShadow};
  }
`;

export const animatedDots = css`
  ::after {
    position: absolute;
    content: '.';
    font-weight: 300;
    animation: ${link} 1s steps(5, end) infinite;
  }
`;
