import { parasApiUrl } from 'services/config';

import { fetchSendJson } from './index';

export default async function retrieveNftParasMetadata(nftTokenContractId: string, nftTokenId: string) {
  const tokenSeriesId = nftTokenId.substring(0, nftTokenId.indexOf(':'));
  const url = `${parasApiUrl}/token/${nftTokenContractId}::${tokenSeriesId}/${nftTokenId}`;
  try {
    const nft = await fetchSendJson({ url });
    return nft.metadata;
  } catch (e) {
    console.warn(`Error ${e} while loading metadata from paras server ${url}`);
    return [];
  }
}
