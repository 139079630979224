import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { FungibleTokenContract } from 'services/contract';
import { IAction } from 'services/interfaces';
import { RootState } from 'store';

export const transferStakingToken = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    amount: string;
    stakingId: string;
    token: FungibleTokenContract;
  },
  { state: RootState }
>('staking/transferStakingToken', async ({ sendTransaction, amount, stakingId, token }, { getState }) => {
  try {
    const {
      services: { stakingContract },
    } = getState();
    if (!stakingContract) return;
    const transaction = await token.transfer({
      accountId: stakingContract.contractId,
      receiverId: token.contractId,
      amount,
      message: stakingId,
    });
    await sendTransaction(transaction);
  } catch (error) {
    console.error('Error during createStaking: ', error);
  }
});
