import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { WalletSelectorProvider } from 'providers/WalletProvider';
import AppRoutes from 'routes';
import theme from 'shared/theme';
import store from 'store';

import './services/translation';
import '@near-wallet-selector/modal-ui/styles.css';
import './index.css';

dayjs.extend(utc);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <WalletSelectorProvider>
          <Router>
            <AppRoutes />
          </Router>
        </WalletSelectorProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
