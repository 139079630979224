import { EStatus, IAuction } from 'shared/interfaces';

const getFilteredAuctionArray = (auctions: IAuction[]) => {
  const activeAuctions = auctions
    .filter((auction) => auction.status === EStatus.Open)
    .sort((a, b) => a.endDate - b.endDate);

  const pendingAuctions = auctions
    .filter((auction) => auction.status === EStatus.Soon)
    .sort((a, b) => a.startDate - b.startDate);

  const completedAuctions = auctions
    .filter((auction) => auction.status === EStatus.Closed)
    .sort((a, b) => b.endDate - a.endDate);

  const activePendingAuctions = activeAuctions.concat(pendingAuctions);
  const filteredAuctions = activePendingAuctions.concat(completedAuctions);
  return filteredAuctions;
};

export default getFilteredAuctionArray;
