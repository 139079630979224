import { useNavigate, useLocation } from 'react-router-dom';

import { getColorType, products, ProductsImageMap } from '../../utils';
import styles from './styles';

export default function Products() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <styles.ProductsWrapper>
      {products.map(({ value, type, name }) => {
        const ProductImage = ProductsImageMap[type];
        const isActive = location.pathname.includes(value);
        return (
          <styles.ProductsButton key={value} onClick={() => navigate(value)} colorType={getColorType(isActive)}>
            <ProductImage />
            <styles.ProductsName>{name}</styles.ProductsName>
          </styles.ProductsButton>
        );
      })}
    </styles.ProductsWrapper>
  );
}
