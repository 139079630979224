import { t } from 'i18next';

import QuestionMark from 'assets/images/icon/question-mark-logo.svg';
import ProjectLogo from 'assets/images/project-placeholder.svg';
import { ESaleType } from 'services/interfaces';
import { EStatus } from 'shared/interfaces';

import Status from '../Status';
import { Translate } from '../Translate';
import styles from './styles';

interface ISaleLayoutInfo {
  name: string;
  tokenIcon: string;
  currency: string;
  saleType?: ESaleType;
  status: EStatus;
}

const getTitle = (saleType?: ESaleType) => {
  switch (saleType) {
    case ESaleType.ByAmount:
      return t('Sale.Type.Amount');
    case ESaleType.BySubscription:
      return t('Sale.Type.Subscription');
    case ESaleType.Cancelled:
      return t('Sale.Type.Cancelled');
    default:
      return t('Sale.Type.Future');
  }
};

export default function SaleLayoutInfo({ name, tokenIcon, saleType, status, currency }: ISaleLayoutInfo) {
  const titleTarget = getTitle(saleType);
  return (
    <>
      <styles.LayoutParent>
        <styles.ProjectLayoutImg src={ProjectLogo} alt="layout" />
      </styles.LayoutParent>
      <styles.ProjectInfoNaming>
        <div>
          <styles.ProjectName>{name}</styles.ProjectName>
          <styles.StageRow>
            <styles.StageIcon src={tokenIcon.length ? tokenIcon : QuestionMark} />
            <styles.StageTxt>
              <Translate value="Sale.SaleLayoutInfo" interpolation={{ currency, titleTarget }} />
            </styles.StageTxt>
          </styles.StageRow>
        </div>
        <styles.StatusRow>
          <Status type={status} />
        </styles.StatusRow>
      </styles.ProjectInfoNaming>
    </>
  );
}
