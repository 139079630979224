import { ReactComponent as Plane } from 'assets/images/icon/plane.svg';

import styles from './styles';

export default function PlaneCheckbox({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <styles.Label htmlFor="checkbox">
      <input type="checkbox" id="checkbox" checked={checked} onChange={onChange} />
      <div>
        <div>
          <Plane />
        </div>
        <span className="street-middle" />
        <span className="cloud" />
        <span className="cloud two" />
      </div>
    </styles.Label>
  );
}
