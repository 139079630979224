import { ITranslationKeys } from 'services/translation';

import { displayAmount } from './displayAmount';
import { formatTokenAmount } from './formatAmount';

interface IProps {
  rewardTicker: string;
  minBuy: string;
  maxBuy: string;
  claimBegun: boolean;
  price: string;
  totalAmount: string;
  depositSymbol: string;
  numAccountSales: number;
}

export interface IParticipantData {
  title: ITranslationKeys;
  additionalTitle?: string;
  amount: string;
  currency?: string;
}

const getParticipantData = (props: IProps, decimals?: number): IParticipantData[] => {
  const {
    rewardTicker,
    minBuy,
    maxBuy,
    claimBegun,
    price,
    totalAmount,
    depositSymbol: currency,
    numAccountSales,
  } = props;

  const minAllocation = displayAmount(minBuy, decimals);
  const maxAllocation = displayAmount(maxBuy, decimals);

  const depositTokenPrice = decimals ? formatTokenAmount(price, decimals) : price;
  const formattedTotalAmount = displayAmount(totalAmount, decimals);
  return [
    {
      title: 'Sale.NumAccountSales',
      amount: numAccountSales.toFixed(),
    },
    {
      title: 'Sale.MinAllocation',
      amount: displayAmount(minAllocation),
      currency,
    },
    {
      title: 'Sale.MaxAllocation',
      amount: displayAmount(maxAllocation),
      currency,
    },
    {
      title: 'Sale.ClaimBegun',
      amount: claimBegun ? 'true' : 'false',
    },
    {
      title: 'Sale.SalePrice',
      additionalTitle: rewardTicker,
      amount: depositTokenPrice,
      currency,
    },
    {
      title: 'Sale.Target',
      amount: displayAmount(formattedTotalAmount),
      currency,
    },
  ];
};

export default getParticipantData;
