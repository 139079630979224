import { financeData } from 'pages/Auction/create-auction-page/constants';
import { ITranslationKeys } from 'services/translation';
import { TextInput } from 'shared/components/Input/FormikInput';
import { translate, Translate } from 'shared/components/Translate';
import { capitalizedWord } from 'shared/utils';

import styles from '../../styles';

export default function Finance() {
  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateAuction.Finance" />
      </styles.Title>
      <styles.ChildWrapper>
        {financeData.map((name) => (
          <TextInput
            key={name}
            label={translate({ value: `CreateAuction.${capitalizedWord(name)}.Label` as ITranslationKeys })}
            name={name}
            type="number"
            placeholder={translate({ value: `CreateAuction.${capitalizedWord(name)}.Placeholder` as ITranslationKeys })}
          />
        ))}
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
