import { useEffect, useState } from 'react';

import { ESaleType } from 'services/interfaces';
import { ONE_SECOND } from 'shared/constants';
import { EStatus } from 'shared/interfaces';
import { getSaleStatus } from 'shared/utils';

const useSaleStatus = (
  startDate: number,
  endDate: number,
  totalAmount: string,
  collectedAmount: string,
  saleType: ESaleType | undefined,
  saleStatus: EStatus | undefined
) => {
  const calcStatus = getSaleStatus(startDate, endDate, totalAmount, collectedAmount, saleType);

  const [status, setStatus] = useState<EStatus>(saleStatus || calcStatus);

  useEffect(() => {
    const updateStatus = () => {
      if (saleStatus === EStatus.Closed) return;
      const newCalcStatus = getSaleStatus(startDate, endDate, totalAmount, collectedAmount, saleType);
      if (newCalcStatus === status) return;
      setStatus(newCalcStatus);
    };
    updateStatus();
    const interval = setInterval(updateStatus, ONE_SECOND);
    return () => clearInterval(interval);
  }, [saleStatus, endDate, startDate, totalAmount, collectedAmount, saleType, status]);

  return { status };
};

export default useSaleStatus;
