import QuestionMark from 'assets/images/icon/question-mark-logo.svg';
import Footer from 'pages/Auction/auction-page/components/Footer';
import { ITranslationKeys } from 'services/translation';
import NFTMedia from 'shared/components/NFTMedia';
import Status from 'shared/components/Status';
import Timer from 'shared/components/Timer';
import { translate, Translate } from 'shared/components/Translate';
import { EMPTY_STRING } from 'shared/constants';
import useImageDataUpload from 'shared/hooks/useImageDataUpload';
import { EStatus } from 'shared/interfaces';

import { IAuctionValues } from '../interfaces';
import styles from './styles';
import { getAuctionData, getNftData } from './utils';

export default function PrototypeAuctionCard({ auction }: { auction: IAuctionValues }): JSX.Element {
  const auctionData = getAuctionData(auction);
  const { media, isLoading, typeImage } = useImageDataUpload(
    auction.nftToken?.metadata.media,
    auction.nftToken?.metadata.mime_type
  );
  const { status } = auctionData;
  const nftDataArray = getNftData(
    auctionData.contractNft,
    auctionData.nft,
    auctionData.tokenMetadata?.symbol || EMPTY_STRING,
    auction.auctionMinStep
  );

  return (
    <styles.Container>
      <styles.CloseContainer>
        <styles.CloseIcon />
      </styles.CloseContainer>
      <styles.MainContainer>
        <styles.ImageContainer>
          <NFTMedia media={media} isLoading={isLoading} />
        </styles.ImageContainer>
        <styles.AuctionDataContainer>
          <styles.TitleBlock>
            <styles.Title>{auction.nftToken?.metadata.title || EMPTY_STRING}</styles.Title>
            <Status type={status} />
          </styles.TitleBlock>
          <styles.LabelBlock>
            <styles.LogoToken
              src={auctionData.tokenMetadata?.icon || QuestionMark}
              alt={translate({ value: 'DepositTokenLogo' })}
            />
            <styles.Label>
              <Translate
                value="AuctionLabel"
                interpolation={{ symbol: auctionData.tokenMetadata?.symbol || EMPTY_STRING, typeImage }}
              />
            </styles.Label>
          </styles.LabelBlock>
          <styles.BidBlock>
            <div>
              <styles.TemplateTitle>
                <Translate value={auctionData.bid.title as ITranslationKeys} />
              </styles.TemplateTitle>
              <styles.TemplateLabel>{auctionData.bid.value}</styles.TemplateLabel>
            </div>
          </styles.BidBlock>
          {status !== EStatus.Closed && <Timer time={auctionData.timeLeft} status={status} />}
        </styles.AuctionDataContainer>
      </styles.MainContainer>
      <styles.NftData>
        {nftDataArray.map(({ title, value }) => (
          <div key={title}>
            <styles.TemplateTitle>
              <Translate value={title as ITranslationKeys} />
            </styles.TemplateTitle>
            <styles.TemplateLabel>{value}</styles.TemplateLabel>
          </div>
        ))}
      </styles.NftData>
      {auction.nftToken?.metadata.description && (
        <styles.Description>{auction.nftToken?.metadata.description}</styles.Description>
      )}
      <Footer socials={auctionData.socials} projectLink={auctionData.projectLink} />
    </styles.Container>
  );
}
