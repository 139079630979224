import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { saleContractId } from 'services/config';
import { FungibleTokenContract } from 'services/contract';
import { IAction, SaleInputProps } from 'services/interfaces';
import { RootState } from 'store';

export const updateFutureSale = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    saleId: number;
    values: SaleInputProps;
    token: FungibleTokenContract;
  },
  { state: RootState }
>('sales/updateFutureSale', async ({ sendTransaction, saleId, values, token }, { getState }) => {
  try {
    const {
      services: { saleContract },
    } = getState();
    if (!saleContract) return;
    const actions: IAction[] = [];
    const createSaleTransaction = saleContract.updateFutureSale(saleId, values);
    actions.push(createSaleTransaction);

    const storageDeposit = await token.checkStorageBalance({
      accountId: saleContractId,
    });
    if (storageDeposit) actions.push(storageDeposit);

    await sendTransaction(actions);
  } catch (error) {
    console.error('Error during updateFutureSale: ', error);
  }
});
