import { EStatus } from 'shared/interfaces';

const getTimeLeft = (status: EStatus, startDate: number, endDate: number) => {
  switch (status) {
    case EStatus.Soon:
      return startDate;
    case EStatus.Open:
      return endDate;
    default:
      return 0;
  }
};
export default getTimeLeft;

export const getUTC = (date: number) => {
  const newDate = new Date(date);
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
};
