import { IFormattedStakingValues } from 'shared/interfaces';

import { IAction } from './index';

export interface IStakingContract {
  getListFarmsBySeed: (seedId: string) => Promise<Array<IStakingInfo> | undefined>;
  getSeedInfo: (seedId: string) => Promise<SeedInfo | undefined>;
  createStaking: (values: IFormattedStakingValues) => IAction;
}

export enum EStakingStatus {
  Running = 'Running',
  Ended = 'Ended',
  Created = 'Created',
  Cleared = 'Cleared',
}

export enum EEnvironmentKind {
  SIMPLE_POOL = 'SIMPLE_POOL',
  STABLE_SWAP = 'STABLE_SWAP',
}

export interface IStakingInfo {
  farm_id: string;
  farm_kind: EEnvironmentKind;
  farm_status: EStakingStatus;
  seed_id: string;
  reward_token: string;
  start_at: number;
  reward_per_session: string;
  session_interval: number;
  total_reward: string;
  cur_round: number;
  last_round: number;
  claimed_reward: string;
  unclaimed_reward: string;
  beneficiary_reward: string;
}

export enum ESeedType {
  FT = 'FT',
  MFT = 'MFT',
}

export interface SeedInfo {
  seed_id: string;
  seed_type: ESeedType;
  farms: Array<string>;
  next_index: number;
  amount: string;
  min_deposit: string;
}
