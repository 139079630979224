import { ONE_SECOND } from 'shared/constants';

export { default as initialValues } from './initialValues';
export { default as formatSale } from './formatSale';
export { default as formatAuction } from './formatAuction';
export { default as getParticipantData } from './getParticipantData';
export { default as downloadFile } from './downloadFile';
export { default as generateList } from './generateList';
export { default as checkVestingShouldUpdate } from './checkShouldUpdate';
export { default as getFilteredAuctionArray } from './filter';
export { default as checkAuctionShouldUpdate } from './checkAuctionShouldUpdate';
export { default as getTimeLeft } from './dateOperation';
export { default as getTimeByDate } from './getTimeByDate';
export * from './validationSchema';
export * from './formatValues';
export * from './formatAmount';
export * from './displayAmount';
export * from './adminPanel';
export * from './vesting';
export * from './calculationDate';
export * from './socialLinks';
export * from './statusLocales';
export * from './imageFileExtensions';
export * from './auctionData';
export * from './staking';

export const capitalizedWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const isNullOrEmpty = <T>(str: T): boolean => Boolean(!str);

export const copy = (text: string) => navigator.clipboard.writeText(text);

export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export const onlyUniqueValues = (values: string[]) => Array.from(new Set(values));

export const toArray = <T>(map: Record<string, T>): Array<T> => Object.values(map);
type KeyType = string | number | symbol;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMap = <T extends Record<KeyType, any>, K extends keyof T>(
  array: Array<T>,
  keyName: K
): Record<T[K], T> => {
  return array.reduce(
    (acc, item) => ({
      ...acc,
      [item[keyName]]: item,
    }),
    {} as Record<T[K], T>
  );
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const secondsToMilliseconds = (num: number) => num * ONE_SECOND;
export const millisecondsToSeconds = (num: number) => num / ONE_SECOND;
