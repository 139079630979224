import buffer from 'buffer';

import { NetworkId, setupWalletSelector, Transaction } from '@near-wallet-selector/core';
import type { WalletSelector, AccountState } from '@near-wallet-selector/core';
import { setupModal } from '@near-wallet-selector/modal-ui';
import type { WalletSelectorModal } from '@near-wallet-selector/modal-ui';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import myNearWalletIconUrl from '@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png';
import { setupNearWallet } from '@near-wallet-selector/near-wallet';
import nearWalletIconUrl from '@near-wallet-selector/near-wallet/assets/near-wallet-icon.png';
import { setupSender } from '@near-wallet-selector/sender';
import senderWalletIconUrl from '@near-wallet-selector/sender/assets/sender-icon.png';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { map, distinctUntilChanged } from 'rxjs';

import { saleContractId, networkId, myNearWalletUrl } from 'services/config';
import { getGas, getAmount } from 'services/helpers';
import { IAction } from 'services/interfaces';
import { EMPTY_STRING } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { selectAccountId, setAccountId, setIsSignedIn } from 'store/slices/user';

import { WalletContextType } from './interfaces';

window.Buffer = window.Buffer || buffer.Buffer;

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}

const WalletSelectorContext = React.createContext<WalletContextType>({} as WalletContextType);

export function WalletSelectorProvider({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();
  const accountId = useAppSelector(selectAccountId);
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const syncAccountState = (currentAccountId: string | null, newAccounts: Array<AccountState>) => {
    if (!newAccounts.length) {
      localStorage.removeItem('accountId');
      dispatch(setAccountId(EMPTY_STRING));
      return;
    }

    const validAccountId = currentAccountId && newAccounts.some((x) => x.accountId === currentAccountId);
    const newAccountId = validAccountId ? currentAccountId : newAccounts[0].accountId;

    localStorage.setItem('accountId', newAccountId);
    dispatch(setAccountId(newAccountId));
  };

  const init = useCallback(async () => {
    const selectorInstance = await setupWalletSelector({
      network: networkId as NetworkId,
      debug: true,
      modules: [
        setupNearWallet({ iconUrl: nearWalletIconUrl }),
        setupMyNearWallet({
          iconUrl: myNearWalletIconUrl,
          walletUrl: myNearWalletUrl,
        }),
        setupSender({ iconUrl: senderWalletIconUrl }),
      ],
    });
    const modalInstance = setupModal(selectorInstance, {
      contractId: saleContractId,
    });
    const state = selectorInstance.store.getState();
    syncAccountState(localStorage.getItem('accountId'), state.accounts);

    window.selector = selectorInstance;
    window.modal = modalInstance;

    setSelector(selectorInstance);
    setModal(modalInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }
    dispatch(setIsSignedIn(selector.isSignedIn()));

    const subscription = selector.store.observable
      .pipe(
        map((state) => {
          const result = state.accounts;
          return result;
        }),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        syncAccountState(accountId, nextAccounts);
      });

    // eslint-disable-next-line consistent-return
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, accountId]);

  const sendTransaction = useCallback(
    async (transactions: IAction[]) => {
      if (!selector) return console.warn('No wallet selected');

      const nearTransactions: Transaction[] = transactions.map((transaction: IAction) => ({
        signerId: accountId,
        receiverId: transaction.receiverId,
        actions: transaction.functionCalls.map((fc) => ({
          type: 'FunctionCall',
          params: {
            methodName: fc.methodName,
            args: fc.args || {},
            gas: getGas(fc.gas).toFixed(),
            deposit: getAmount(fc.amount).toFixed(),
          },
        })),
      }));

      const walletInstance = await selector.wallet();
      return walletInstance.signAndSendTransactions({
        transactions: nearTransactions,
      });
    },
    [accountId, selector]
  );

  const openModal = useCallback(() => {
    if (!modal) return;
    modal.show();
  }, [modal]);

  const signOut = useCallback(async () => {
    try {
      if (!selector) return;
      const wallet = await selector.wallet();
      await wallet.signOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, accountId]);

  const walletData = useMemo(
    () => ({
      openModal,
      sendTransaction,
      signOut,
      walletSelector: selector,
    }),
    [openModal, sendTransaction, signOut, selector]
  );

  if (!selector || !modal) {
    return null;
  }

  return <WalletSelectorContext.Provider value={walletData}>{children}</WalletSelectorContext.Provider>;
}

export function useWalletData() {
  return useContext(WalletSelectorContext);
}
