import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useState } from 'react';

import styles from 'pages/Mars/create-sale-page/styles';
import { useWalletData } from 'providers/WalletProvider';
import { ESaleKind, VestingOutput } from 'services/interfaces';
import { GeneralInformation, RoundInformation, RoundDate, Network } from 'shared/components/FormComponent';
import FormComponentStyles from 'shared/components/FormComponent/styles';
import SaleCard from 'shared/components/MainCard';
import { Translate } from 'shared/components/Translate';
import VestingAdminPanel from 'shared/components/VestingAdminPanel';
import { social } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { EFromValues, IValues } from 'shared/interfaces';
import {
  initialValues,
  saleValidationSchema,
  futureSaleValidationSchema,
  formatValues,
  formatValuesToSale,
} from 'shared/utils';
import { createSale, createFutureSale } from 'store/actions/sale';
import { selectSales } from 'store/slices/sales';
import { selectIsSignedIn } from 'store/slices/user';

export default function Form({ saleKind }: { saleKind: ESaleKind }) {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const { sendTransaction } = useWalletData();
  const dispatch = useAppDispatch();
  const sales = useAppSelector(selectSales);
  const saleId = Object.keys(sales).length;
  const [isValidVesting, setIsValidVesting] = useState<boolean>(true);
  const isSimpleSale = saleKind === ESaleKind.SIMPLE;
  const handleSubmit = async (values: IValues) => {
    switch (saleKind) {
      case ESaleKind.SIMPLE: {
        if (!values.depositToken?.metadata || !values.distributeToken?.metadata || !isValidVesting) return;
        const formattedValues = formatValues(values, values.depositToken.metadata, values.distributeToken.metadata);
        await dispatch(createSale({ sendTransaction, values: formattedValues, token: values.depositToken }));
        break;
      }
      case ESaleKind.FUTURE: {
        if (!values.depositToken?.metadata || !values.distributeToken?.metadata) return;
        const formattedValues = formatValues(values, values.depositToken.metadata, values.distributeToken.metadata);
        await dispatch(createFutureSale({ sendTransaction, values: formattedValues, token: values.depositToken }));
        break;
      }
      default:
        break;
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={isSimpleSale ? saleValidationSchema : futureSaleValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <styles.Container>
          <styles.FormContainer>
            <GeneralInformation values={values} />
            <RoundInformation values={values} />
            <RoundDate values={values} setFieldValue={setFieldValue} errors={errors} />
            <FormComponentStyles.Wrapper>
              <FormComponentStyles.Title>
                <Translate value="Vesting.Title" />
              </FormComponentStyles.Title>
              <styles.VestingWrapper>
                <VestingAdminPanel
                  initialVestingType={values.vestingType}
                  saleEndDate={dayjs(values.roundFinishDate).valueOf()}
                  saleTotalAmount={values.maxAmount}
                  setIsValidVesting={setIsValidVesting}
                  currentVesting={values.vesting}
                  handleChange={(vesting: VestingOutput[]) => setFieldValue(EFromValues.vesting, vesting)}
                  rewardTicker={values.distributeToken?.metadata ? values.distributeToken.metadata.symbol : ''}
                />
              </styles.VestingWrapper>
            </FormComponentStyles.Wrapper>
            <Network social={social} />
            <Button variant="contained" color={isSimpleSale ? 'warning' : 'info'} type="submit" disabled={!isSignedIn}>
              <Translate value={isSimpleSale ? 'Action.CreateSale' : 'Action.CreateFutureSale'} />
            </Button>
          </styles.FormContainer>
          <styles.SaleContainer>
            <SaleCard {...formatValuesToSale(values, saleId)} isCentered={false} />
          </styles.SaleContainer>
        </styles.Container>
      )}
    </Formik>
  );
}
