import Big from 'big.js';

import { IStakeInput } from 'providers/LocalProviders/StakingProvider';
import { ZERO_STR } from 'shared/constants';
import { IStaking } from 'shared/interfaces';
import { parseTokenAmount, retrieveStakingData } from 'shared/utils';

const calculatedStakingStatistics = (
  staking: IStaking,
  stakingInputs: { [key: number]: IStakeInput },
  decimals: number
) => {
  const newStakingArray = staking.farms.arr.map((element) => {
    const correspondingStakingInput = stakingInputs[element.id];
    if (!correspondingStakingInput) return element;
    const depositAmount = correspondingStakingInput.depositAmount || ZERO_STR;
    const parseDepositAmount = parseTokenAmount(depositAmount, decimals);
    const totalReward = Big(element.totalReward).plus(parseDepositAmount).toFixed();
    const stakingDuration = Big(totalReward).div(element.rewardPerSeconds).toNumber();
    const endAt = staking.startAt + stakingDuration;
    return { ...element, totalReward, endAt };
  });
  const newStaking = retrieveStakingData(newStakingArray, staking.totalStaked);
  if (!newStaking) return null;
  return {
    endDate: {
      value: newStaking.endAt,
      isChanged: !Big(staking.endAt).eq(newStaking.endAt),
    },
    totalReward: {
      value: newStaking.totalReward,
      isChanged: !Big(staking.totalReward).eq(newStaking.totalReward),
    },
    apy: {
      value: newStaking.apy,
      isChanged: !Big(staking.apy).eq(newStaking.apy),
    },
    rewardPerSeconds: {
      value: newStaking.rewardPerSeconds,
      isChanged: !Big(staking.rewardPerSeconds).eq(newStaking.rewardPerSeconds),
    },
  };
};

export default calculatedStakingStatistics;
