import Big from 'big.js';

import { ESaleType } from 'services/interfaces';
import { ONE_SECOND, TIMESTAMP, ZERO, ZERO_STR } from 'shared/constants';
import { IParseDate, EStatus, ITimeLeft } from 'shared/interfaces';

export const formatDate = (number: number) => (number > 9 ? number.toString() : `0${number}`);

export const detectTimestamp = (startDate: number, endDate: number, status: EStatus) => {
  if (status === EStatus.Soon) {
    return startDate;
  }
  return endDate;
};

export const getSaleStatus = (
  startDate: number,
  endDate: number,
  totalAmount: string,
  collectedAmount: string,
  saleType?: ESaleType | null
) => {
  const currentDate = Date.now();
  if (saleType === ESaleType.Cancelled) return EStatus.Cancelled;
  if (Big(collectedAmount).gte(totalAmount) && saleType === ESaleType.ByAmount) return EStatus.Closed;
  if (startDate > currentDate) return EStatus.Soon;
  if (endDate > currentDate) return EStatus.Open;
  return EStatus.Closed;
};

export const formatCountdown = (diffInMilliSeconds: number): ITimeLeft => {
  const diffInSeconds = Math.floor(diffInMilliSeconds / ONE_SECOND);
  if (diffInSeconds <= 1) {
    return {
      days: ZERO_STR,
      hours: formatDate(ZERO),
      minutes: formatDate(ZERO),
      seconds: formatDate(ZERO),
    };
  }
  const days = Math.floor(diffInSeconds / TIMESTAMP.ONE_DAY);
  const hours = Math.floor((diffInSeconds - days * TIMESTAMP.ONE_DAY) / TIMESTAMP.ONE_HOUR);
  const minutes = Math.floor(
    (diffInSeconds - days * TIMESTAMP.ONE_DAY - hours * TIMESTAMP.ONE_HOUR) / TIMESTAMP.ONE_MINUTE
  );
  const seconds =
    diffInSeconds - days * TIMESTAMP.ONE_DAY - hours * TIMESTAMP.ONE_HOUR - minutes * TIMESTAMP.ONE_MINUTE;
  return {
    days: days.toString(),
    hours: formatDate(hours),
    minutes: formatDate(minutes),
    seconds: formatDate(seconds),
  };
};

export const getParseDate = (date: string | number): IParseDate => {
  const newDate = new Date(date);
  return {
    year: newDate.getUTCFullYear().toString(),
    month: formatDate(newDate.getUTCMonth() + 1),
    monthString: newDate.toLocaleString('en', { month: 'short' }),
    day: formatDate(newDate.getUTCDate()),
    hours: formatDate(newDate.getUTCHours()),
    minutes: formatDate(newDate.getUTCMinutes()),
    seconds: formatDate(newDate.getUTCSeconds()),
  };
};

export const getAuctionStatus = (startDate: number, endDate: number): EStatus => {
  const dateNow = Date.now();
  if (dateNow > endDate) return EStatus.Closed;
  if (startDate > dateNow) return EStatus.Soon;
  if (dateNow > startDate && dateNow < endDate) return EStatus.Open;
  return EStatus.Closed;
};
