enum RequestTypes {
  GET = 'GET',
}

const HEADERS = { 'Content-type': 'application/json; charset=UTF-8' };

export const fetchSendJson = async ({ url }: { url: string }) => {
  const response: Response = await fetch(url, {
    method: RequestTypes.GET,
    headers: HEADERS,
  });

  if (!response.ok) {
    const body = await response.text();
    let parsedBody;
    try {
      parsedBody = JSON.parse(body);
    } catch (e) {
      throw Error(`Error status = ${response.status.toString()}, \n ${e} \n ${body}`);
    }
    throw Error(`Error status = ${response.status.toString()} \n ${parsedBody}`);
  }
  if (response.status === 204) {
    return null;
  }
  return response.json();
};

export { default as retrieveNftParasMetadata } from './retrieveNftParasMetadata';
