import styled from 'styled-components';

import { ReactComponent as CircleIcon } from 'assets/images/icon/circle-icon.svg';
import { ReactComponent as NotClaimedIcon } from 'assets/images/icon/not-claimed-icon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.lightOrange};
  color: ${({ theme }) => theme.black};
  border-radius: 1rem;
  margin-top: 1.5rem;
  padding: 1rem;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 2rem;
`;

const UserInfo = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  justify-content: space-between;
  & > div:nth-child(2) {
    align-items: center;
  }
  & > div:last-child {
    align-items: flex-end;
  }
`;

const UserInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  & > p:first-child {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: ${({ theme }) => theme.primaryGrey};
    margin-bottom: 0.375rem;
  }
  & > p:last-child {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.188rem;
    color: ${({ theme }) => theme.black};
  }
`;

const VestingList = styled.div`
  width: 100%;
  max-width: 23.125rem;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  & > p {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: ${({ theme }) => theme.primaryGrey};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  & > div:last-child {
    display: none;
  }
`;

const Row = styled.div<{ isEnded?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  padding: 0 0.063rem;
  svg {
    margin-right: 0.75rem;
  }
  p {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.063rem;
    color: ${({ theme, isEnded }) => (isEnded ? theme.black : theme.lightGrey)};
  }
  & > p:last-child {
    flex: 1;
    text-align: right;
    font-weight: 400;
  }
`;

const Division = styled.div`
  margin: 0.75rem 0;
  height: 0.063rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.orangeDivision};
`;

const Circle = styled(CircleIcon)``;

const DistributionInContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
  margin-bottom: 4rem;
`;

export default {
  Container,
  Title,
  UserInfo,
  UserInfoRow,
  VestingList,
  HeaderList,
  List,
  Row,
  Division,
  NotClaimedIcon,
  Circle,
  DistributionInContainer,
};
