import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuctionContract, SaleContract, StakingContract } from 'services/contract';
import RPCProviderService, { IRPCProviderService } from 'services/RPCProviderService';
import { RootState } from 'store';

type ServicesState = {
  provider: IRPCProviderService;
  saleContract?: SaleContract;
  auctionContract?: AuctionContract;
  stakingContract?: StakingContract;
};

const initialState: ServicesState = {
  provider: new RPCProviderService(),
  saleContract: undefined,
  auctionContract: undefined,
  stakingContract: undefined,
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setSaleContract(state, action: PayloadAction<SaleContract>) {
      return {
        ...state,
        saleContract: action.payload,
      };
    },
    setAuctionContract(state, action: PayloadAction<AuctionContract>) {
      return {
        ...state,
        auctionContract: action.payload,
      };
    },
    setStakingContract(state, action: PayloadAction<StakingContract>) {
      return {
        ...state,
        stakingContract: action.payload,
      };
    },
    setProvider(state, action: PayloadAction<IRPCProviderService>) {
      return {
        ...state,
        provider: action.payload,
      };
    },
  },
});

// SELECTORS
export const selectSaleContract = (state: RootState) => state.services.saleContract;
export const selectAuctionContract = (state: RootState) => state.services.auctionContract;
export const selectStakingContract = (state: RootState) => state.services.stakingContract;
export const selectProvider = (state: RootState) => state.services.provider;

// ACTIONS
export const { setSaleContract, setAuctionContract, setStakingContract, setProvider } = servicesSlice.actions;

export const servicesReducer = servicesSlice.reducer;
