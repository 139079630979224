import { useEffect, useState } from 'react';

import { ONE_SECOND } from 'shared/constants';
import { ETypeVesting, IVesting, IVestingDetails } from 'shared/interfaces';
import { detectActiveVesting, getVestingDetails, updateVestingStatus, checkVestingShouldUpdate } from 'shared/utils';

const useVesting = (vesting: IVesting[], vestingType: ETypeVesting | null, totalClaimAvailable: string) => {
  const oldVestingDetails = getVestingDetails(vesting, vestingType, totalClaimAvailable);
  const activeVesting = detectActiveVesting(oldVestingDetails.array, vestingType);
  const [timestamp, setTimestamp] = useState<number>(activeVesting?.date || 0);
  const [vestingDetails, setVestingDetails] = useState<IVestingDetails>(oldVestingDetails);
  const oldVestingEnded = vestingDetails.array.every((el) => el.date < Date.now());
  const [vestingEnded, setVestingEnded] = useState<boolean>(oldVestingEnded);

  const [lastCheck, setLastCheck] = useState<boolean>(false);

  useEffect(() => {
    const updateStatus = () => {
      const shouldUpdate = checkVestingShouldUpdate(vestingDetails.array, vestingType, vestingEnded, lastCheck);
      if (!shouldUpdate) return;
      const updatedVestingArrayByStatus = updateVestingStatus(vesting, vestingType);
      const updatedVestingDetails = getVestingDetails(updatedVestingArrayByStatus, vestingType, totalClaimAvailable);
      const updatedActiveVesting = detectActiveVesting(updatedVestingDetails.array, vestingType);
      if (updatedActiveVesting) {
        setVestingDetails(updatedVestingDetails);
        setTimestamp(updatedActiveVesting.date);
      } else {
        const updatedVestingEnded = updatedVestingDetails.array.every((el) => el.date < Date.now());
        setVestingEnded(updatedVestingEnded);
        setVestingDetails(updatedVestingDetails);
        setLastCheck(true);
      }
    };
    updateStatus();
    const interval = setInterval(updateStatus, ONE_SECOND);
    return () => clearInterval(interval);
  }, [vesting, vestingEnded, vestingType, lastCheck]);

  return {
    timestamp,
    vestingEnded,
    vestingDetails,
  };
};

export default useVesting;
