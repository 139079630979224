import { Button } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'assets/images/icon/plus-icon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 20px;
  background-color: ${({ theme }) => theme.white};
  & > div {
    margin: 0.625rem 0;
  }
`;

const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
  width: 100%;
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.grayOp01};
  border-radius: 1rem;
  padding: 0.75rem;
  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Row = styled.div`
  display: flex;
  & > div,
  label {
    width: 100%;
  }
  & > div:not(:last-child),
  label {
    margin-right: 1rem;
  }
`;

const AddPeriod = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.grey};
    path {
      fill: ${({ theme }) => theme.grey};
    }
  }
`;

const Plus = styled(PlusIcon)`
  margin-right: 0.5rem;
`;

const PeriodTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem 0;
  & > p {
    margin: 0;
    font-weight: 600;
  }
`;

export default {
  Container,
  PeriodContainer,
  Button,
  Row,
  AddPeriod,
  Plus,
  PeriodTitle,
};
