import { NonFungibleTokenContract } from 'services/contract';
import { INFTTokenMetadata } from 'services/interfaces';
import { translate } from 'shared/components/Translate';
import { DASH, EMPTY_STRING } from 'shared/constants';
import { getStatus } from 'shared/hooks/useAuctionStatus';
import { ESocial, ISocials } from 'shared/interfaces';
import { getTimeByDate, getTimeLeft } from 'shared/utils';

import { IAuctionValues } from '../interfaces';

export const getAuctionData = (auction: IAuctionValues) => {
  const parsedStartDate = getTimeByDate(auction.startDate);
  const parsedEndDate = getTimeByDate(auction.endDate);
  const status = getStatus(parsedStartDate, parsedEndDate);

  const contractNft = auction.nftContract;
  const nft = auction.nftToken;
  const currentBid = auction.initialPrice || DASH;
  const symbol = auction.depositToken?.metadata?.symbol || EMPTY_STRING;
  const bid = { title: 'Bid.CurrentBid', value: `${currentBid} ${symbol}` };

  const socials: ISocials[] = [
    { value: auction.mediumLink, type: ESocial.Medium },
    { value: auction.telegramLink, type: ESocial.Telegram },
    { value: auction.twitterLink, type: ESocial.Twitter },
  ];
  const { projectLink } = auction;
  const timeLeft = getTimeLeft(status, parsedStartDate, parsedEndDate);
  return {
    contractNft,
    nft,
    tokenMetadata: auction.depositToken?.metadata,
    currentBid,
    bid,
    socials,
    projectLink,
    status,
    timeLeft,
  };
};

export const getNftData = (
  contractNft: NonFungibleTokenContract | null,
  nft: INFTTokenMetadata | null,
  symbol: string,
  auctionMinStep?: string | null
) => {
  return [
    {
      title: 'NftData.ContractAddress',
      value: contractNft?.contractId || DASH,
    },
    {
      title: 'NftData.Owner',
      value: nft?.owner_id || DASH,
    },
    {
      title: 'Bid.MinMarkup',
      value: translate({
        value: 'TokenAmount',
        interpolation: { amount: auctionMinStep || DASH, symbol },
      }),
    },
    {
      title: 'NftData.TokenID',
      value: nft?.token_id || DASH,
    },
  ];
};
