import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { TextField, TextFieldProps, Button } from '@mui/material';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useMemo, useState } from 'react';

import { useWalletData } from 'providers/WalletProvider';
import { createFormattedDate } from 'services/helpers';
import { ESaleType, ITokenMetadata, VestingOutput } from 'services/interfaces';
import { Select, TextAreaInput, TextInput } from 'shared/components/Input/FormikInput';
import TokenBlock from 'shared/components/TokenBlock';
import { Translate, translate } from 'shared/components/Translate';
import VestingAdminPanel from 'shared/components/VestingAdminPanel';
import { ZERO_STR } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { IValues, IFutureSale, EFromValues } from 'shared/interfaces';
import { setupInitialFutureAdminValues, formatTokenAmount } from 'shared/utils';
import { getToken } from 'store/actions/helpers';
import { createSaleFromFutureSale, deleteFutureSale, updateFutureSale } from 'store/actions/sale';
import { selectTokens } from 'store/slices/tokens';
import { selectIsSignedIn } from 'store/slices/user';

import styles from '../styles';
import { updateValuesForFutureSale, validateFutureSale } from './utils';

interface IAdminPanel {
  sale: IFutureSale;
  depositTokenMetadata: ITokenMetadata;
}

export default function FutureAdminPanel({ sale, depositTokenMetadata }: IAdminPanel) {
  const { sendTransaction } = useWalletData();
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const tokens = useAppSelector(selectTokens);
  const dispatch = useAppDispatch();
  const [isValidVesting, setIsValidVesting] = useState<boolean>(true);
  const [futureSaleError, setFutureSaleError] = useState<boolean>(false);

  const tokensList = useMemo(
    () =>
      Object.values(tokens).map((token) => ({
        title: token.metadata ? token.metadata.symbol : token.contractId,
        value: token.contractId,
      })),
    [tokens]
  );

  const submitForm = async (values: IValues) => {
    const token = getToken(sale.depositTokenId, tokens);
    if (!token || !token.metadata || !isValidVesting) return;
    const updatedValues = updateValuesForFutureSale(values, token.metadata);
    await dispatch(updateFutureSale({ sendTransaction, saleId: sale.id, values: updatedValues, token }));
  };
  const errors = validateFutureSale(sale);

  const handlerCreateSaleFromFutureSale = () => {
    if (errors.length) {
      setFutureSaleError(true);
    } else {
      dispatch(createSaleFromFutureSale({ sendTransaction, saleId: sale.id }));
    }
  };

  const deleteSale = () => {
    if (!isSignedIn) return;
    dispatch(deleteFutureSale({ sendTransaction, saleId: sale.id }));
  };

  return (
    <styles.Container>
      <p>
        <Translate value="AdminPanel.Title" />
      </p>
      <Formik
        initialValues={setupInitialFutureAdminValues(sale, depositTokenMetadata.decimals, tokens)}
        onSubmit={submitForm}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <>
            <styles.Row>
              <TextInput
                label={translate({ value: 'CreateSale.ProjectName.Label' })}
                name={EFromValues.projectName}
                type="text"
                placeholder="CreateSale.ProjectName.Placeholder"
              />
              <TextInput
                label={translate({ value: 'CreateSale.ProjectSite.Label' })}
                name={EFromValues.projectSite}
                type="text"
                placeholder="CreateSale.ProjectSite.Placeholder"
              />
            </styles.Row>
            <TokenBlock
              value={values.distributeTokenId}
              label="CreateSale.DistributeToken.Label"
              valueName={EFromValues.distributeTokenId}
              tokenName={EFromValues.distributeToken}
              placeholder="CreateSale.DistributeToken.Placeholder"
              optionList={tokensList}
              token={values.distributeToken}
            />
            <TokenBlock
              value={values.depositTokenId}
              valueName={EFromValues.depositTokenId}
              tokenName={EFromValues.depositToken}
              label="CreateSale.DepositToken.Label"
              placeholder="CreateSale.DepositToken.Placeholder"
              optionList={tokensList}
              token={values.depositToken}
            />
            <styles.Row>
              <TextAreaInput name={EFromValues.description} placeholder={t('CreateSale.Description')} />
            </styles.Row>
            <styles.Row>
              <TextInput
                label={translate({ value: 'Whitelist.Input.Update.Label' })}
                name={EFromValues.whitelistRootHash}
                type="text"
                placeholder="Whitelist.Input.Placeholder"
              />
            </styles.Row>
            <styles.Row>
              <TextInput
                label={translate({ value: 'CreateSale.Telegram.Label' })}
                name={EFromValues.socialTg}
                type="text"
                placeholder="CreateSale.Telegram.Placeholder"
              />
              <TextInput
                label={translate({ value: 'CreateSale.Twitter.Label' })}
                name={EFromValues.socialTwitter}
                type="text"
                placeholder="CreateSale.Twitter.Placeholder"
              />
              <TextInput
                label={translate({ value: 'CreateSale.Medium.Label' })}
                name={EFromValues.socialMedium}
                type="text"
                placeholder="CreateSale.Medium.Placeholder"
              />
            </styles.Row>
            <styles.Row>
              <Select name={EFromValues.roundType}>
                <option value={ESaleType.Empty}>Select a round type</option>
                <option value={ESaleType.ByAmount}>Amount</option>
                <option value={ESaleType.BySubscription}>Subscription</option>
              </Select>
            </styles.Row>
            <styles.Row>
              <TextInput
                value={values.minAllocation}
                label={translate({ value: 'CreateSale.MinAllocation.Label' })}
                name={EFromValues.minAllocation}
                type="number"
                placeholder={t('CreateSale.MinAllocation.Placeholder', { symbol: depositTokenMetadata.symbol })}
              />
              <TextInput
                value={values.maxAllocation}
                label={translate({ value: 'CreateSale.MaxAllocation.Label' })}
                name={EFromValues.maxAllocation}
                type="number"
                placeholder={t('CreateSale.MaxAllocation.Placeholder', { symbol: depositTokenMetadata.symbol })}
              />
              <TextInput
                value={values.limitPerTransaction}
                label={translate({ value: 'CreateSale.LimitPerTransaction.Label' })}
                name={EFromValues.limitPerTransaction}
                type="number"
                placeholder={t('CreateSale.LimitPerTransaction.Placeholder', { symbol: depositTokenMetadata.symbol })}
              />
            </styles.Row>
            <styles.Row>
              <TextInput
                value={values.price}
                label={t('CreateSale.Price.Label', {
                  projectSymbol: sale.metadata.rewardTicker,
                  depositTokenPrice: values.price,
                  depositTokenSymbol: depositTokenMetadata.symbol,
                })}
                name={EFromValues.price}
                type="number"
                placeholder="CreateSale.Price.Placeholder"
              />
              <TextInput
                label={translate({ value: 'CreateSale.MaxAmount.Label' })}
                name={EFromValues.maxAmount}
                type="number"
                placeholder={t('CreateSale.MaxAmount.Placeholder', { symbol: depositTokenMetadata.symbol })}
              />
            </styles.Row>
            <styles.Row>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DateTimePicker
                  disablePast
                  renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
                  label={translate({ value: 'CreateSale.StartDate' })}
                  value={values.roundStartDate}
                  onChange={(date: string | null) => {
                    if (!date) return;
                    setFieldValue(EFromValues.roundStartDate, createFormattedDate(date));
                  }}
                  ampm={false}
                />
                <DateTimePicker
                  disablePast
                  renderInput={(props: TextFieldProps) => <TextField {...props} error={false} />}
                  label={translate({ value: 'CreateSale.EndDate' })}
                  value={values.roundFinishDate}
                  onChange={(date: string | null) => {
                    if (!date) return;
                    setFieldValue(EFromValues.roundFinishDate, createFormattedDate(date));
                  }}
                  ampm={false}
                />
              </LocalizationProvider>
            </styles.Row>
            <styles.VestingContainer>
              <VestingAdminPanel
                initialVestingType={values.vestingType}
                saleTotalAmount={formatTokenAmount(sale.totalAmount || ZERO_STR, depositTokenMetadata.decimals)}
                saleEndDate={sale.endDate || undefined}
                setIsValidVesting={setIsValidVesting}
                handleChange={(vesting: VestingOutput[]) => setFieldValue(EFromValues.vesting, vesting)}
                currentVesting={values.vesting}
                rewardTicker={sale.metadata.rewardTicker}
              />
            </styles.VestingContainer>
            <styles.WrapperButton>
              <Button disabled={!isSignedIn} variant="contained" color="warning" onClick={() => handleSubmit()}>
                <Translate value="Action.UpdateFutureSale" />
              </Button>
            </styles.WrapperButton>
          </>
        )}
      </Formik>
      <Button disabled={!isSignedIn} variant="contained" color="warning" onClick={handlerCreateSaleFromFutureSale}>
        <Translate value="Action.CreateSaleFromFutureSale" />
      </Button>
      {futureSaleError && errors.length && (
        <styles.Errors>
          <Translate value="Error.CreateSaleFromFutureSale" />
          <ul>
            {errors.map((title) => (
              <li key={title}>{title}</li>
            ))}
          </ul>
        </styles.Errors>
      )}
      <Button disabled={!isSignedIn} variant="contained" color="error" onClick={deleteSale}>
        <Translate value="Action.RemoveSale" />
      </Button>
    </styles.Container>
  );
}
