import { Buffer } from 'buffer';

import { providers } from 'near-api-js';
import { AccountView, CodeResult } from 'near-api-js/lib/providers/provider';

export interface IRPCProviderService {
  viewFunction: (method: string, accountId: string, args?: any) => Promise<any>;
  viewAccount: (accountId: string) => Promise<any>;
}

enum RPCProviderMethods {
  CALL_FUNCTION = 'call_function',
  VIEW_ACCOUNT = 'view_account',
}

const FINALITY_FINAL = 'final';
const BASE64 = 'base64';

export default class RPCProviderService implements IRPCProviderService {
  private provider?: providers.JsonRpcProvider;

  constructor(provider?: providers.JsonRpcProvider) {
    this.provider = provider;
  }

  async viewFunction(method: string, accountId: string, args: any = {}) {
    try {
      if (!this.provider) {
        console.warn('No Provider selected');
        return undefined;
      }
      const response = await this.provider.query<CodeResult>({
        request_type: RPCProviderMethods.CALL_FUNCTION,
        account_id: accountId,
        method_name: method,
        args_base64: Buffer.from(JSON.stringify(args)).toString(BASE64),
        finality: FINALITY_FINAL,
      });
      return JSON.parse(Buffer.from(response.result).toString());
    } catch (error) {
      console.error(`${error} \n\n while calling method ${method} on contract ${accountId}`);
      return undefined;
    }
  }

  async viewAccount(accountId: string) {
    try {
      if (!this.provider) return console.warn('No Provider selected');
      return await this.provider.query<AccountView>({
        request_type: RPCProviderMethods.VIEW_ACCOUNT,
        finality: FINALITY_FINAL,
        account_id: accountId,
      });
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}
