import { EStatus } from 'shared/interfaces';
import { StatusLocales } from 'shared/utils';

import { translate } from '../Translate';
import styles from './styles';

export default function Status({ type }: { type: EStatus }): JSX.Element | null {
  if (type === EStatus.Future) return null;
  return (
    <styles.StatusWrapper type={type}>
      <styles.StatusText>{translate({ value: StatusLocales[type] })}</styles.StatusText>
    </styles.StatusWrapper>
  );
}
