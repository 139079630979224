import styled from 'styled-components';

import { EStatus } from 'shared/interfaces';

const StatusWrapper = styled.div<{ type?: EStatus }>`
  display: flex;
  align-items: center;
  padding: 0.281rem 0.375rem;
  border-radius: 0.25rem;
  max-height: 1.5rem;
  background-color: ${({ theme, type }) => {
    if (type === EStatus.Open) return theme.statusOpenBg;
    if (type === EStatus.Soon) return theme.statusSoonBg;
    if (type === EStatus.Cancelled) return theme.statusCanceledBg;
    return theme.statusEndedBg;
  }};

  color: ${({ theme, type }) => {
    if (type === EStatus.Open) return theme.statusOpenText;
    if (type === EStatus.Soon) return theme.statusSoonText;
    if (type === EStatus.Cancelled) return theme.statusCanceledText;
    return theme.statusEndedText;
  }};
`;

const StatusText = styled.p`
  margin: 0;

  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.938rem;
`;

export default {
  StatusWrapper,
  StatusText,
};
