import { ReactComponent as AuctionIcon } from 'assets/images/products/auction-icon.svg';
import { ReactComponent as MarsIcon } from 'assets/images/products/mars-icon.svg';
import { ReactComponent as StakingIcon } from 'assets/images/products/staking-icon.svg';
import { APP_ROUTES } from 'routes/constant';

enum ProductsEnum {
  Mars,
  Auction,
  Whitelist,
  Staking,
}

interface IProducts {
  value: string;
  type: ProductsEnum;
  name: string;
}

export const products: IProducts[] = [
  {
    value: APP_ROUTES.MARS.HOME,
    type: ProductsEnum.Mars,
    name: 'Mars',
  },
  {
    value: APP_ROUTES.WHITELIST,
    type: ProductsEnum.Whitelist,
    name: 'Whitelist',
  },
  {
    value: APP_ROUTES.AUCTION.HOME,
    type: ProductsEnum.Auction,
    name: 'Auction',
  },
  {
    value: APP_ROUTES.STAKING,
    type: ProductsEnum.Staking,
    name: 'Staking',
  },
];

export const ProductsImageMap: {
  [key: number]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  [ProductsEnum.Mars]: MarsIcon,
  [ProductsEnum.Whitelist]: MarsIcon,
  [ProductsEnum.Auction]: AuctionIcon,
  [ProductsEnum.Staking]: StakingIcon,
};

export enum EColorType {
  ACTIVE,
  EMPTY,
}
export const getColorType = (isActive?: boolean) => {
  if (isActive) return EColorType.ACTIVE;
  return EColorType.EMPTY;
};
