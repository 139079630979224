import styled from 'styled-components';

import { animatedDots } from 'shared/theme/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 18.75rem;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  background: ${({ theme }) => theme.grayOp01};
  border-radius: 0.5rem;
  padding: 0.5rem;
  span {
    color: ${({ theme }) => theme.black};
    :first-child {
      white-space: nowrap;
      max-width: 9.375rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :last-child {
      color: ${({ theme }) => theme.lightGrey};
    }
  }
`;

const Loading = styled.p`
  position: relative;
  color: ${({ theme }) => theme.lightGrey};
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  ${animatedDots}
`;

const Title = styled.p`
  color: ${({ theme }) => theme.lightGrey};
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

export default {
  Container,
  Row,
  Loading,
  Title,
};
