import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useFormikContext } from 'formik';

import { EAuctionFromValues, IAuctionValues } from 'pages/Auction/create-auction-page/interfaces';
import { createFormattedDate } from 'services/helpers';
import styles from 'shared/components/FormComponent/styles';
import { translate, Translate } from 'shared/components/Translate';

export default function AboutDate() {
  const { values, setFieldValue, errors } = useFormikContext<IAuctionValues>();
  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateAuction.Date" />
      </styles.Title>
      <styles.ChildWrapper>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => (
              <TextField {...props} error={Boolean(errors.startDate)} helperText={errors.startDate} />
            )}
            label={translate({ value: 'CreateAuction.StartDate' })}
            value={values.startDate}
            onChange={(date) => {
              if (!date) return;
              setFieldValue(EAuctionFromValues.startDate, createFormattedDate(date));
            }}
            ampm={false}
          />
          <DateTimePicker
            disablePast
            renderInput={(props: TextFieldProps) => (
              <TextField {...props} error={Boolean(errors.endDate)} helperText={errors.endDate} />
            )}
            label={translate({ value: 'CreateAuction.EndDate' })}
            value={values.endDate}
            onChange={(date) => {
              if (!date) return;
              setFieldValue(EAuctionFromValues.endDate, createFormattedDate(date));
            }}
            ampm={false}
          />
        </LocalizationProvider>
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
