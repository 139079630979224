import Big from 'big.js';

import { MICRO_SECOND, DECIMAL_PREFIX_KILO, ZERO_STR, BASE, MILI_IN_NANO } from 'shared/constants';

Big.RM = Big.roundDown;
Big.DP = 30;

export const formatTokenAmount = (value: string, decimals: number, precision?: number): string => {
  try {
    return (
      value &&
      Big(value)
        .div(Big(BASE).pow(decimals))
        .toFixed(precision && precision)
    );
  } catch (e) {
    return ZERO_STR;
  }
};

export const parseTokenAmount = (value: string, decimals: number): string => {
  try {
    return (
      value &&
      Big(value)
        .times(Big(BASE).pow(decimals || 0))
        .toFixed(0)
    );
  } catch (e) {
    return ZERO_STR;
  }
};

export const nanosecondsToMilliseconds = (nanoseconds: string | number): number =>
  new Big(nanoseconds).div(new Big(BASE).pow(MILI_IN_NANO)).toNumber();

export const millisecondsToNanoseconds = (milliseconds: number) => milliseconds * MICRO_SECOND;

export const systemSIToPrefixKilo = (quota: number) => quota * DECIMAL_PREFIX_KILO;
export const prefixKiloToSI = (quota: number) => quota / DECIMAL_PREFIX_KILO;

export const roundAmount = (totalLocked: string) => {
  if (Big(totalLocked).lte('0.001')) return ZERO_STR;
  return totalLocked;
};

export const uint8ArrayToHex = (uint8Array: Array<number>): string => {
  let hexString = '';

  uint8Array.forEach((item) => {
    const hexByte = item.toString(16).padStart(2, '0');
    hexString += hexByte;
  });

  return hexString;
};

export const hexToUint8Array = (hexString: string): Array<number> | null => {
  const byteArray = new Uint8Array(hexString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)));

  if (byteArray.length !== 32) {
    console.error('Invalid input: Hex string must represent 32 bytes');
    return null;
  }

  return Array.from(byteArray);
};
