import { ESaleType } from 'services/interfaces';
import { EMPTY_STRING } from 'shared/constants';
import { ETypeVesting, IValues } from 'shared/interfaces';

const initialValues: IValues = {
  projectName: EMPTY_STRING,
  description: EMPTY_STRING,
  distributeTokenId: EMPTY_STRING,
  distributeToken: null,
  depositTokenId: EMPTY_STRING,
  depositToken: null,

  maxAmount: EMPTY_STRING,
  minAllocation: EMPTY_STRING,
  maxAllocation: EMPTY_STRING,
  limitPerTransaction: EMPTY_STRING,
  roundType: ESaleType.Empty,
  price: EMPTY_STRING,

  roundStartDate: EMPTY_STRING,
  roundFinishDate: EMPTY_STRING,

  projectSite: EMPTY_STRING,
  socialTg: EMPTY_STRING,
  socialTwitter: EMPTY_STRING,
  socialMedium: EMPTY_STRING,

  vestingType: ETypeVesting.OneTime,
  vesting: undefined,

  whitelistRootHash: EMPTY_STRING,
};

export default initialValues;
