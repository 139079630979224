import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction } from 'services/interfaces';
import { RootState } from 'store';

export const changeWhitelistHash = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    saleId: number;
    newHash: Array<number>;
  },
  { state: RootState }
>('sales/changeWhitelistHash', async ({ sendTransaction, saleId, newHash }, { getState }) => {
  try {
    const {
      services: { saleContract },
    } = getState();
    if (!saleContract) return;
    const action = saleContract.changeWhitelistHash(saleId, newHash);
    await sendTransaction([action]);
  } catch (error) {
    console.error('Error during changeWhitelistHash: ', error);
  }
});
