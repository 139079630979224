import { Button } from '@mui/material';
import { t } from 'i18next';
import { sha256 } from 'js-sha256';
import { MerkleTree } from 'merkletreejs';
import Papa from 'papaparse';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { ReactComponent as Copy } from 'assets/images/icon/copy.svg';
import ToastService from 'shared/components/Toast';
import { Translate } from 'shared/components/Translate';
import { EMPTY_STRING } from 'shared/constants';
import { copy } from 'shared/utils';

import styles from './styles';

type DataType = string[][];

interface IWhitelistData {
  file: File | null;
  whitelist: string[] | [];
  rootHash: string | null;
  leaves: string[] | [];
}

const whitelistInitialData: IWhitelistData = {
  file: null,
  whitelist: [],
  rootHash: null,
  leaves: [],
};

export default function Whitelist() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [whitelistData, setWhitelistData] = useState<IWhitelistData>(whitelistInitialData);

  const clearFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = EMPTY_STRING;
      setWhitelistData(whitelistInitialData);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const inputFile = e.target.files[0];
      setWhitelistData({ ...whitelistData, file: inputFile });
    }
  };

  const handleParse = () => {
    if (!whitelistData.file) {
      setWhitelistData(whitelistInitialData);
      return;
    }

    Papa.parse(whitelistData.file, {
      complete({ data }: { data: DataType }) {
        const list = data.map((address) => address[0]);
        setWhitelistData({ ...whitelistData, whitelist: list });
      },
    });
  };

  useEffect(() => {
    if (!whitelistData.file) {
      setWhitelistData(whitelistInitialData);
      return;
    }
    handleParse();
  }, [whitelistData.file]);

  useEffect(() => {
    if (!whitelistData.whitelist.length) return;

    const leafNodes = whitelistData.whitelist.map((address) => sha256(address));
    const merkleTree = new MerkleTree(leafNodes, sha256);
    const merkleTreeRootHash = merkleTree.getRoot().toString('hex');

    setWhitelistData({ ...whitelistData, leaves: leafNodes, rootHash: merkleTreeRootHash });
  }, [whitelistData.whitelist]);

  const generateFile = (data: Array<string>) => {
    const content = JSON.stringify(data, null, 2);
    const f = new Blob([content], { type: 'application/json' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(f);
    element.download = 'merkle_leaves.json';
    element.click();
  };

  const copyRootHashToClipboard = () => {
    if (!whitelistData.rootHash) return;
    copy(whitelistData.rootHash);
    ToastService.success('Toast.CopiedToClipboard');
  };

  return (
    <styles.WhitelistWrapper>
      <styles.Title>
        <Translate value="Whitelist.Title" />
      </styles.Title>
      <styles.Description>
        <Translate value="Whitelist.Description" />
      </styles.Description>
      <styles.FileData>
        <div>
          <styles.Label>
            <Translate value="Whitelist.File.Label" />
          </styles.Label>
          <styles.Value>{whitelistData.file ? whitelistData.file.name : t('Whitelist.File.Empty')}</styles.Value>
        </div>
        <styles.ButtonGroup>
          {whitelistData.file && (
            <Button variant="outlined" color="error" onClick={clearFileInput}>
              <Translate value="Action.ClearAllData" />
            </Button>
          )}
          <Button variant="contained" component="label" color="warning">
            {whitelistData.file ? t('Action.UploadNewFile') : t('Action.UploadFile')}
            <input ref={inputRef} onChange={handleFileChange} type="file" accept=".csv" hidden />
          </Button>
        </styles.ButtonGroup>
      </styles.FileData>
      {whitelistData.rootHash && (
        <styles.RootHash>
          <div>
            <styles.Label>
              <Translate value="Whitelist.Root.Label" />
            </styles.Label>
            <styles.Value>{whitelistData.rootHash}</styles.Value>
          </div>
          <Copy onClick={copyRootHashToClipboard} />
        </styles.RootHash>
      )}
      {Boolean(whitelistData.leaves.length) && (
        <styles.DownloadLeaves>
          <div>
            <styles.Label>
              <Translate value="Whitelist.Leaves.Label" />
            </styles.Label>
            <styles.Value>
              <Translate value="Whitelist.Leaves.Description" />
            </styles.Value>
          </div>
          <Button
            variant="contained"
            component="label"
            color="warning"
            onClick={() => generateFile(whitelistData.leaves)}
          >
            <Translate value="Action.Download" />
          </Button>
        </styles.DownloadLeaves>
      )}
      {Boolean(whitelistData.whitelist.length) && (
        <styles.AddressesWrapper>
          <styles.AddressesTitle>
            <Translate value="Whitelist.Addresses" />
          </styles.AddressesTitle>
          {whitelistData.whitelist.map((address) => (
            <styles.AddressItem key={address}>{address}</styles.AddressItem>
          ))}
        </styles.AddressesWrapper>
      )}
    </styles.WhitelistWrapper>
  );
}
