import { t } from 'i18next';

import { ESaleType, ITokenMetadata, SaleInputProps } from 'services/interfaces';
import { EMPTY_STRING } from 'shared/constants';
import { IFutureSale, IValues } from 'shared/interfaces';
import {
  parseTokenAmount,
  millisecondsToNanoseconds,
  getTimeByDate,
  isNullOrEmpty,
  isNotNullOrUndefined,
  hexToUint8Array,
} from 'shared/utils';

export const updateValuesForFutureSale = (values: IValues, metadata: ITokenMetadata): SaleInputProps => {
  const { symbol, decimals } = metadata;
  const {
    maxAmount,
    minAllocation,
    maxAllocation,
    limitPerTransaction,
    price,
    roundStartDate,
    roundFinishDate,
    vesting,
    description,
    projectSite,
    projectName,
    socialTg,
    socialMedium,
    socialTwitter,
    depositTokenId,
    distributeTokenId,
    distributeToken,
    roundType,
    whitelistRootHash,
  } = values;
  const parsedMaxAmount = maxAmount ? parseTokenAmount(maxAmount, decimals) : undefined;
  const parsedMinAllocation = minAllocation ? parseTokenAmount(minAllocation, decimals) : undefined;
  const parsedMaxAllocation = maxAllocation ? parseTokenAmount(maxAllocation, decimals) : undefined;
  const parsedLimitPerTransaction = limitPerTransaction ? parseTokenAmount(limitPerTransaction, decimals) : undefined;
  const parsedPrice = price ? parseTokenAmount(price, decimals) : undefined;
  const parsedWhitelistRootHash = whitelistRootHash ? hexToUint8Array(whitelistRootHash) : null;

  const startDate = roundStartDate ? millisecondsToNanoseconds(getTimeByDate(roundStartDate)).toString() : undefined;
  const endDate = roundFinishDate ? millisecondsToNanoseconds(getTimeByDate(roundFinishDate)).toString() : undefined;
  return {
    metadata: {
      name: projectName,
      symbol,
      description,
      smart_contract_url: projectSite,
      logo_url: EMPTY_STRING,
      output_ticker: distributeToken?.metadata?.symbol || EMPTY_STRING,
      project_telegram: socialTg || undefined,
      project_medium: socialMedium || undefined,
      project_twitter: socialTwitter || undefined,
    },
    deposit_token_id: depositTokenId,
    distribute_token_id: distributeTokenId,
    distribute_token_decimals: distributeToken?.metadata?.decimals,
    min_buy: parsedMinAllocation,
    max_buy: parsedMaxAllocation,
    max_amount: parsedMaxAmount,
    start_date: startDate,
    end_date: endDate,
    price: parsedPrice,
    limit_per_transaction: parsedLimitPerTransaction,
    sale_type: roundType === ESaleType.Empty ? undefined : roundType,
    vesting: !vesting?.length ? undefined : vesting,
    whitelist_hash: parsedWhitelistRootHash,
  };
};

export const validateFutureSale = (sale: IFutureSale) => {
  const {
    depositTokenId,
    distributeTokenId,
    distributeTokenDecimals,
    minBuy,
    maxBuy,
    totalAmount,
    startDate,
    endDate,
    price,
    limitPerTransaction,
    saleType,
    vesting,
  } = sale;

  const errors = {
    depositTokenId: isNullOrEmpty(depositTokenId) ? t('CreateSale.DepositToken.Label') : null,
    distributeTokenId: isNullOrEmpty(distributeTokenId) ? t('CreateSale.DistributeToken.Label') : null,
    distributeTokenDecimals: isNullOrEmpty(distributeTokenDecimals) ? t('CreateSale.DistributeToken.Decimals') : null,
    minBuy: isNullOrEmpty(minBuy) ? t('CreateSale.MinAllocation.Label') : null,
    maxBuy: isNullOrEmpty(maxBuy) ? t('CreateSale.MaxAllocation.Label') : null,
    limitPerTransaction: isNullOrEmpty(limitPerTransaction) ? t('CreateSale.LimitPerTransaction.Label') : null,
    totalAmount: isNullOrEmpty(totalAmount) ? t('CreateSale.MaxAmount.Label') : null,
    price: isNullOrEmpty(price) ? t('CreateSale.Price.Error') : null,
    startDate: isNullOrEmpty(startDate) ? t('CreateSale.StartDate') : null,
    endDate: isNullOrEmpty(endDate) ? t('CreateSale.EndDate') : null,
    saleType: isNullOrEmpty(saleType) || saleType === ESaleType.Empty ? t('CreateSale.SaleType') : null,
    vesting: isNullOrEmpty(vesting) || !vesting?.length ? t('Vesting.Title') : null,
  };
  return Object.values(errors).filter(isNotNullOrUndefined);
};
