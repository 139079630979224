import { Fragment } from 'react';

import { EVestingStatus, IVestingArray } from 'shared/interfaces';

import { Translate } from '../Translate';
import CurrentLogo from './helpers';
import styles from './styles';

interface IVestingList {
  rewardTicker: string;
  vesting: IVestingArray[];
}

export default function VestingList({ vesting, rewardTicker }: IVestingList) {
  return (
    <styles.VestingList>
      <styles.HeaderList>
        <p>
          <Translate value="Vesting.Amount" />
        </p>
        <p>
          <Translate value="Vesting.UnlockDate" />
        </p>
      </styles.HeaderList>
      <styles.List>
        {vesting.map((row) => (
          <Fragment key={row.date}>
            <styles.Row isEnded={row.status === EVestingStatus.CLOSED}>
              <CurrentLogo status={row.status} startPeriod={row.previousVestingDate} endPeriod={row.date} />
              <p>
                <Translate
                  value="Sale.Price"
                  interpolation={{
                    value: row.amount,
                    symbol: rewardTicker,
                  }}
                />
              </p>
              <p>
                <Translate
                  value="Vesting.ShortDate"
                  interpolation={{
                    day: row.dateObj.day,
                    month: row.dateObj.monthString,
                    year: row.dateObj.year,
                  }}
                />
              </p>
            </styles.Row>
            <styles.Division />
          </Fragment>
        ))}
      </styles.List>
    </styles.VestingList>
  );
}
