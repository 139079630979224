import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: 43.125rem;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  box-shadow: 0px 4px 8px -4px ${({ theme }) => theme.boxShadowCard};
  padding: 1rem;
  position: relative;
  align-self: flex-start;
  color: ${({ theme }) => theme.black};
  & > button {
    margin-top: 1.5rem;
  }
  & > p {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 0 1rem;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`align-self: center;`}
`;

const Row = styled.div`
  display: flex;
  margin: 1rem 0;

  & > div {
    flex: 1;
  }

  justify-content: space-around;
  align-items: center;
`;

const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  > button {
    width: 100%;
  }
`;

export default {
  Container,
  Row,
  WrapperButton,
};
