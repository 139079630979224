import { FungibleTokenContract } from 'services/contract';
import { IRPCProviderService } from 'services/RPCProviderService';

export async function retrieveFilteredTokenMetadata(
  provider: IRPCProviderService,
  tokenAddresses: string[]
): Promise<FungibleTokenContract[]> {
  return Promise.all(
    tokenAddresses.map(async (address: string) => {
      const ftTokenContract: FungibleTokenContract = new FungibleTokenContract({
        provider,
        contractId: address,
      });
      await ftTokenContract.getMetadata();
      return ftTokenContract;
    })
  );
}

export const getToken = (
  tokenId: string,
  tokens: { [key: string]: FungibleTokenContract }
): FungibleTokenContract | null => (tokenId ? tokens[tokenId] ?? null : null);

export const retrieveMetadataToken = async (
  tokenId: string | null,
  RPCProvider: IRPCProviderService,
  tokens: { [key: string]: FungibleTokenContract },
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoadingTokenFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!tokenId) {
    setIsLoadingTokenFailed(true);
    setLoading(false);
    return null;
  }
  const uploadedToken = getToken(tokenId, tokens);
  if (uploadedToken) {
    setIsLoadingTokenFailed(false);
    setLoading(false);
    return uploadedToken;
  }
  try {
    const ftTokenContract: FungibleTokenContract = new FungibleTokenContract({
      provider: RPCProvider,
      contractId: tokenId,
    });
    const tokenMetadata = await ftTokenContract.getMetadata();
    const isLoadingSuccessfully = Boolean(tokenMetadata);

    setIsLoadingTokenFailed(!isLoadingSuccessfully);
    if (isLoadingSuccessfully) return ftTokenContract;
    return null;
  } catch (e) {
    console.warn(`Error: ${e} while getMetadataToken ${tokenId}`);
    return null;
  } finally {
    setLoading(false);
  }
};
