import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/images/icon/arrow-icon.svg';

const Container = styled.div<{ isOpen?: boolean }>`
  padding: 1rem;
  background: ${({ theme }) => theme.white};
  border-radius: 1rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.black};
  width: 100%;
  box-shadow: 0 0 0 3px ${({ theme, isOpen }) => (isOpen ? theme.statusOpenCard : theme.transparent)};
`;

const NameValue = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primaryGrey};
`;

const CurrencyValue = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
  padding-top: 0.375rem;
`;

const Footer = styled.footer`
  margin-top: 1rem;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`;

const SaleInfoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-width: 21rem;
  & > div:first-child {
    align-items: flex-start;
  }
  & > div:last-child {
    align-items: flex-end;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    max-width: 100%;
  `}
`;

const SaleInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    margin: 0;

    :first-child {
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.938rem;
      color: ${({ theme }) => theme.grey};
    }

    :last-child {
      font-weight: 500;
      font-size: 1rem;
      line-height: 0.938rem;
      color: ${({ theme }) => theme.black};
    }
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
    width: 100%;
    justify-content: space-between;
  `}
`;

const ArrowLogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-left: 1.25rem;
`;

const Timer = styled.div`
  display: flex;
  justify-content: end;
  min-width: 8.125rem;
  font-weight: 600;
`;

export default {
  Container,
  NameValue,
  CurrencyValue,
  Footer,
  SaleInfoWrapper,
  SaleInfoBlock,
  RightSide,
  ArrowLogoContainer,
  ArrowIcon,
  Timer,
};
