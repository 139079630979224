import { Slide, toast, ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import { ITranslationKeys } from 'services/translation';
import colors from 'shared/theme/colors';

import { Translate } from '../Translate';

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.black};
  & > p {
    color: ${({ theme }) => theme.primaryGrey};
    margin: 0;
    font-size: 0.75rem;
  }
`;

const options: ToastOptions = {
  transition: Slide,
  position: 'top-center',
  closeOnClick: false,
  style: {
    background: colors.lightOrange,
    boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '.5rem',
  },
};

const generateContent = (action: ITranslationKeys, href?: string) =>
  href ? (
    <Link href={href} target="_blank" rel="noreferrer">
      <Translate value={action} />
      <p>
        <Translate value="Toast.OpenTransaction" />
      </p>
    </Link>
  ) : (
    <Translate value={action} />
  );

export default class ToastService {
  static success(action: ITranslationKeys, href?: string): void {
    const content = generateContent(action, href);
    toast.success(content, options);
  }

  static error(action: ITranslationKeys, href?: string): void {
    const content = generateContent(action, href);
    toast.error(content, options);
  }
}
