import { retrieveNftParasMetadata } from 'services/api';
import { parasContractId } from 'services/config';
import { INFTMetadata, INFTTokenMetadata } from 'services/interfaces';
import { IRPCProviderService } from 'services/RPCProviderService';
import { DEFAULT_NFT_TOKENS_PER_PAGE } from 'shared/constants';

import { NFTViewMethods } from './contractMethods';

const ICON_DEFAULT_VALUE = '';

const defaultContractMetadata = {
  spec: '',
  name: '',
  symbol: '',
  icon: ICON_DEFAULT_VALUE,
  base_uri: '',
  reference: '',
  reference_hash: '',
  copies: 1,
};

export default class NonFungibleTokenContract {
  readonly contractId: string;

  private provider: IRPCProviderService;

  public constructor({ contractId, provider }: { contractId: string; provider: IRPCProviderService }) {
    this.contractId = contractId;
    this.provider = provider;
  }

  contractMetadata: INFTMetadata = defaultContractMetadata;

  tokenMetadata: { [key: string]: INFTTokenMetadata } = {};

  static buildMediaUrl = (media: string, base_uri: string | null | undefined) => {
    if (!media || media.includes('://') || media.startsWith('data:image')) {
      return media;
    }

    if (base_uri) {
      return `${base_uri}/${media}`;
    }

    return `https://cloudflare-ipfs.com/ipfs/${media}`;
  };

  async getNFTContractMetadata(): Promise<INFTMetadata | null> {
    try {
      const contractMetadata = await this.provider.viewFunction(NFTViewMethods.nftMetadata, this.contractId);
      this.contractMetadata = { ...contractMetadata };
      return contractMetadata;
    } catch (e) {
      console.warn(`Error while loading ${this.contractId}`);
    }
    return null;
  }

  async getNFTTokenMetadata(
    tokenId: string,
    base_uri: string | null | undefined
  ): Promise<{ [key: string]: INFTTokenMetadata } | null> {
    try {
      if (!this.tokenMetadata) return this.tokenMetadata;

      const tokenMetadata = await this.provider.viewFunction(NFTViewMethods.nftToken, this.contractId, {
        token_id: tokenId,
      });
      const { media, reference } = tokenMetadata.metadata;

      if (this.contractId === parasContractId) {
        const parasMetadata = await retrieveNftParasMetadata(this.contractId, tokenId);
        if (parasMetadata) tokenMetadata.metadata = parasMetadata;
      } else if (!media && reference && base_uri) {
        const data = await fetch(`${base_uri}/${reference}`);
        tokenMetadata.metadata = await data.json();
      }

      tokenMetadata.metadata.media = NonFungibleTokenContract.buildMediaUrl(tokenMetadata.metadata.media, base_uri);

      this.tokenMetadata = {
        ...this.tokenMetadata,
        [tokenId]: {
          ...tokenMetadata,
        },
      };
      return tokenMetadata;
    } catch (e) {
      console.warn(`Error while loading ${this.contractId}`);
    }
    return null;
  }

  async nftSupplyForOwner(accountId: string): Promise<number | undefined> {
    return this.provider.viewFunction(NFTViewMethods.nftSupplyForOwner, this.contractId, { account_id: accountId });
  }

  async getTokens({
    accountId,
    baseUri,
    fromIndex,
  }: {
    accountId: string;
    baseUri: string | null | undefined;
    fromIndex: number;
  }) {
    const tokens: INFTTokenMetadata[] = await this.provider.viewFunction(
      NFTViewMethods.nftTokensForOwner,
      this.contractId,
      {
        account_id: accountId,
        from_index: fromIndex.toString(),
        limit: DEFAULT_NFT_TOKENS_PER_PAGE,
      }
    );
    const filteredTokens = tokens
      .filter(({ metadata }) => !!metadata)
      .map((token) => {
        const tokenWithUpdatedMedia = NonFungibleTokenContract.mapTokenMediaUrl(token, baseUri);
        this.tokenMetadata = {
          ...this.tokenMetadata,
          [token.token_id]: {
            ...tokenWithUpdatedMedia,
          },
        };
        return tokenWithUpdatedMedia;
      });
    return filteredTokens;
  }

  static mapTokenMediaUrl = ({ metadata, ...token }: INFTTokenMetadata, baseUri: string | null | undefined) => {
    const { media } = metadata;
    return {
      ...token,
      metadata: {
        ...metadata,
        media: this.buildMediaUrl(media, baseUri),
      },
    };
  };
}
