import { createAsyncThunk } from '@reduxjs/toolkit';

import { wNearAddress } from 'services/config';
import { NEAR_TOKEN_ID } from 'shared/constants';
import { toMap } from 'shared/utils';
import { setTokens, setTokensLoading } from 'store/slices/tokens';

import { RootState } from '../index';
import { retrieveFilteredTokenMetadata } from './helpers';

export const loadTokens = createAsyncThunk<
  void,
  {
    tokenAddresses: string[];
  },
  { state: RootState }
>('tokens/loadTokens', async ({ tokenAddresses }, { dispatch, getState }) => {
  try {
    const newTokenAddresses = [...tokenAddresses, NEAR_TOKEN_ID, wNearAddress];
    if (!newTokenAddresses.length) return;
    const {
      tokens,
      services: { provider },
    } = getState();
    if (Object.keys(tokens.obj).length) {
      const uniqTokenAddress = newTokenAddresses.filter((token) => !tokens.obj[token]);
      if (!uniqTokenAddress.length) return;
      const tokensMetadataFiltered = await retrieveFilteredTokenMetadata(provider, uniqTokenAddress);
      const tokensMap = toMap(tokensMetadataFiltered, 'contractId');
      dispatch(setTokens({ ...tokens.obj, ...tokensMap }));
    } else {
      const tokensMetadataFiltered = await retrieveFilteredTokenMetadata(provider, newTokenAddresses);
      const tokensMap = toMap(tokensMetadataFiltered, 'contractId');
      dispatch(setTokens(tokensMap));
    }
  } catch (e) {
    console.error(`Error: while loading token metadata \n ${e}`);
  } finally {
    dispatch(setTokensLoading(false));
  }
});
