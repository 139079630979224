import { useParams } from 'react-router-dom';

import AdminPanel from 'shared/components/AdminPanel/SimpleAdminPanel';
import MainCard from 'shared/components/MainCard';
import { useAppSelector } from 'shared/hooks/redux';
import { ISale } from 'shared/interfaces';
import { selectData } from 'store/selectors/selectData';
import { selectToken } from 'store/selectors/selectToken';

export default function SalePage() {
  const { sales, loading } = useAppSelector(selectData);

  const { id } = useParams();
  const sale: ISale | null = sales.obj[Number(id)] || null;
  const token = useAppSelector((state) => selectToken(state, sale?.depositTokenId));
  if (loading.sale || !sale || !token || !token.metadata) return null;
  return (
    <>
      <MainCard {...sale} />
      <AdminPanel sale={sale} depositTokenMetadata={token.metadata} />
    </>
  );
}
