/* eslint-disable @typescript-eslint/naming-convention */
import { FutureSaleOutput } from 'services/interfaces';
import { EMPTY_STRING } from 'shared/constants';
import { EStatus, IFutureSale, ESocial, ETypeVesting, IVesting } from 'shared/interfaces';

import { nanosecondsToMilliseconds, uint8ArrayToHex } from './formatAmount';
import { formatVesting, getCliffDetails, getVestingArray, getVestingType } from './vesting';

const formatFutureSale = (id: number, futureSale: FutureSaleOutput): IFutureSale => {
  const {
    name,
    symbol,
    description,
    smart_contract_url,
    logo_url,
    output_ticker,
    project_telegram,
    project_medium,
    project_twitter,
  } = futureSale.metadata;

  const startDate = futureSale.start_date ? nanosecondsToMilliseconds(futureSale.start_date) : null;
  const endDate = futureSale.end_date ? nanosecondsToMilliseconds(futureSale.end_date) : null;
  const formattedVesting = formatVesting(futureSale.vesting);
  const vestingType: ETypeVesting | null = getVestingType(formattedVesting);
  const vesting: IVesting[] | null = getVestingArray(formattedVesting, vestingType, endDate);
  const cliff = getCliffDetails(formattedVesting);
  const parsedWhitelistRootHash = futureSale.whitelist_hash ? uint8ArrayToHex(futureSale.whitelist_hash) : null;

  return {
    id,
    metadata: {
      socials: [
        { value: project_medium, type: ESocial.Medium },
        { value: project_telegram, type: ESocial.Telegram },
        { value: project_twitter, type: ESocial.Twitter },
      ],
      name,
      depositSymbol: symbol,
      description,
      projectUrl: smart_contract_url,
      rewardTokenLogo: logo_url,
      rewardTicker: output_ticker,
      rewardDescription: null,
    },
    status: EStatus.Future,
    depositTokenId: futureSale.deposit_token_id || EMPTY_STRING,
    distributeTokenId: futureSale.distribute_token_id || EMPTY_STRING,

    stakingContracts: futureSale.staking_contracts,
    distributeTokenDecimals: futureSale.distribute_token_decimals,
    minBuy: futureSale.min_buy,
    maxBuy: futureSale.max_buy,
    totalAmount: futureSale.max_amount,
    startDate,
    endDate,
    price: futureSale.price,
    whitelistRootHash: parsedWhitelistRootHash,
    limitPerTransaction: futureSale.limit_per_transaction,
    saleType: futureSale.sale_type,
    vesting,
    vestingType,
    cliff,
    numAccountSales: 0,
    claimBegun: false,
  };
};

export default formatFutureSale;
