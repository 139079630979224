import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction } from 'services/interfaces';
import { RootState } from 'store';

export const removeAuction = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    auctionId: number;
  },
  { state: RootState }
>('auctions/removeAuction', async ({ sendTransaction, auctionId }, { getState }) => {
  try {
    const {
      services: { auctionContract },
    } = getState();
    if (!auctionContract) return;
    const action = auctionContract.removeAuction(auctionId);
    await sendTransaction([action]);
  } catch (error) {
    console.error('Error during removeAuction: ', error);
  }
});
