import { Alert } from '@mui/material';

import { Translate } from '../Translate';
import styles from './styles';

export default function LoadingRocket() {
  return (
    <styles.Container>
      <styles.RocketLogo />
      <Alert variant="filled" severity="error">
        <Translate value="Alert.Login" />
      </Alert>
    </styles.Container>
  );
}
