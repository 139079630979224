import { Button } from '@mui/material';
import { Formik } from 'formik';

import { useWalletData } from 'providers/WalletProvider';
import { DepositTokenBlock, Finance, AboutDate, NFTBlock } from 'shared/components/FormComponent/Auction';
import Network from 'shared/components/FormComponent/Network';
import Loading from 'shared/components/Loading';
import LoadingRocket from 'shared/components/LoadingRocket';
import { Translate } from 'shared/components/Translate';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { auctionValidationSchema, formatAuctionValues } from 'shared/utils';
import { createAuction } from 'store/actions/auction/createAuction';
import { selectLoading } from 'store/selectors/selectLoading';
import { selectIsSignedIn } from 'store/slices/user';

import { initialAuctionValues, social } from './constants';
import { IAuctionValues } from './interfaces';
import PrototypeAuctionCard from './PrototypeAuctionCard';
import styles from './styles';

export default function CreateAuctionPage() {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const loading = useAppSelector(selectLoading);
  const { sendTransaction } = useWalletData();
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: IAuctionValues) => {
    const { depositToken, nftContract, nftToken } = values;
    const decimals = depositToken?.metadata?.decimals;
    if (!depositToken || !nftContract || !nftToken || !decimals) return;
    const formattedValues = formatAuctionValues(values, decimals);
    await dispatch(createAuction({ sendTransaction, auction: formattedValues, token: depositToken }));
  };
  if (loading.auction) return <Loading />;
  if (!isSignedIn) return <LoadingRocket />;

  return (
    <Formik initialValues={initialAuctionValues} validationSchema={auctionValidationSchema} onSubmit={handleSubmit}>
      {({ values }) => (
        <styles.Container>
          <styles.FormContainer>
            <NFTBlock />
            <DepositTokenBlock />
            <Finance />
            <AboutDate />
            <Network social={social} />
            <Button variant="contained" color="secondary" type="submit" disabled={!isSignedIn}>
              <Translate value="Action.CreateAuction" />
            </Button>
          </styles.FormContainer>
          <PrototypeAuctionCard auction={values} />
        </styles.Container>
      )}
    </Formik>
  );
}
