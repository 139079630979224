import { useMemo } from 'react';

import { useAppSelector } from 'shared/hooks/redux';
import { EFromValues, IValues } from 'shared/interfaces';
import { selectTokens } from 'store/slices/tokens';

import { TextAreaInput, TextInput } from '../Input/FormikInput';
import TokenBlock from '../TokenBlock';
import { translate, Translate } from '../Translate';
import styles from './styles';

interface IGeneralInformation {
  values: IValues;
}

export default function GeneralInformation({ values }: IGeneralInformation) {
  const tokens = useAppSelector(selectTokens);

  const tokensList = useMemo(
    () =>
      Object.values(tokens).map((token) => ({
        title: token.metadata ? token.metadata.symbol : token.contractId,
        value: token.contractId,
      })),
    [tokens]
  );

  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateSale.GeneralInformation" />
      </styles.Title>
      <styles.ChildWrapper>
        <TextInput
          key={EFromValues.projectName}
          label={translate({ value: 'CreateSale.ProjectName.Label' })}
          name={EFromValues.projectName}
          type="text"
          placeholder={translate({
            value: 'CreateSale.ProjectName.Placeholder',
          })}
        />
        <TextAreaInput name={EFromValues.description} placeholder={translate({ value: 'CreateSale.Description' })} />
        <TokenBlock
          value={values.distributeTokenId}
          label="CreateSale.DistributeToken.Label"
          valueName={EFromValues.distributeTokenId}
          tokenName={EFromValues.distributeToken}
          placeholder="CreateSale.DistributeToken.Placeholder"
          optionList={tokensList}
          token={values.distributeToken}
        />
        <TokenBlock
          value={values.depositTokenId}
          valueName={EFromValues.depositTokenId}
          tokenName={EFromValues.depositToken}
          label="CreateSale.DepositToken.Label"
          placeholder="CreateSale.DepositToken.Placeholder"
          optionList={tokensList}
          token={values.depositToken}
        />
        <TextInput
          key={EFromValues.whitelistRootHash}
          label={translate({ value: 'Whitelist.Input.Label' })}
          name={EFromValues.whitelistRootHash}
          type="text"
          placeholder={translate({ value: 'Whitelist.Input.Placeholder' })}
        />
        <styles.Description>
          <span>
            <strong>
              <Translate value="CreateSale.Note.Title" />
            </strong>
          </span>
          <Translate value="CreateSale.Note.Description" />
        </styles.Description>
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
