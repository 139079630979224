import { createAsyncThunk } from '@reduxjs/toolkit';

import { ZERO_STR } from 'shared/constants';
import { RootState } from 'store';
import { setStaking, setStakingLoading } from 'store/slices/staking';
import { setStakingTokenBalance } from 'store/slices/user';

import { retrieveStaking } from './helpers';
import { loadTokens } from './loadTokens';

export const loadStaking = createAsyncThunk<void, void, { state: RootState }>(
  'staking/loadStaking',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const {
        services: { stakingContract },
        user: { isSignedIn, id: accountId },
      } = state;
      if (!stakingContract) return;
      const stakingData = await retrieveStaking(stakingContract);
      let balance = ZERO_STR;
      if (isSignedIn) {
        const stakingToken = state.tokens.obj[stakingData.rewardTokenId];
        balance = stakingToken ? await stakingToken.getBalanceOf({ accountId }) : ZERO_STR;
      }
      dispatch(setStaking(stakingData));
      dispatch(setStakingTokenBalance(balance));
      dispatch(loadTokens({ tokenAddresses: [stakingData.rewardTokenId] }));
    } catch (e) {
      console.error(`Error: while load staking \n ${e}`);
    } finally {
      dispatch(setStakingLoading(false));
    }
  }
);
