import { Form } from 'formik';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
`;

const SaleContainer = styled.div`
  margin-top: 4.469rem;
  max-width: 43rem;
  width: 100%;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3.125rem;
  & > button {
    border-radius: 10px;
    font-size: 1rem;
  }
`;

const VestingWrapper = styled.div`
  padding: 1.125rem;
  background-color: white;
  border-radius: 20px;
`;

const Header = styled.header`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 500;
  }
`;

export default {
  Container,
  FormContainer,
  SaleContainer,
  VestingWrapper,
  Header,
};
