import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import { EAuctionFromValues, IAuctionValues } from 'pages/Auction/create-auction-page/interfaces';
import TokenBlock from 'shared/components/TokenBlock';
import { Translate } from 'shared/components/Translate';
import { useAppSelector } from 'shared/hooks/redux';
import { selectTokens } from 'store/slices/tokens';

import styles from '../../styles';

export default function DepositTokenBlock() {
  const { values } = useFormikContext<IAuctionValues>();
  const tokens = useAppSelector(selectTokens);

  const tokensList = useMemo(
    () =>
      Object.values(tokens).map((token) => ({
        title: token.metadata ? token.metadata.symbol : token.contractId,
        value: token.contractId,
      })),
    [tokens]
  );

  return (
    <styles.Wrapper>
      <styles.Title>
        <Translate value="CreateAuction.DepositTokenBlock" />
      </styles.Title>
      <styles.ChildWrapper>
        <TokenBlock
          value={values.depositTokenId}
          label="CreateAuction.DepositToken.Label"
          valueName={EAuctionFromValues.depositTokenId}
          tokenName={EAuctionFromValues.depositToken}
          placeholder="CreateAuction.DepositToken.Placeholder"
          optionList={tokensList}
          token={values.depositToken}
        />
        <styles.Description>
          <span>
            <strong>
              <Translate value="CreateAuction.Note.Title" />
            </strong>
          </span>
          <Translate value="CreateAuction.Note.TokenDescription" />
        </styles.Description>
      </styles.ChildWrapper>
    </styles.Wrapper>
  );
}
