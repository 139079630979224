import { FinalExecutionOutcome } from '@near-wallet-selector/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAction } from 'services/interfaces';
import { RootState } from 'store';

export const updateAuctionDates = createAsyncThunk<
  void,
  {
    sendTransaction: (t: IAction[]) => Promise<void | FinalExecutionOutcome[]>;
    auctionId: number;
    startDate: number;
    endDate: number;
  },
  { state: RootState }
>('auctions/updateAuctionDates', async ({ sendTransaction, auctionId, startDate, endDate }, { getState }) => {
  try {
    const {
      services: { auctionContract },
    } = getState();
    if (!auctionContract) return;
    const action = auctionContract.updateAuctionDates(auctionId, startDate, endDate);
    await sendTransaction([action]);
  } catch (error) {
    console.error('Error during updateAuctionDates: ', error);
  }
});
