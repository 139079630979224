import { useNavigate, useParams } from 'react-router-dom';

import { APP_ROUTES } from 'routes/constant';
import FutureAdminPanel from 'shared/components/AdminPanel/FutureAdminPanel';
import MainCard from 'shared/components/MainCard';
import { useAppSelector } from 'shared/hooks/redux';
import { selectData } from 'store/selectors/selectData';
import { selectToken } from 'store/selectors/selectToken';

import formatFutureSaleToSimpleSale from './utils';

export default function FutureSalePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, sales } = useAppSelector(selectData);

  const futureSale = sales.future.obj[Number(id)] || null;
  const token = useAppSelector((state) => selectToken(state, futureSale?.depositTokenId));
  if (loading.sale || !futureSale || !token?.metadata) return null;
  if (!loading.sale && !futureSale) navigate(APP_ROUTES.MARS.HOME);
  return (
    <>
      <MainCard {...formatFutureSaleToSimpleSale(futureSale)} />
      <FutureAdminPanel sale={futureSale} depositTokenMetadata={token.metadata} />
    </>
  );
}
