import dayjs from 'dayjs';

import { ONE_MINUTE } from 'shared/constants';
import { EStatus, IAuction } from 'shared/interfaces';

const checkAuctionShouldUpdate = (auction: IAuction | null, status: EStatus) => {
  if (!auction) return false;
  if (status === EStatus.Open) return true;
  const dateNow = dayjs().valueOf();
  const dateBeforeStart = auction.startDate - ONE_MINUTE;
  const dateAfterEnd = auction.endDate + ONE_MINUTE;
  if (status === EStatus.Soon && dateBeforeStart < dateNow) return true;
  if (status === EStatus.Closed && dateAfterEnd > dateNow) return true;
  return false;
};

export default checkAuctionShouldUpdate;
